const categories = [
    {
      id: "Mathura", // Mathura
      data: [
        {
          title: "About Mathura Tourism",
          items: [
            "Festivals in Mathura",
            "Things to do in Mathura",
            "Places to Visit in Mathura",
            "How to Reach Mathura",
            "Foods of Mathura",
          ],
        },
        
      ],
    },
    {
      id: "Vrindavan", // Vrindavan
      data: [
        {
          title: "About Vrindavan Tourism",
          items: [
            "Festivals in Vrindavan",
            "Things to do in Vrindavan",
            "Places to Visit in Vrindavan",
            "How to Reach Vrindavan",
            "Foods of Vrindavan",
          ],
        },
        
      ],
    },
    {
      id: "Barsana", // Barsana
      data: [
        {
          title: "About Barsana Tourism",
          items: [
            "Festivals in Barsana",
            "Things to do in Barsana",
            "Places to Visit in Barsana",
            "How to Reach Barsana",
            "Foods of Barsana",
          ],
          
        },
        
        
      ],
      
    },
    {
      id: "Nandgaon", // Nandgaon
      data: [
        {
          title: "About Nandgaon Tourism",
          items: [
            "Festivals in Nandgaon",
            "Things to do in Nandgaon",
            "Places to Visit in Nandgaon",
            "How to Reach Nandgaon",
            "Foods of Nandgaon",
          ],
        },
        
      ],
    },
    {
      id: "Govardhan", // Goverdhan
      data: [
        {
          title: "About Govardhan Tourism",
          items: [
            "Festivals in Govardhan",
            "Things to do in Govardhan",
            "Places to Visit in Govardhan",
            "How to Reach Govardhan",
            "Foods of Govardhan",
          ],
        },
        
      ],
    },
    {
      id: "Gokul-&-Dauji", // Gokul & Dauji
      data: [
        {
          title: "About Gokul & Dauji Tourism",
          items: [
            "Festivals in Gokul & Dauji",
            "Things to do in Gokul & Dauji",
            "Places to Visit in Gokul & Dauji",
            "How to Reach Gokul & Dauji",
            "Foods of Gokul & Dauji",
          ],
        },
       
      ],
    },
  ];
  
    
  
  export default categories;
  