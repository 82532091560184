import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../pagesCss/HvtReview.css';

const HvtReview = () => {
  const testimonials = [
    {
      name: 'Olga Vintonyak',
      text: `Namaste ✨ Trusting Deepak for holy place tours was the best decision. Living in Vrindavan his whole life, his devotion and knowledge are unmatched. He’s a wonderful guide and person. If you want to explore Krishna’s paths deeply, I highly recommend this company.`,
      imgSrc: '/images/Olga Vintonyak.jpeg',
      designation: ''
    },

    {
      name: 'Praveen Kumar',
      text: "Exploring Vrindavan with Deepak was a deeply soulful experience. His extensive knowledge of sacred sites and captivating stories of Krishna’s leelas brought every moment to life. His devotion and warmth made the journey truly enriching.",
      imgSrc: '/images/praveen.jpeg',
      designation: ''
    },
    {
      name: 'Tanishq Bhindarwal',
      text: 'Deepak and his team provided an immersive experience in Vrindavan. His knowledge of Vrindavan’s sacred sites and stories of Krishna brought the experience to life. A kind and devoted guide, highly recommended for spiritual travelers.',
      imgSrc: '/images/tanishq.jpeg',
      designation: ''
    },
   
    {
      name: 'Shivanshi Sharma',
      text: `Deepak made my Vrindavan trip magical. His knowledge and connection to Krishna’s stories were inspiring. The Yamuna Aarti and Govardhan Parikrama were unforgettable. Highly recommended for anyone seeking a soulful, enriching experience.`,
      imgSrc: '/images/shivani.jpeg',
      designation: ''
    },
   
    {
      name: 'Akash Kumar',
      text: 'I’ve visited Vrindavan many times, but traveling with Deepak was transformative. His devotion and heartfelt stories brought Krishna’s leelas to life. The well-paced tour covered all key temples and sacred sites seamlessly, making it truly unforgettable.',
      imgSrc: '/images/Akash.jpeg',
      designation: ''
    },
   
  ];

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className='bg-light'>
      <Container className="my-5 p-5">
        <Row className="justify-content-center">
          <Col md={8} className="text-center clients">
            <h2 className="fw-bold ">
              What Our <span className="main-hover">Clients Say</span>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5"  data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
          <Col md={10} className="px-2">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div key={index} className="testimonial-item">
                  <Card className="testimonial-card">
                    <Card.Body className="d-flex flex-column align-items-center">
                      <img
                        src={testimonial.imgSrc}
                        className="rounded-circle mb-3 testimonial-img"
                        alt={testimonial.name}
                      />
                      <Card.Title className="mt-3 testimonial-name">{testimonial.name}</Card.Title>
                      <Card.Text className="text-center testimonial-text">
                        "{testimonial.text}"
                      </Card.Text>
                      <Card.Subtitle className="text-muted testimonial-designation">{testimonial.designation}</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HvtReview;
