import React from 'react';
import { useParams, Link } from 'react-router-dom';
import tours from "../Data/data.js";
import categories from '../Data/categories'; // Import the categories data
import PlanCard from './PlanCard';

const TourDetails = () => {
  const { id } = useParams();
  const tour = tours.find(t => t.id === id);
  const tourCategories = categories.find(cat => cat.id === id); // Find the matching categories for the tour

  return (
    <section className='bg-light'>
      <div className="container mb-5 mt-5 text-center p-5">
        {tour ? (
          <>
            <h2 className='fw-bold mb-4 fs-2 fs-md-2 '>{tour.title}</h2>
            <p className="text-muted" style={{ textAlign: "left" }}>{tour.details}</p>
            <h3 className='pt-4 mb-4 fw-bold fs-3'>Plans</h3>
            <div className="row">
              {tour.plans.map((plan, index) => (
                <div className="col-12 col-md-6 mb-4" key={index}>
                  <PlanCard plan={plan} />
                </div>
              ))}
            </div>

            <div className="mt-5">
  <h2 className="fw-bold mb-3">Why Should You Choose {tour?.title} Tours?</h2>
  <p className="text-muted pb-4" style={{ textAlign: "left" }}>
    Holy Vrindavan Tours is your trusted travel partner for exploring the divine and vibrant lands of India. 
    With years of experience and a commitment to excellence, we bring you carefully curated tours that blend 
    spirituality, culture, and adventure. Our team ensures that every journey you take with us is safe, 
    comfortable, and memorable.
  </p>

  {tourCategories && tourCategories.data.length > 0 ? (
    tourCategories.data.map((category, index) => (
      <div key={index} className="mb-5 ">
        <h5 className="fw-bold" style={{ textAlign: "left" }}>{category.title}</h5>
        <div className="d-flex flex-wrap gap-3 mt-3">
          {category.items.map((item, i) => (
            <Link
              key={i}
              to={`/tours/${id}/blogs/${item.replace(/\s+/g, "-").toLowerCase()}`}
              className="btn tag-link"
              style={{
                border: "1px solid",  
                padding: "9px 12px",
                textDecoration: "none",
                borderRadius: "50px",
                textAlign: "left", // Optional if required
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) => e.target.style.backgroundColor = "#8E7E50"}
              onMouseLeave={(e) => e.target.style.backgroundColor = "#f7f7f7"}
            >
              {item}
            </Link>
          ))}
        </div>
      </div>
    ))
  ) : (
    <p className="text-muted mt-4" style={{ textAlign: "left" }}>
      Explore our exciting tours and plans to experience the best of Holy Vrindavan!
    </p>
  )}
</div>


          </>
        ) : (
          <p>Tour not found</p>
        )}
      </div>
    </section>
  );
};

export default TourDetails;
