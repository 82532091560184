import React from "react";
import { useParams } from "react-router-dom";
import contentMap from "../Data/contantMap";
import ReactMarkdown from "react-markdown"; // To handle markdown rendering

const CategoryDetail = () => {
  const { id, item  } = useParams();
  const categoryData = contentMap[item.toLowerCase()];

  if (!categoryData) {
    return (
      <section className="bg-light text-center py-5">
        <h2>Content not available</h2>
        <p>We're working on adding details for this category. Stay tuned!</p>
      </section>
    );
  }

  const { title, sections } = categoryData;

  return (
    <section className="">
      <div className="container py-5">
        {/* Title */}
        <div className="text-center mb-5">
          <h2 className="fw-bold fs-2" >{title}</h2>
        </div>

        {/* Sections */}
        {sections.map((section, index) => (
   <div
   className={`row align-items-center mb-5 section-row ${
     index % 2 === 0 ? "" : "flex-row-reverse"
   }`}
   key={index}
 >
  
   {/* Image */}
   <div className="col-md-4 section-image" >
     <img
       src={section.image}
       alt={section.heading}
       className="img-fluid rounded shadow"
       style={{
         width: "400px", // Set your desired width
         height: "500px", // Set your desired height
         objectFit: "cover",
         borderRadius: "10px",
         boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
       }}
     />
   </div>
 
   {/* Details */}
   <div className="col-md-8 section-description">
     <h3
       className="fw-bold mb-3 section-heading"
       style={{
         fontSize: "24px", // Adjust heading size
         color: "#333", // Customize heading color
         marginBottom: "15px", // Space between heading and description
       }}
     >
       {section.heading}
     </h3>
     <ReactMarkdown
       components={{
         p: ({ node, ...props }) => (
           <p
             {...props}
             style={{
               fontSize: "15.2px", // Adjust paragraph font size
               lineHeight: "1.6", // Line height for readability
               color: "#555", // Paragraph text color
               fontFamily: "Arial, sans-serif", // Optional font family
             }}
           />
         ),
         h1: ({ node, ...props }) => (
           <h1
             {...props}
             style={{
               fontSize: "25px", // Customize font size for h1
               color: "#222",
             }}
           />
         ),
         h3: ({ node, ...props }) => (
           <h3
             {...props}
             style={{
               fontSize: "16px", // Customize font size for h2
               color: "#222",
             }}
           />
         ),
         li: ({ node, ...props }) => (
           <li
             {...props}
             style={{
               fontSize: "15.2px", // Font size for list items
               color: "#555",
             }}
           />
         ),
       }}
     >
       {section.description}
     </ReactMarkdown>
   </div>
 </div>
 
     
      
       
        ))}
      </div>
    </section>
  );
};

export default CategoryDetail;
