import React, { useState } from "react";
import { Card, Button, Modal, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FaPhone} from "react-icons/fa";

import "../ComponentCss/PlanCard.css";



const PlanCard = ({ plan }) => {
  const [showBookNow, setShowBookNow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const handleCloseBookNow = () => setShowBookNow(false);
  const handleShowBookNow = () => setShowBookNow(true);

  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = () => setShowDetails(true);

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(''); // 'success', 'error', or ''

  const [data, setData] = useState({
    Name: "",
    mobNumber: "",
    Email: "",
    Date: "",
    Numberofadults: "",
    Category: "",
    Message: "",
  });

  // const url = "https://hvt-backend.vercel.app/";
  const userApiUrl = `https://hvt-backend.vercel.app/user/create`;

  const submitHandler = async () => {

    setStatus('');
    setIsLoading(true);

    // Validation for required fields
    if (
      !data.Name ||
      !data.mobNumber ||
      !data.Email ||
      !data.Date ||
      !data.Numberofadults ||
      !data.Category
    ) {
      toast.error('Please fill in all required fields.⚠️');
      setIsLoading(false);
      return;
    }

    try {
      await axios.post(userApiUrl, data);
      toast.success('Enquiry sent successfully! We will contact you soon.😊');

      setStatus('success'); // Set status to success
      setData({
        Name: '',
        mobNumber: '',
        Email: '',
        Date: '',
        Numberofadults: '',
        Category: '',
        Message: '',
      });
      handleCloseBookNow();
    } catch (err) {
      console.error(err.message);
      toast.error('Failed to send booking. Please try again.⚠️');
      setStatus('error'); // Set status to error
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  const buttonVariant = status === 'success' ? 'success' : status === 'error' ? 'danger' : 'primary';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="plan-card">
      <Card>
        <Row noGutters>
          <Col md={12} lg={4}>
            <Card.Img src={plan.imgSrc} alt="Plan image" />
          </Col>
          <Col md={12} lg={8}>
            <Card.Body>
              <Card.Title className="main-hover"><strong>{plan.title}</strong></Card.Title>
              <Card.Text className="detailsP">{plan.details}</Card.Text>
              <div className="button-group">
                <Button
                  className="px-4 py-2 Btn btn1 text-white"
                  style={{ backgroundColor: '#8E7E50', border: 'none', borderRadius: '20px' }}
                  onClick={handleShowDetails}
                >
                  View Details
                </Button>
                <Button
                  className="Btn px-4 py-2 mx-1 text-white"
                  style={{ backgroundColor: '#8E7E50', border: 'none', borderRadius: '20px' }}
                  onClick={handleShowBookNow}
                >
                  Book Now
                </Button>
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Card>

      <Modal show={showDetails} onHide={handleCloseDetails}>
        <Modal.Header closeButton>
          <Modal.Title>Plan Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <img src={plan.imgSrc} alt="Plan" style={{ width: "100%", height: "170px" }} />
            </Col>
            <Col>
              <h4 className="main-hover">{plan.title}</h4>
              <p>{plan.details}</p>
              <p>
  <strong>Price: </strong>
  <a 
    href="tel:+919259171073" 
    style={{ 
      display: "inline-flex", 
      alignItems: "center", 
      gap: "8px", 
      textDecoration: "none", // Default no underline
      padding: "5px 10px", 
      borderRadius: "8px", 
      color: "#333", 
      fontWeight: "bold", 
      position: "relative", // Required for hover pseudo-element
    }}
    onMouseEnter={(e) => e.target.style.textDecoration = "underline"} // Add underline on hover
    onMouseLeave={(e) => e.target.style.textDecoration = "none"} // Remove underline on hover
  >
    <span 
      style={{ 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        width: "24px", 
        height: "24px", 
        borderRadius: "50%", 
        backgroundColor: "green", 
        color: "#fff" 
      }}
    >
      <FaPhone style={{ fontSize: "0.8rem" }} />
    </span>
    Inquiry
  </a>
</p>





            </Col>
          </Row>
          <hr />
          <h5 className="fw-bold mb-3">
            Recommended
            <span className="text main-hover"> Itinerary </span>
          </h5>
          <div className="itinerary">
            {plan.itinerary.map((item, index) => (
              <div key={index} className="itinerary-item mb-4">
                <div className="itinerary-point"></div>
                <Row>
                  <Col md={2} className="itinerary-day">
                    <strong>{item.day}</strong>
                  </Col>
                  <Col md={10} className="itinerary-content">
                    <h6 className="main-hover">{item.location}</h6>
                    <p>{item.description}</p>
                  </Col>
                </Row>
              </div>
            ))}
            <div className="itinerary-line"></div>
          </div>
          <hr />
          <h5>Pricing: </h5>
          <table className="table">
  <thead>
    <tr>
      <th>Hotel Category</th>
      <th>Package cost per adult</th>
    </tr>
  </thead>
  <tbody>
    {plan.pricing.map((item, index) => (
      <tr key={index}>
        <td>{item.category}</td>
        <td>
          <a
            href="tel:+919259171073"
            style={{
              display: "inline-flex",
              alignItems: "center",
              gap: "5px",
              textDecoration: "none", // Default no underline
            }}
            onMouseEnter={(e) => (e.target.style.textDecoration = "underline")} // Add underline on hover
            onMouseLeave={(e) => (e.target.style.textDecoration = "none")} // Remove underline on hover
          >
            {item.cost} <FaPhone style={{ fontSize: "1em" }} />
          </a>
        </td>
      </tr>
    ))}
  </tbody>
</table>
          <p className="small">
            The above cost is for per person. All transfers and sightseeing by Sedan. Any increase/decrease in the strength of the group would change the pricing.
          </p>
          <hr />
          <h5>Inclusions:</h5>
          <ul className="small">
            {plan.inclusions.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBookNow} onHide={handleCloseBookNow}>
        <Modal.Header closeButton>
          <Modal.Title>Book Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control as="select" required>
                  <option>- Select -</option>
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Ms.</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  name="Name"
                  value={data.Name}
                  type="text"
                  placeholder="Enter Name"
                  required={true}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                name="mobNumber"
                value={data.mobNumber}
                onChange={handleChange}
                type="text"
                placeholder="Enter Mobile"
                required={true}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="Email"
                value={data.Email}
                onChange={handleChange}
                type="email"
                placeholder="Enter email"
                required={true}
              />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  name="Date"
                  value={data.Date}
                  onChange={handleChange}
                  type="date"
                  placeholder="Select Date"
                  required={true}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formNumberOfAdults">
                <Form.Label>Number of Adults</Form.Label>
                <Form.Control
                  name="Numberofadults"
                  value={data.Numberofadults}
                  onChange={handleChange}
                  type="number"
                  placeholder="Number of Adults"
                  required={true}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                name="Category"
                value={data.Category}
                onChange={handleChange}
                as="select"
                required={true}
              >
                <option>- Select -</option>
                <option>Budget</option>
                <option>Deluxe</option>
                <option>Luxury</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                name="Message"
                value={data.Message}
                onChange={handleChange}
                as="textarea"
                rows={3}
                placeholder="Enter Message"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBookNow}>
            Close
          </Button>
          <Button
            variant={buttonVariant}
            onClick={submitHandler}
            disabled={isLoading}
          >
            {isLoading ? 'Submitting...' : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default PlanCard;
