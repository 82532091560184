import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../pagesCss/FAQ.css";

const FAQ = () => {
  useEffect(() => {
    AOS.init({ once: true }); // Initialize AOS once
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const faqData = [
    {
      title: "Q: What services do you offer?",
      content:
        "A: We offer a variety of travel services including hotel reservations, guided tours, and travel packages.",
    },
    {
      title: "Q: How can I book a tour?",
      content:
        "A: You can book a tour directly through our website by selecting your desired package and following the booking instructions. Alternatively, you can contact our customer service team for assistance.",
    },
    {
      title: "Q: Can I customize my travel package?",
      content:
        "A: Yes, we offer customizable travel packages. Please contact our customer service team to discuss your specific requirements.",
    },
    {
      title: "Q: Do you offer group discounts?",
      content:
        "A: Yes, we offer discounts for group bookings. Please contact us for more details.",
    },
    {
      title: "Q: How do I confirm my booking?",
      content:
        "A: After completing the booking process, you will receive a confirmation email with all the details of your trip.",
    },
    {
      title: "Q: What is your cancellation policy?",
      content:
        "A: Our cancellation policy varies depending on the type of service and package booked. Please refer to our cancellation policy page for detailed information.",
    },
    {
      title: "Q: Can I make changes to my booking?",
      content:
        "A: Yes, changes can be made to your booking subject to availability and our terms and conditions. Please contact our customer service team for assistance.",
    },
    {
      title: "Q: Do you offer travel assistance?",
      content:
        "A: Yes, we provide 24/7 travel assistance to help you with any issues or emergencies that may arise during your trip.",
    },
    {
      title: "Q: What destinations do you cover?",
      content:
        "A: We offer tours to a wide range of destinations worldwide. Please check our destinations page for more information.",
    },
    {
      title: "Q: Are your tours guided?",
      content:
        "A: Yes, most of our tours are guided by experienced professionals to ensure you have the best experience possible.",
    },
    {
      title: "Q: How can I contact customer support?",
      content:
        "A: You can contact our customer support team via phone and email on our website.",
    },
    {
      title: "Q: What are your customer support hours?",
      content:
        "A: Our customer support team is available 24/7 to assist you with your travel needs.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    console.log("Submitted comment:", comment);
    setTimeout(() => {
      console.log("Submitted comment:", comment);
      setComment("");
      setIsSubmitting(false);
    }, 1000);
    setComment("");
  };

  return (
    <section className="bg-light pb-4">
      <div className="faq-container pt-5" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
        <h2 className="faq-heading fw-bold text-center mb-5 fs-2 ">
          Frequently <span className="main-hover">Asked Questions</span>
        </h2>
        <p>
          "Answers to Your Common Questions: Discover Everything You Need to
          Know!"
        </p>
        {faqData.slice(0, showAll ? faqData.length : 6).map((faq, index) => (
          <div
            key={index}
            className="faq-item"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="2000"
          >
            <div className="faq-title" onClick={() => handleToggle(index)}>
              {faq.title}
              <span className="faq-icon">
                {activeIndex === index ? "-" : "+"}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-content">
                <p>{faq.content}</p>
              </div>
            )}
          </div>
        ))}
        <div
          className="see-more-container"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
        >
          {!showAll && faqData.length > 6 && (
            <button
              className="see-more-button"
              onClick={() => setShowAll(true)}
            >
              <span className="see-more-text">See More</span>
            </button>
          )}
          {showAll && (
            <button
              className="see-more-button"
              onClick={() => setShowAll(false)}
            >
              <span className="see-more-text">Show Less</span>
            </button>
          )}
        </div>
        
      </div>
    </section>
  );
};

export default FAQ;
