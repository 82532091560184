const contentMap = {
  //Mathura
  "shopping-places-in-mathura": {
    title: "Shopping Places in Mathura",
    sections: [
      {
        heading: "#1 - Holy Gate Market in Mathura",
        image: "/images/holigatemarket.jpeg",
        description: `
  **Holy Gate Market** in Mathura is one of the most popular shopping areas for both pilgrims and tourists. Located near the **Shri Krishna Janmabhumi Temple**, this market is an essential stop for visitors seeking religious items, souvenirs, and a taste of the vibrant local culture.
  
  ### Location
  Holy Gate Market is situated near the **Holy Gate**, which is one of the prominent entry points to the Shri Krishna Janmabhumi Temple. This location makes it convenient for pilgrims and tourists to explore the area while offering a range of spiritual items to enhance their visit.
  - **Address**: Near Holy Gate, Mathura, Uttar Pradesh, India.
  - The market is easily accessible and serves as a hub for local vendors offering a variety of goods.
  
  ### What to Buy
  Visitors to Holy Gate Market can find a wide array of items that cater to both spiritual and cultural interests:
  - **Religious Items**: Krishna idols, temple accessories, brassware, puja items, and spiritual books, ideal for devotees wishing to take home a piece of their pilgrimage.
  - **Souvenirs**: Traditional clothing, silver jewelry, wooden toys, and beautifully crafted handicrafts that showcase the region’s artistic heritage.
  - **Mathura Peda**: A famous local sweet, Mathura Peda is a must-try delicacy. You can find it in various shops along the market street, offering the perfect souvenir to take back home.
  
  ### Market Timing
  The market typically operates from **9:00 AM to 9:00 PM**, though timings may vary during peak tourist seasons or special festivals. During major festivals like **Janmashtami, Holi, and Govardhan Puja**, the market may stay open later to accommodate the increased footfall. The vibrant atmosphere during these festivals adds to the charm of shopping here.
  
  Holy Gate Market provides a unique shopping experience, blending religious significance with local traditions and offering a variety of products for all visitors.`,
      },
      {
        heading: "#2 - Chatta Bazaar in Mathura",
        image: "/images/chattabazar.jpeg",
        description: `
  **Chatta Bazaar** is one of the oldest and most famous markets in Mathura, renowned for its vibrant atmosphere and variety of goods, especially those related to **Lord Krishna** and traditional Indian crafts. This bustling market is a must-visit for anyone exploring the spiritual and cultural heart of Mathura.
  
  ### Location
  Chatta Bazaar is conveniently located near **Dwarkadhish Temple** and **Vishram Ghat**, two of the most important religious landmarks in Mathura. This proximity makes it an ideal shopping destination for pilgrims and tourists visiting these sacred sites.
  - **Address**: Chatta Bazaar, Mathura, Uttar Pradesh, India.
  - The market is a hub for those seeking both spiritual items and local handicrafts.
  
  ### What to Buy
  Chatta Bazaar offers a wide array of items, both for religious purposes and cultural appreciation:
  - **Religious Items**: Krishna idols, **puja samagri** (worship items), brass and copper items, temple bells, and floral garlands. These are ideal for devotees wishing to bring home a piece of their spiritual journey.
  - **Traditional Clothing**: You’ll find a variety of **sarees**, **kurta-pajamas**, traditional jewelry, and scarves, perfect for those wanting to embrace Indian culture or bring back a souvenir.
  - **Handicrafts**: The market is known for its beautifully crafted **wooden sculptures**, **paintings**, **terracotta items**, and other handmade artifacts, each reflecting the artistic tradition of the region.
  - **Local Sweets**: Mathura’s famous **pedas** and other local sweets are available at multiple stalls, offering a sweet treat for visitors to enjoy or take home.
  - **Spiritual Books**: Books on Hindu philosophy, particularly related to **Lord Krishna**, are available for those wanting to delve deeper into the teachings of this revered deity.
  
  ### Market Timing
  Chatta Bazaar typically operates from **9:00 AM to 9:00 PM**. During special festival seasons like **Janmashtami** or **Holi**, the market may stay open longer to accommodate the increased flow of tourists and pilgrims, making it a lively and exciting place to visit.
  
  Chatta Bazaar is a vibrant shopping hub offering a perfect blend of spirituality, culture, and tradition.`,
      },
      {
        heading: "#3 - Krishna Nagar Market in Mathura",
        image: "/images/krishnanagarmarket.jpeg",
        description: `
  **Krishna Nagar Market** is a popular local market in Mathura, renowned for its wide variety of religious, spiritual items, and traditional Indian goods. This vibrant market attracts both pilgrims and tourists who seek to take home souvenirs or spiritual offerings.
  
  ### Location
  Krishna Nagar Market is located near the **Krishna Nagar area**, making it easily accessible for visitors to Mathura, especially for those visiting the **Shri Krishna Janmabhumi Temple** and other religious landmarks in the city.
  - **Address**: Krishna Nagar Market, Mathura, Uttar Pradesh, India.
  - This market offers an authentic local shopping experience, reflecting the spiritual essence of Mathura.
  
  ### What to Buy
  Krishna Nagar Market offers a variety of religious and cultural items that are perfect for pilgrims, tourists, and those interested in Indian traditions:
  - **Religious Items**: Idols of **Lord Krishna** and **Radha**, **brassware**, **temple accessories**, **puja samagri** (worship items), and other spiritual offerings are available for those looking to enhance their devotion or take home sacred mementos.
  - **Clothing**: The market features a wide range of traditional **Indian clothing**, including **sarees**, **kurta-pajamas**, and **scarves**, perfect for special occasions or as souvenirs.
  - **Handicrafts**: Krishna Nagar Market is well-known for its traditional **wooden carvings**, **terracotta items**, and **brass statues**, showcasing India’s rich craftsmanship.
  - **Jewelry**: Visitors can purchase **silver jewelry** and traditional Indian jewelry pieces, ideal for gifting or personal adornment.
  - **Sweets**: Mathura’s famous **pedas** and other local sweets are widely available, allowing visitors to enjoy a taste of the city’s culinary traditions.
  - **Religious Books**: For those interested in Hindu philosophy and the teachings of **Lord Krishna**, a variety of **religious books** and scriptures are sold at the market.
  
  ### Market Timing
  Krishna Nagar Market operates from **9:00 AM to 9:00 PM**, providing ample time for visitors to shop. During festive seasons like **Janmashtami** or **Holi**, the market stays open later to accommodate the large number of visitors and devotees coming to Mathura.
  
  Krishna Nagar Market is a must-visit spot for those seeking spiritual items, traditional crafts, and a piece of Mathura’s rich cultural heritage.`,
      },

      // Add more sections as needed
    ],
  },
  "places-to-visit-in-mathura": {
    title: "Place to visit in Mathura",
    sections: [
      {
        heading: "1# - Krishna Janmabhoomi: The Birthplace of Lord Krishna",
        image: "/images/janambhoomi.jpg", // Replace with actual image URL
        description: `
**Krishna Janmabhoomi** refers to the birthplace of Lord Krishna, located in Mathura. This revered site marks the place where Lord Krishna, the eighth incarnation of Lord Vishnu, was born over 5,000 years ago.

### History of Krishna Janmabhoomi
- The original temple was believed to have been destroyed and rebuilt multiple times.
- The first temple was built during the Maurya Empire and later destroyed by Mahmud of Ghazni.
- The current structure is part of a 20th-century complex.

### Key Attractions
1. **Birthplace of Krishna**: The exact spot marked within the temple.
2. **The Prison Cell (Garbh Griha)**: Believed to be the jail where Lord Krishna was born.
3. **The Krishna Janmabhoomi Temple**: The main temple dedicated to Lord Krishna.
4. **Shahji Ki Baithak**: A historic site for religious discussions.
5. **Keshava Deo Temple**: A prominent nearby temple.

### Festivals at Krishna Janmabhoomi
- **Janmashtami**: Celebrating Lord Krishna’s birth with grand processions, music, and prayers.
- **Holi**: Known for its vibrant and playful celebrations.

### Accessibility
- **By Air**: Nearest airport is Agra (60 km) or Delhi (180 km).
- **By Train**: Mathura Junction is well-connected to major cities.
- **By Road**: Accessible via highways from Delhi, Agra, and nearby cities.

Krishna Janmabhoomi is a must-visit for those seeking spiritual enlightenment and a glimpse into India’s cultural heritage.`,
      },
      {
        heading: "2# - Dwarkadhish Temple: A Symbol of Lord Krishna's Kingship",
        image: "/images/Dwarkadhish.jpg", // Replace with actual image URL
        description: `
The **Dwarkadhish Temple** in Mathura is a renowned Hindu temple dedicated to Lord Krishna. Located in Uttar Pradesh, it stands distinct from the Dwarkadhish Temple in Dwarka, Gujarat. It holds immense religious and historical importance, attracting thousands of devotees.

### History and Significance
- Built in the 19th century by Gokuladas Parikh, a devoted merchant.
- Dedicated to Lord Krishna as **Dwarkadhish**, symbolizing him as the King of Dwarka.
- Located near Krishna Janmabhoomi, the birthplace of Lord Krishna.
- Features an idol of Lord Krishna in royal attire, symbolizing divine authority.

### Architectural Highlights
1. **Main Shrine**: Houses the beautifully adorned idol of Lord Krishna.
2. **Shikhara**: A towering spire with intricate carvings.
3. **Prakaram**: A circumambulatory path for devotees to walk around the temple.
4. **Carved Walls**: Depict scenes from Hindu mythology and Krishna's life.

### Visiting Timings
**Morning:**- 5:00 AM to 12:00 PM: Includes the Mangala Aarti (5:00 AM to 6:00 AM).  \n
**Afternoon Break:** - 12:00 PM to 4:00 PM: Temple remains closed.\n
**Evening:**- 4:00 PM to 9:00 PM: Includes Sandhya Aarti (6:00 PM to 7:00 PM).

**Special Note**: During festivals like Janmashtami, the temple remains 

The Dwarkadhish Temple in Mathura is a spiritual haven with rich history and architectural splendor. A visit here provides not only a religious experience but also a glimpse into the vibrant cultural heritage of Mathura.
  `,
      },
      {
        heading: "3# - Vishram Ghat: A Sacred Haven in Mathura",
        image: "/images/vishramGhat.jpg", // Replace with the actual image URL
        description: `
**Vishram Ghat**, located on the banks of the Yamuna River in Mathura, Uttar Pradesh, is a highly revered site associated with the life of Lord Krishna. Known for its spiritual significance, this ghat attracts thousands of pilgrims and visitors, especially during festivals.

### Religious Significance
- **Lord Krishna’s Resting Place**: It is believed to be the place where Lord Krishna rested after defeating the demon Kansa.
- **Sacred Bath**: Pilgrims bathe in the Yamuna to cleanse their sins and receive spiritual blessings.
- **Pilgrimage Hub**: A key site during major festivals like Janmashtami, Diwali, and Kartik Purnima.

### Key Features
1. **Stone Steps**: The ghat has wide steps leading down to the river, where people perform rituals or meditate.
2. **Temples**: Several small temples along the ghat honor Lord Krishna and Radha.
3. **Aarti Rituals**: Morning and evening aartis create a divine atmosphere, especially with the ghat illuminated at night.

### Best Times to Visit
**Morning:**- Early morning (5:00 AM – 7:00 AM) for a peaceful dip and morning aarti.\n
**Evening:**- Evening aarti (6:00 PM – 7:00 PM) is a vibrant experience with the ghat glowing in lights.

### Accessibility
- **Location**: Near Krishna Janmabhoomi Temple in Mathura.
- **By Air**: Nearest airports are in Agra (50 km) and Delhi (150 km).
- **By Train**: Mathura Junction is well-connected to major cities.

### Nearby Attractions
1. **Krishna Janmabhoomi Temple**: The birthplace of Lord Krishna.
2. **Banke Bihari Temple & Prem Mandir**: Famous temples in nearby Vrindavan.

Vishram Ghat is a must-visit destination for anyone seeking spiritual peace and wishing to experience the divine aura tied to Lord Krishna’s life.
  `,
      },
      {
        heading: "4# - Birla Mandir: A Divine Abode in Mathura",
        image: "/images/Birla-mandir.jpg",
        description: `
  **Birla Mandir** in Mathura, Uttar Pradesh, is a serene temple dedicated to Lord Krishna, built by the Birla family. Known for its spiritual ambiance and modern architectural style, the temple attracts pilgrims and tourists seeking peace and divine blessings.

### Highlights
- **History**: Constructed in the early 20th century by the philanthropic Birla family.
- **Dedication**: Devoted to Lord Krishna, the deity of Mathura, his birthplace.
- **Architecture**: Combines traditional Hindu designs with contemporary elements, featuring intricate carvings, towering spires, and serene gardens.

### Timings
- **Morning**: Open from 5:30 AM to 12:00 PM; Mangala Aarti starts at 5:30 AM.
- **Evening**: Open from 4:00 PM to 9:00 PM; Sandhya Aarti at 6:30 PM.

### Festivals
- **Janmashtami**: Special prayers and decorations mark Krishna’s birthday.
- **Diwali & Holi**: The temple comes alive with vibrant rituals and celebrations.

### Accessibility
- **By Air**: Nearest airports: Agra (50 km), Delhi (150 km).
- **By Train**: Mathura Junction is well-connected.
- **By Road**: Accessible from major cities via National Highways.

### Nearby Attractions
1. **Krishna Janmabhoomi Temple**: Krishna’s birthplace.
2. **Vishram Ghat**: A sacred spot on the Yamuna River.
3. **Vrindavan Temples**: Banke Bihari Temple, Prem Mandir, and more.


Birla Mandir offers a perfect blend of devotion, peace, and architectural beauty, making it a must-visit for spiritual seekers and history enthusiasts.  `,
      },

      {
        heading: "5# - Galteshwar Mahadev: An Ancient Shiva Temple in Mathura",
        image: "/images/Galteshwar-Mahadev.jpg",
        description: `**Galteshwar Mahadev Temple**, near Mathura, is a sacred site dedicated to Lord Shiva, believed to be over 5,000 years old. Nestled by the Yamuna River, it offers a tranquil atmosphere and deep spiritual significance.

### Highlights
- **Historical Roots**: One of the oldest Shiva temples, associated with the sage Galta Rishi.
- **Shivalinga Worship**: Lord Shiva is worshipped in the form of a Shivalinga, attracting devotees year-round.
- **Serene Surroundings**: Located near lush greenery and the Yamuna River, perfect for meditation and prayers.

### Timings
- **Morning**: 5:00 AM - 12:00 PM; Morning Aarti at 5:30 AM.
- **Evening**: 4:00 PM - 9:00 PM; Evening Aarti at 7:00 PM.

### Festivals
- **Maha Shivaratri**: Celebrated with night-long prayers and rituals.
- **Shravan Maas**: A sacred month for Shiva devotees, drawing large crowds.

### Accessibility
- **By Air**: Agra Airport (50 km) and Delhi Airport (150 km).
- **By Train**: Mathura Junction is the nearest railway station.
- **By Road**: Well-connected via National Highways.

### Nearby Attractions
1. **Krishna Janmabhoomi**: Krishna's birthplace.
2. **Vishram Ghat**: Sacred ghat on the Yamuna River.
3. **Vrindavan Temples**: Banke Bihari Temple, Prem Mandir, and others.


Galteshwar Mahadev Temple is a peaceful retreat for Lord Shiva devotees, blending history, spirituality, and natural beauty. A must-visit for anyone exploring Mathura and its divine heritage.
  `,
      },
      {
        heading: "6# - Kans Qila: The Historic Fort of Mathura",
        image: "/images/KansQila.jpg",
        description: `
**Kans Qila**, located near the Yamuna River in Mathura, Uttar Pradesh, is a significant historical and religious site associated with the tyrant ruler Kansa, maternal uncle of Lord Krishna. This ancient fort, now in ruins, draws pilgrims and history enthusiasts alike.

### Highlights
- **Historical Significance**: Believed to be the seat of Kansa’s rule, where he conspired against Lord Krishna.
- **Krishna’s Triumph**: Marks the pivotal event of Lord Krishna defeating Kansa, symbolizing the victory of good over evil.
- **Architectural Remains**: Explore remnants of the fort's walls and gates, offering a glimpse of ancient fortifications.

### What to See
1. **Ruins of the Fort**: Walk through the historical remains and visualize its former grandeur.
2. **Krishna Temple**: A small shrine dedicated to Lord Krishna inside the fort, attracting pilgrims year-round.
3. **Scenic Views**: Located on elevated ground, the fort offers panoramic views of Mathura and the Yamuna River.

### Timings
- **Morning**: 6:00 AM - 12:00 PM for a peaceful exploration.
- **Evening**: 4:00 PM - 6:00 PM to enjoy sunset views.

### Festivals
- **Janmashtami**: Witness vibrant celebrations commemorating Krishna's victory over Kansa.
- **Kartik Purnima & Diwali**: Popular times for pilgrims to visit.

### Accessibility
- **By Air**: Agra Airport (50 km) or Delhi Airport (150 km).
- **By Train**: Mathura Junction is the nearest railway station.
- **By Road**: Easily accessible via local transport or private vehicles.

### Nearby Attractions
1. **Krishna Janmabhoomi**: Krishna's birthplace.
2. **Vishram Ghat**: Sacred Yamuna ghat.
3. **Vrindavan Temples**: Banke Bihari Temple and Prem Mandir.

Kans Qila is a must-visit destination in Mathura, offering a mix of mythology, history, and spirituality. Its serene location and connection to Lord Krishna’s life make it a significant stop for pilgrims and history enthusiasts.
  `,
      },
      {
        heading: "7# - Keshav Devji Temple: A Divine Landmark in Mathura",
        image: "/images/Keshav-devji.jpg",
        description: `Keshav Devji Temple, dedicated to Lord Krishna, is a revered site in Mathura, believed to be near Krishna's birthplace. Known for its spiritual significance, it attracts pilgrims from across the globe.

### Highlights
- **Historical Significance**: Built close to Krishna Janmabhoomi, it honors Lord Krishna's childhood form, Keshav Dev.
- **Temple Features**: Intricate carvings, a serene sanctum, and a deity idol adorned in vibrant attire.
- **Festivals**: Grand celebrations during Janmashtami, Holi, and Diwali.


### What to See
- **Sacred Sanctum**: Houses the beautifully adorned idol of Keshav Dev, offering a serene space for prayer.
- **Temple Architecture**: North Indian design with intricate carvings and vibrant paintings depicting Krishna’s life.
- **Gardens**: Peaceful green surroundings ideal for reflection.
- **Nearby Shrines**: Smaller shrines dedicated to Krishna's various forms and other deities.



### Timings
- **Morning**: 5:00 AM - 12:00 PM (Mangala Aarti at 5:30 AM).
- **Evening**: 4:00 PM - 9:00 PM (Sandhya Aarti at 6:30 PM).

### Accessibility
- **Nearest Airport**: Agra (50 km) or Delhi (150 km).
- **Train Station**: Mathura Junction.
- **By Road**: Well-connected to nearby cities like Agra and Delhi.

### Nearby Attractions
- Krishna Janmabhoomi, Vishram Ghat, and Vrindavan’s iconic temples.

Keshav Devji Temple offers a peaceful and spiritually enriching experience, making it a must-visit for Krishna devotees.
  `,
      },
      {
        heading:
          "8# - Mathura Yamuna Aarti: A Spiritual Experience by the River",
        image: "/images/Yamuna-Arti.jpg",
        description: `The **Mathura Yamuna Aarti** is a sacred ritual performed at Vishram Ghat on the banks of the Yamuna River, honoring the goddess Yamuna and her divine connection with Lord Krishna.

### Significance
- The Yamuna River is deeply connected to Lord Krishna’s life and is believed to be his consort in the form of a river goddess.
- The aarti, which includes hymns, lamps (diyas), incense, and offerings, is performed to seek blessings for health, wealth, and spiritual well-being.

### Timings
- **Morning Aarti**: 6:00 AM - 7:00 AM, a serene and peaceful event.
- **Evening Aarti**: 6:00 PM - 7:00 PM, more vibrant with bells, chants, and thousands of glowing lamps.

### What to Expect
- **Chanting & Hymns**: Devotional songs and prayers are sung, invoking blessings.
- **Waving of Lamps**: A central ritual, with large oil lamps waved in honor of Yamuna Mata.
- **Offerings & Incense**: Flowers, fruits, and incense are offered, creating a spiritual atmosphere.

### Best Time to Visit
- **Evening Aarti**: Ideal for a festive, vibrant experience with a large crowd.
- **Morning Aarti**: Best for a peaceful, serene experience in the quiet of the morning.

### Location
- **Vishram Ghat** is the main spot to witness the aarti, easily accessible from Mathura city.

### Nearby Attractions
- Krishna Janmabhoomi, Vishram Ghat, and Vrindavan’s temples.

The Mathura Yamuna Aarti offers a spiritually enriching experience that honors the divine connection of Lord Krishna and the Yamuna River.
`,
      },
      {
        heading: "9# - Bhuteshwar Temple: A Sacred Site for Lord Shiva Worship",
        image: "/images/Bhuteshwar-Temple.jpg",
        description: `
The **Bhuteshwar Temple**, located in Mathura, Uttar Pradesh, is a revered Hindu temple dedicated to Lord Shiva. Known for its tranquil environment and spiritual significance, it attracts devotees seeking peace and divine blessings.

### Significance
- **Lord Shiva**: The temple is dedicated to Lord Shiva, known as Bhuteshwar (Lord of all beings), and is believed to help remove obstacles and provide mental peace.
- **Historical & Mythological Importance**: The temple is tied to ancient Hindu legends, and its name signifies the removal of suffering and the attainment of peace and moksha (liberation).

### Architecture
- **Shiva Lingam**: The primary deity is the Shiva Lingam, which is worshipped with offerings like water, milk, and honey.
- **Design**: Simple yet elegant with intricate carvings and small shrines dedicated to other deities.

### Timings
- **Morning**: 5:00 AM - 12:00 PM, with Mangala Aarti starting at 5:30 AM.
- **Evening**: 4:00 PM - 9:00 PM, featuring Sandhya Aarti at 6:30 PM.
- **Night**: The temple closes at 9:00 PM after Shayan Aarti.

### Special Festivals
- **Maha Shivaratri**: Celebrated with grand aartis and prayers, attracting large crowds.
- **Shravan Month**: A special time for Lord Shiva worship.
- **Diwali & Navratri**: The temple also hosts prayers and aartis during these festivals.

### Location & Accessibility
- **Location**: Situated near the Yamuna River, close to other important sites in Mathura.
- **By Air**: Agra Airport (50 km), Delhi Airport (150 km).
- **By Train**: Mathura Junction, well-connected to major cities.
- **By Road**: Accessible by buses, taxis, and private vehicles.

### Nearby Attractions
- **Krishna Janmabhoomi Temple**, **Vishram Ghat**, **Dwarkadhish Temple**, and **Vrindavan** are nearby attractions.

The Bhuteshwar Temple offers a peaceful setting for worship and reflection, making it a must-visit for Shiva devotees.
  `,
      },

      // Add more sections as needed
    ],
  },

  "things-to-do-in-mathura": {
    title: "Things to do in Mathura",
    sections: [
      {
        heading: "1# - Take a Boat Ride on the Yamuna River",
        image: "/images/Boat.jpeg", // Replace with actual image URL
        description: `Embark on a tranquil boat ride on the Yamuna River near Vishram Ghat, a must-do experience for anyone visiting Mathura. The calm waters offer a peaceful retreat, allowing you to soak in the serene ambiance while admiring the stunning views of ancient temples and the vibrant ghats lining the riverbanks. The boat ride is a unique way to explore the city, offering an intimate connection with Mathura’s spiritual and cultural heritage.

Vishram Ghat, a significant location in Hindu mythology, is believed to be the place where Lord Krishna rested after defeating Kansa. This rich historical and religious connection adds an extra layer of meaning to the boat ride. As you glide through the waters, you will see devotees performing daily rituals, priests chanting sacred mantras, and the bustling life along the ghats, offering a glimpse into the local spiritual practices. The experience is peaceful and meditative, making it an ideal way to connect with the city’s spiritual heart.

The boat ride is especially enchanting during sunrise or sunset. The soft morning light or the golden hues of sunset paint the sky with shades of orange and pink, creating a picturesque scene reflected in the calm waters of the Yamuna. This natural beauty, combined with the quiet and spiritual atmosphere, makes the ride even more magical. As you drift along the river, you will feel as though you are transported to another world—one of tranquility, devotion, and timeless beauty.

For visitors, the boat ride offers more than just a scenic tour; it is an opportunity to experience the soul of Mathura. The river has long been a symbol of devotion, and this journey allows you to witness the daily connection between the people and their sacred river. The peaceful ambiance, the devotion of the locals, and the stunning surroundings make this a truly memorable experience.

Don’t miss the chance to take a boat ride on the Yamuna River near Vishram Ghat during your visit to Mathura. It offers a beautiful way to experience the city’s cultural heritage, connect with its spiritual essence, and enjoy the serene beauty of the river that has been the backdrop to centuries of devotion.














`,
      },
      {
        heading: "#2 - Evening Aarti at Yamuna River Ghats",
        image: "/images/aarti.jpeg", // Replace with actual image URL
        description: `The evening aarti at the Yamuna River ghats in Mathura is a spiritually enriching and visually captivating experience that immerses visitors in the sacred atmosphere of this holy city. Taking place every evening at dusk, the aarti is a centuries-old tradition where devotees gather to offer their prayers to the Yamuna River, which holds immense religious significance in Hinduism. For those visiting Mathura, this ritual is a highlight of their spiritual journey, offering a profound sense of peace and connection.

As the sun sets and the sky transitions into shades of orange and purple, the ghats along the Yamuna River come alive with the sounds of devotional chanting, the ringing of bells, and the rhythmic beat of drums. The air is filled with the soft fragrance of incense, and the calm waters of the river reflect the glow of countless oil lamps held by the devotees. The sight is nothing short of magical, as the flickering lamps create a mesmerizing dance of light on the surface of the river.

The aarti ceremony itself is a beautiful and synchronized display of devotion. Priests, dressed in traditional attire, lead the ritual by performing sacred prayers and waving large oil lamps in intricate movements. The lamps are offered to the river, symbolizing light triumphing over darkness, and are accompanied by the chanting of mantras and hymns, adding to the spiritual atmosphere. The sound of conch shells being blown and the ringing of cymbals further enhance the sacredness of the moment, drawing everyone present into the experience.

Visitors to Mathura are often struck by the peaceful and mystical ambiance that surrounds the evening aarti. The devotion and reverence of the locals create an atmosphere of deep spirituality, which resonates with anyone who witnesses the ceremony. The sight of the lamps glowing against the backdrop of temples and the sound of the river gently flowing, coupled with the fervent chanting, offers a sense of calm and tranquility. Many visitors find themselves reflecting on the beauty and significance of the moment, making the aarti an unforgettable experience.

The evening aarti at the Yamuna River ghats is not just a visual spectacle but a spiritual journey that connects visitors to the divine. Whether you're seeking inner peace, a deeper understanding of the traditions, or simply a beautiful experience in a sacred setting, the evening aarti offers it all. It is a profound way to experience the essence of Mathura’s spiritual heritage and is a must-see for anyone visiting this holy city.






`,
      },
      {
        heading: "#3 - Explore the Mathura Museum",
        image: "/images/museum.jpeg", // Replace with actual image URL
        description: `For history enthusiasts and art lovers, the Mathura Museum is a treasure trove that offers a fascinating glimpse into the ancient history and cultural heritage of Mathura and the surrounding region. Situated in the heart of the city, the museum is home to an impressive collection of artifacts, sculptures, and paintings that span several centuries, shedding light on the rich artistic traditions of the area. The Mathura Museum is particularly famous for its extensive collection from the Mathura Art School, a renowned center of art during the Kushan period.

The museum’s displays feature a wide range of sculptures, many of which depict gods, goddesses, and mythological figures. The Mathura Art School, which flourished between the 1st and 3rd centuries CE, is known for its exquisite sculptures made from red sandstone. These sculptures, often depicting scenes from Hindu mythology, showcase the skill and creativity of ancient artisans. Visitors will find representations of Lord Buddha, Lord Vishnu, and various other deities, all crafted with intricate detail and precision. The museum provides valuable insight into the evolution of Indian art and religious iconography during this period.

One of the highlights of the museum is its collection of sculptures that reflect the fusion of Indian and Greco-Roman influences, a characteristic of the Kushan period. This cultural exchange is evident in the naturalistic portrayal of figures, as well as in the distinctive Hellenistic styles of drapery and facial features. The museum also houses a remarkable collection of coins, pottery, and inscriptions, each telling a story about the trade, culture, and daily life in ancient Mathura. These items offer a window into the economic and social landscape of the time.

In addition to the sculptures, the Mathura Museum also displays a number of paintings and reliefs that further enrich the understanding of the region’s artistic achievements. The paintings, many of which are related to religious themes, showcase the evolution of art from the early centuries of the Common Era.

A visit to the Mathura Museum is not just a chance to admire the beauty of ancient art but also an opportunity to understand the deep historical and cultural roots of Mathura. The museum allows visitors to trace the city's importance as a major cultural and religious center throughout history. Whether you are a history buff or simply curious about the region’s past, the Mathura Museum offers a captivating journey into the heart of India’s ancient civilization.








`,
      },
      {
        heading: "#4 - Shop for Local Handicrafts",
        image: "/images/Handcraft.jpeg", // Replace with actual image URL
        description: `Exploring Mathura's vibrant markets is an experience that beautifully combines cultural immersion with the joy of discovering unique, handcrafted treasures. Holi Gate Market, one of the most popular shopping destinations, is a bustling hub where local artisans display their exceptional skills through a variety of products. From intricately designed brass artifacts to vibrant textiles, the markets offer a glimpse into Mathura's rich cultural heritage.

Among the most sought-after items are the finely crafted brass products, which showcase the expertise of Mathura’s artisans. These pieces range from functional household items to decorative artifacts, often featuring intricate patterns and designs. The craftsmanship and cultural significance behind each item make them ideal keepsakes and conversation pieces for your home.

Another highlight of Mathura’s markets is the collection of exquisite Krishna paintings. Depicting scenes from the life of Lord Krishna, these artworks are rendered in vibrant colors and meticulous detailing, reflecting the spiritual essence of the city. These paintings are more than decorative pieces; they are spiritual expressions that resonate deeply with devotees and art enthusiasts alike.

The market also offers a wide array of religious artifacts, including carved idols, prayer items, and ceremonial accessories. These items hold immense spiritual value, making them perfect for personal use or as thoughtful gifts. Additionally, colorful textiles and traditional attire provide visitors with the chance to own a piece of Mathura’s vibrant culture. Whether you’re a devotee, a collector, or simply a curious traveler, shopping for local handicrafts in Mathura is a deeply rewarding experience that allows you to take home a tangible piece of the city's heritage.
`,
      },
      {
        heading: "#5 - Taste Local Cuisine",
        image: "/images/kachoudi.jpg",
        description: `Mathura’s culinary scene is a delightful journey through flavors, traditions, and cultural significance. The city is especially famous for its sweets, with peda being its most iconic offering. Made from condensed milk and sugar, peda has a soft, melt-in-your-mouth texture that is hard to resist. This delicacy is often associated with religious rituals and celebrations, making it not just a treat but a cultural symbol.

Alongside peda, Mathura’s savory dishes offer an equally memorable gastronomic experience. Kachaudi, a deep-fried bread filled with spiced lentils or peas, is a local favorite. Served with tangy chutneys or flavorful sabzi, it’s a hearty and satisfying meal that perfectly captures the essence of North Indian street food.

For dessert lovers, rabri is a must-try. This creamy delicacy is made from thickened milk, flavored with cardamom, and garnished with nuts, creating a rich and indulgent treat. Whether enjoyed on its own or paired with other sweets, rabri embodies the richness of Mathura’s culinary traditions.

Mathura’s vibrant food culture extends beyond its iconic dishes. The city’s bustling food stalls and traditional eateries offer a variety of snacks and beverages that provide an authentic taste of local life. From lassi to puri-sabzi, every bite tells a story of Mathura’s deep-rooted culinary heritage. Sampling these delicacies is not just about satisfying your taste buds but also about experiencing the warmth and hospitality of the city.`,
      },
      {
        heading: "#6 - Attend Spiritual Discourses",
        image: "/images/mathuravisit.jpeg",
        description: `Mathura, the birthplace of Lord Krishna, is a city steeped in spirituality and devotion. Beyond its temples and religious sites, the city offers visitors a chance to engage deeply with its spiritual essence through enlightening discourses and devotional gatherings. Attending spiritual sessions or bhajans at temples and ashrams is a transformative experience, allowing participants to connect with the teachings of the Bhagavad Gita and other sacred texts.

The ISKCON Temple and other local ashrams regularly host spiritual discourses led by knowledgeable gurus and scholars. These sessions often begin with enchanting devotional music and chanting, creating an atmosphere of peace and reverence. The teachings, rooted in the Bhagavad Gita, explore profound concepts of life, spirituality, and self-realization, making them accessible and meaningful to people from all walks of life.

For those new to Indian spirituality, these gatherings offer an introduction to the timeless wisdom of the scriptures. Experienced devotees find solace and inspiration in revisiting these teachings. Beyond the philosophical insights, the sessions provide a sense of community as participants join together in devotion and learning.

Bhajans, or devotional songs, are another integral part of Mathura’s spiritual offerings. Sung in praise of Lord Krishna, these melodies are both uplifting and meditative, allowing participants to experience a sense of unity and spiritual connection. Many temples and ashrams organize evening bhajans, where the serene ambiance, glowing lamps, and collective chants create a truly mystical experience.

Engaging in these spiritual activities offers a peaceful retreat from the bustle of daily life, providing a chance to reflect and rejuvenate. For visitors, it is an opportunity to not only witness but also participate in Mathura’s spiritual heartbeat, leaving them with lasting memories and a deeper understanding of the city’s devotion-filled culture.`,
      },
    ],
  },

  "how-to-reach-mathura": {
  title: "How to Reach Mathura",
  sections: [
    {
      heading: "By Train",
      image: "/images/train.jpg",
      description: `
Mathura is well-connected by rail, and **Mathura Junction** is an important station in Uttar Pradesh.

### Key Details:
- **From Delhi**: The distance is approximately **150 km**, with superfast or express trains available from **New Delhi Railway Station (NDLS)**, **Hazrat Nizamuddin (NZM)**, and other stations. Travel time is between **2.5 to 3.5 hours**.
- **From Agra**: Mathura is about **50 km** away, and local or express trains cover this distance in around **1 hour**.
- **From Other Cities**: Major cities like **Vrindavan**, **Kanpur**, and **Jaipur** are connected to Mathura. Check **Indian Railways** for schedules.

### Significance:
Train travel is one of the most convenient and affordable options to reach Mathura, especially from nearby cities like Delhi and Agra.`,
    },
    {
      heading: "By Bus",
      image: "/images/bus.jpg",
      description: `
Mathura is well-connected by road with numerous buses from nearby cities.

### Key Routes:
- **From Delhi**: Buses from **ISBT Delhi** take about **3 to 4 hours**. Both government and private buses are available.
- **From Agra**: The distance is **50 km**, which is covered in about **1 to 1.5 hours** by bus.
- **From Jaipur**: The distance is around **240 km**, taking **5 to 6 hours** by bus.

### Significance:
Buses are an affordable and comfortable option, with several services operating throughout the day.`,
    },
    {
      heading: "By Car",
      image: "/images/car.jpg",
      description: `
Mathura is easily accessible by car via highways.

### Key Routes:
- **From Delhi**: **Yamuna Expressway** provides a direct route of **150 km**, taking about **2.5 to 3 hours**.
- **From Agra**: **NH2** covers **50 km** in about **1 hour**.
- **From Jaipur**: **NH21** connects Jaipur to Mathura in about **5 to 6 hours**.

### Significance:
Driving provides flexibility and comfort, especially if you are traveling with family or in a group. You can enjoy the scenic route and make stops along the way.`,
    },
    {
      heading: "By Air",
      image: "/images/plane.jpg",
      description: `
The nearest airport to Mathura is in **Agra**, about **60 km** away. However, the **Indira Gandhi International Airport** in **Delhi** is the primary international gateway.

### Key Airports:
- **Agra Airport**: For those flying domestically or from nearby regions, this is the closest airport.
- **Delhi Airport**: From here, you can hire a **taxi**, use public transport, or book a private vehicle. It’s about **150 km** from Mathura, taking approximately **3-4 hours** by road.

### Significance:
Air travel is ideal for travelers coming from far distances, but additional transport will be required to reach Mathura from the airport.`,
    },
    {
      heading: "By Taxi or Ride-Sharing Apps",
      image: "/images/cab.jpg",
      description: `
For a more comfortable and direct journey, taxis and ride-sharing apps like **Ola** and **Uber** are available.

### Key Benefits:
- Ideal for **groups** or **travelers with luggage**.
- Convenient pick-up from your location and direct drop-off at Mathura.

### Significance:
Taxi services provide a seamless and direct journey, making it one of the most convenient modes of transport, especially for those with limited time or luggage.`,
    },
    {
      heading: "By Bicycle",
      image: "/images/cycle.jpg",
      description: `
For adventurous travelers, **cycling** from **Delhi** or **Agra** is an option.

### Key Details:
- **Distance from Delhi**: Around **150 km**.
- **Distance from Agra**: About **50 km**.

### Significance:
While cycling is an adventurous and eco-friendly way to travel, it requires stamina and careful planning due to the long distances and road conditions. This option is best suited for experienced cyclists.`,
    },
    
  ],
},


  "festivals-in-mathura": {
    title: "Festivals in Mathura",
    sections: [
      {
        heading: "1# - Janmashtami (Krishna's Birth Anniversary)",
        image: "/images/janam.jpeg",
        description: `

**Janmashtami** is the most important and widely celebrated festival in Mathura, as it marks the birth of Lord Krishna. The entire city comes alive during this festival with grand processions, prayers, and devotional singing.

### Celebration Highlights
- The festival begins at midnight, the exact time of Krishna's birth.
- Temples like **Shri Krishna Janmabhumi** and **Keshav Dev Temple** host special prayers, dahi handi (human pyramid formation), and bhajans.
- Devotees fast throughout the day and celebrate the divine birth of Krishna with joy and devotion.

### Special Rituals
- The night is filled with the chanting of Krishna’s name, dancing, and dramatic reenactments of his childhood deeds (*Krishna Leela*).
- The festival culminates with offerings of butter, sweets, and milk, symbolizing Krishna’s love for these simple delights.

**Janmashtami in Mathura** is not just a religious event but a grand celebration that brings together spiritual fervor, cultural heritage, and community joy.`,
      },
      {
        heading: "2# - Holi (Festival of Colors)",
        image: "/images/holi.jpeg",
        description: `
  **Holi**, known as the festival of colors, is one of the most famous and vibrant festivals in Mathura. The celebrations here hold a special significance, reflecting the divine love story of **Lord Krishna and Radha**. With a unique blend of tradition and enthusiasm, Mathura transforms into a colorful paradise during this festival.
  
  ### Celebration Highlights
  - **Lathmar Holi in Barsana**: One of the most iconic Holi celebrations happens in Barsana, near Mathura. Women playfully beat men with sticks (*lathis*) while men attempt to shield themselves, recreating a playful episode from Krishna's life. This unique tradition attracts visitors from around the world.
  - **Holi in Vrindavan**: The celebrations spill over into Mathura from the nearby town of Vrindavan, where the Banke Bihari Temple hosts grand events. The air is filled with the joyful sounds of music, singing, and chanting.
  - **Colorful Streets**: The streets of Mathura come alive with people throwing vibrant powders (*gulal*), dancing, and drenching each other with colored water, embodying the playful and joyous nature of Krishna.
  
  ### Significance
  Holi in Mathura is deeply rooted in the legends of Krishna. According to folklore, Krishna playfully complained to his mother about the fairness of Radha's complexion compared to his own. To pacify him, his mother suggested smearing colors on Radha’s face, symbolizing unity and love. This story is at the heart of Mathura's Holi celebrations.
  
  ### Unique Experiences
  - **Phoolon Ki Holi**: At select temples, Holi is celebrated with flowers instead of colors, creating a mesmerizing and fragrant spectacle.
  - **Cultural Performances**: Traditional dance and drama, particularly reenactments of Krishna’s life (*Krishna Leela*), add a spiritual and artistic touch to the celebrations.
  - **Special Offerings**: Sweet delicacies like *gujiya*, *thandai*, and other festive treats are prepared and shared among families and communities.
  
  ### Tips for Visitors
  - **Plan Ahead**: Holi in Mathura attracts massive crowds, so it’s advisable to book accommodations in advance.
  - **Dress Comfortably**: Wear clothes you don’t mind getting stained with colors.
  - **Join the Festivities**: Don’t just watch—immerse yourself in the joy and exuberance of Holi in Mathura.
  
  Holi in Mathura is more than just a festival—it’s a vibrant and unforgettable celebration of life, love, and unity, making it a must-visit event for everyone.`,
      },
      {
        heading: "3# - Govardhan Puja",
        image: "/images/goverdhanpooja.jpeg",
        description: `
  **Govardhan Puja** is an important festival in Mathura, celebrated to honor the divine intervention of **Lord Krishna**, who lifted the Govardhan Hill to protect the people of Gokul from the wrath of Lord Indra. This event is an embodiment of Krishna’s devotion to his devotees, showcasing his power and compassion.
  
  ### Celebration Highlights
  - **Offering Food to Krishna**: Devotees prepare and offer traditional food items like **dal, bati, and churma**, which are associated with the story of Krishna lifting the hill. These dishes represent the nourishment and protection Krishna provided to the people of Gokul during the torrential rains.
  - **Govardhan Parikrama**: The day is marked by the sacred act of **Govardhan Parikrama**, where devotees walk around the Govardhan Hill in a ritualistic circle, praying and offering their gratitude for Krishna’s divine protection.
  - **Special Prayers at Temples**: Temples dedicated to Lord Krishna, such as the Shri Krishna Janmabhoomi and the Dwarkadheesh Temple, host special prayers and hymns to commemorate this day. The celebrations are marked by devotional singing, chanting of mantras, and offerings of flowers and sweets.
  
  ### Significance
  The story behind **Govardhan Puja** is rooted in Hindu mythology. According to the legend, Lord Krishna saved the people of Gokul from a catastrophic flood caused by Lord Indra, the god of rain, who was angered when the villagers stopped offering him annual tributes. In response to this, Krishna lifted the Govardhan Hill on his little finger, providing shelter to the people and cattle from the heavy rains. The festival is a celebration of Krishna’s protection and the community's gratitude towards him.
  
  ### Unique Rituals
  - **Creating Govardhan Hill Replicas**: In many places, small replicas of Govardhan Hill are created using **mud or stones**, and devotees offer food and prayers to these hills as a symbol of Krishna's protective power.
  - **Community Feasts**: Families and communities come together to prepare and share special dishes, symbolizing the unity and love among the people of Mathura.
  - **Ritualistic Worship**: The rituals are observed with great devotion, often including the lighting of lamps and the chanting of Krishna's name, invoking his blessings for prosperity, health, and happiness.
  
  ### Tips for Visitors
  - **Participate in the Rituals**: If visiting during Govardhan Puja, join the **Govardhan Parikrama** to experience the spiritual essence of the festival.
  - **Taste Traditional Dishes**: Don’t miss the opportunity to taste the **dal, bati, and churma**, which are an integral part of the festival's food offerings.
  
  Govardhan Puja in Mathura is a time to reflect on the power of faith, the importance of gratitude, and the boundless love that Lord Krishna has for his devotees. This celebration is a truly memorable experience for all who participate.`,
      },
    ],
  },

  "foods-of-mathura": {
    title: "Local Foods of Mathura",
    sections: [
      {
        heading: "Mathura's Peda -",
        image: "/images/peda.jpeg",
        description: `
  **Peda** is Mathura's most iconic sweet and a must-try for anyone visiting this sacred city. It is a popular dessert that holds deep cultural significance and is loved by people of all ages. Made from **condensed milk**, **sugar**, and **ghee**, peda is rich, creamy, and has a dense texture that makes it a delightful treat.
  
  ### Ingredients and Preparation
  The primary ingredients used in making peda include **full-fat milk**, which is reduced to a dense consistency by cooking it with **sugar** and **ghee** (clarified butter). Often, **cardamom** or **saffron** is added to enhance the flavor and give it a distinct aroma. The peda is then garnished with crushed **pistachios** or **almonds** for an extra layer of flavor and a crunchy texture.
  
  ### Popular Variants
  Mathura's peda comes in different varieties, each offering unique flavors:
  - **Mawa Peda**: The most traditional variety, made with **khoya (mawa)**, a milk reduction.
  - **Kesar Peda**: Infused with **saffron** for a rich, golden color and aromatic flavor.
  - **Pistachio Peda**: Garnished generously with **pistachios**, giving it a nutty crunch.
  
  ### Significance
  Mathura's peda is not only a treat for the taste buds but also an important part of the local culture. It is often offered at temples as part of religious rituals and is a favorite during festivals like **Janmashtami**. It symbolizes the abundance and sweetness of Lord Krishna's birthplace.
  
  ### Where to Buy
  Peda can be found in most sweet shops throughout Mathura, especially near the **Shri Krishna Janmabhumi Temple**. Many shops in the area specialize in making fresh peda throughout the day.
  
  Mathura’s peda is a must-try delicacy for those seeking to indulge in the rich culinary tradition of the region.`,
      },
     
    {
      heading: "Mathura's Kachori -",
      image: "/images/kachori.jpeg",
      description: `
**Kachori** is one of the most beloved snacks in Mathura, offering a delightful combination of crispy texture and flavorful filling. This popular dish is made by deep-frying a round dough shell filled with a spiced mixture of **moong dal** (yellow lentils) or **peas**. It is typically enjoyed as a breakfast or an evening snack and is a favorite among locals and tourists alike.

### Ingredients and Preparation
The dough for the kachori is made with **flour** and **ghee**, creating a crispy outer layer when deep-fried. The filling varies, but the most common ones are made with **moong dal** or **spiced peas**, flavored with a blend of **cumin**, **coriander**, **ginger**, and **chili powder**. The spicy and aromatic filling contrasts beautifully with the crispy outer shell.

### Accompaniments
Kachoris are usually served with **tangy tamarind chutney** or **mint chutney**, which adds a refreshing burst of flavor and balances the spice. Some vendors also serve it with **aloo ki sabzi** (spiced potato curry), making it a more filling meal.

### Popularity and Significance
Kachori is a staple street food in Mathura and is often enjoyed by devotees visiting the temples or people seeking a quick snack during their visit to the city. It is especially popular during **festivals** like **Holi** and **Diwali**, when the streets are filled with food stalls offering a variety of snacks.

### Where to Buy
Kachoris can be found in local markets around Mathura, including **Holy Gate Market** and **Chatta Bazaar**, where vendors serve them fresh and hot. Many shops specialize in making kachoris, and they are often paired with a cup of **masala chai** for a perfect snack.

Mathura's kachori is a must-try for anyone visiting this historic city, combining the richness of local flavors with a crispy, satisfying texture.`,
    },
    {
      heading: "Mathura's Mathri -",
      image: "/images/mathri.jpeg",
      description: `
**Mathri** is a popular savory snack in Mathura, known for its crispy texture and delightful flavor. Made from **whole wheat flour**, this deep-fried snack is seasoned with a blend of **carom seeds (ajwain)** and **cumin**, giving it a unique taste. Mathri is commonly served with **pickles** or **yogurt**, making it a perfect accompaniment to meals or enjoyed as a snack on its own.

### Ingredients and Preparation
The dough for Mathri is prepared using **whole wheat flour**, **ghee**, and a variety of spices like **cumin** and **carom seeds (ajwain)**. The dough is rolled out into flat, round discs and deep-fried until golden and crispy. The spices add a distinctive flavor to the dough, while the deep-frying process gives Mathri its crunchy texture.

### Significance and Popularity
Mathri is a traditional snack often enjoyed during festivals such as **Diwali**, **Holi**, and **Govardhan Puja**. Its crispness and savory taste make it a perfect snack for celebrations and gatherings. It’s often served with a side of tangy **pickle** or cooling **yogurt** to complement the spiciness.

### Where to Buy
Mathri can be found in various markets across Mathura, especially in local markets like **Chatta Bazaar** and **Holy Gate Market**. Many vendors sell Mathri freshly prepared, making it an essential treat for visitors and locals alike. The snack can also be found in sweet shops that specialize in traditional Mathura snacks.

Mathri is a must-try for anyone visiting Mathura, offering a taste of local tradition and a satisfying crunch with every bite.`,
    },
  ],
},


  

  // Vrindavan
  "places-to-visit-in-vrindavan": {
    title: "Place to visit in Vrindavan",
    sections: [
      {
        heading:
          "#1 - Maa Vaishno Devi Temple: A Divine Destination in Vrindavan",
        image: "/images/Maa-Vaishno-Devi.jpg",
        description: `
The **Maa Vaishno Devi Temple** in Vrindavan is a prominent spiritual site, inspired by the famous Vaishno Devi Shrine in Katra, Jammu & Kashmir. It offers a unique experience for devotees seeking blessings from Goddess Vaishno Devi.

### Significance
- **Goddess Vaishno Devi**: A manifestation of the Divine Mother, believed to grant health, prosperity, and spiritual growth.
- **Spiritual Symbolism**: Represents the combined power of the goddesses Maha Kali, Maha Lakshmi, and Maha Saraswati.

### Temple Features
- **Holy Cave**: Resembling the sacred cave in Katra, devotees enter through a narrow cave-like entrance.
- **Idols**: Features the pind (stone idol) of Maa Vaishno Devi along with idols of Lord Ram, Lakshman, and Lord Hanuman.

### Visiting Timings
- **Morning**: 5:00 AM to 12:00 PM (Mangala Aarti from 5:00 AM).
- **Evening**: 4:00 PM to 9:00 PM (Sandhya Aarti at 7:00 PM).

### Festivals
- **Navratri**: The temple is especially vibrant during Navratri, with special prayers and rituals.
- **Maha Shivaratri**: Celebrated as Maa Vaishno Devi is associated with Lord Shiva.

### Location and Accessibility
- **Location**: Situated in Vrindavan, Mathura, Uttar Pradesh.
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, Radha Rani Temple.
- **By Air**: Nearest airport - Agra (60 km); Delhi (180 km).
- **By Train/Road**: Well-connected to Delhi, Agra, and Mathura.

A visit to the Maa Vaishno Devi Temple in Vrindavan provides a peaceful atmosphere for devotees seeking spiritual fulfillment. The temple’s rich architecture and vibrant festivals make it a must-visit destination.
  `,
      },
      {
        heading: "#2 - Prem Mandir: Temple of Divine Love",
        image: "/images/prem-mandir.jpg", // Replace with actual image URL
        description: `
**Prem Mandir** (Temple of Divine Love) is a grand and awe-inspiring temple located in Vrindavan, Uttar Pradesh, dedicated to the divine love of Radha and Krishna. It is one of the most iconic and visited temples in Vrindavan, attracting millions of devotees and tourists due to its stunning architecture, spiritual significance, and peaceful atmosphere.

### Significance
- **Divine Love**: The temple symbolizes the eternal love between Lord Krishna and Radha, highlighting devotion and love.
- **Architecture**: Built with Italian white marble and intricate carvings, the temple’s beauty is enhanced by spectacular lighting, especially at night.

### Spiritual Experience
- **Divine Connection**: A place to meditate, pray, and experience spiritual bliss through devotion.
- **Main Deities**: Radha and Krishna, along with Lord Chaitanya Mahaprabhu and Lord Nityananda.

### Timings
- **Morning**: 5:30 AM to 1:00 PM (with Mangala Aarti at 5:30 AM).
- **Evening**: 4:30 PM to 9:00 PM (Sandhya Aarti at 7:00 PM).

### Festivals & Rituals
- Special celebrations during Janmashtami, Radhashtami, and Diwali, with aarti and cultural programs.

### Accessibility
- **Location**: In Vrindavan, near other temples like Banke Bihari and Radha Raman.
- **Nearby**: Agra (60 km), Delhi (180 km).
- **By Train/Bus**: Accessible from Mathura Junction (15 km away).

Prem Mandir offers a divine experience of spiritual connection with Radha and Krishna, making it a must-visit destination in Vrindavan.
  `,
      },
      {
        heading: "#3 - Priyakant Ju Mandir: Temple of Divine Love",
        image: "/images/Priyakantju.jpg", // Replace with actual image URL
        description: `
**Priyakant Ju Mandir** is a significant temple dedicated to Lord Krishna and Radha. It is located in Mathura, the birthplace of Lord Krishna, and is especially revered by the devotees of Radha-Krishna. The temple is located in the area of Goverdhan, which is a part of Mathura's religious landscape. Priyakant Ju Mandir is known for its serene environment and its association with the devotional culture surrounding the divine couple.

### Significance
- **Devotional Focus**: The temple symbolizes the divine love and devotion between Radha and Krishna, with Krishna as "Priyakant" (the beloved consort of Radha).
- **Historical Importance**: Though not as grand as other temples, it is a revered site for those dedicated to Radha-Krishna worship.

### Key Features
- **Deities**: The main idol is of Priyakant (Lord Krishna), alongside idols of Radha.
- **Architecture**: Built in traditional North Indian style with intricate carvings, creating a peaceful and serene environment.
- **Spiritual Atmosphere**: Ideal for meditation, chanting hymns, and connecting with the divine love of Radha and Krishna.

### Timings
- **Morning**: 5:00 AM to 12:00 PM (Mangala Aarti at 5:00 AM).
- **Evening**: 4:00 PM to 9:00 PM (Sandhya Aarti at 7:00 PM).

### Special Prayers & Aartis
- **Mangala Aarti**: Performed at sunrise, marking the start of the day.
- **Sandhya Aarti**: Evening prayers with devotional songs and temple lighting.

### Nearby Attractions
- **Mathura**: Shri Krishna Janmabhoomi, Vishram Ghat, Dwarkadhish Temple, and Goverdhan Hill.
- **Vrindavan**: Nearby temples like Banke Bihari and Prem Mandir.

Priyakant Ju Mandir offers a tranquil space for devotion and reflection, making it a must-visit for those seeking a deeper connection with Radha and Krishna.
  `,
      },
      {
        heading: "#4 - Chandrodaya Temple: A Spiritual Sanctuary",
        image: "/images/chandrodya.jpg", // Replace with actual image URL
        description: `
**Chandrodaya Temple** is one of the most remarkable and grand temples in Vrindavan, located in the holy town of Vrindavan in Uttar Pradesh. It is dedicated to Lord Krishna and is known for its massive size, modern architecture, and spiritual significance. The temple is also associated with the International Society for Krishna Consciousness (ISKCON), an organization that promotes the teachings and devotion to Lord Krishna.


### Significance
- **Inspiration from Krishna's Life**: The temple showcases Lord Krishna's love, devotion, and teachings, reflecting his divine connection with devotees.
- **Global Peace Vision**: Aimed at spreading the teachings of the Bhagavad Gita, the temple promotes peace, love, and devotion.
- **Architectural Marvel**: One of India's tallest temples, with intricate carvings, large domes, and surrounding gardens.

### Key Features
1. **Main Deity**: Lord Krishna, alongside Radha, symbolizing their eternal divine love.
2. **The Towering Structure**: A majestic building with a central dome and towering spires.
3. **Spiritual Environment**: Spacious halls for prayer, meditation, and spiritual activities.
4. **Krishna Museum**: Exhibiting the life and teachings of Lord Krishna.
5. **Cultural Programs**: Regular kirtans, bhajans, and spiritual discourses.

### Timings
- **Morning**: 5:00 AM to 12:00 PM (Mangala Aarti at 5:00 AM).
- **Evening**: 4:00 PM to 9:00 PM (Sandhya Aarti at 7:00 PM).

### Special Aartis and Rituals
- **Mangala Aarti**: Morning prayer invoking blessings.
- **Sandhya Aarti**: Evening prayer with chanting and hymns.
- **Kirtans & Bhajans**: Regular devotional singing at the temple.



### Location & Accessibility
- **Location**: Vrindavan, Mathura District, Uttar Pradesh.
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, Radha Rani Temple.
- **By Air**: Agra Airport (60 km) or Delhi Airport (180 km).
- **By Train**: Vrindavan Railway Station or Mathura Junction (15 km).
- **By Road**: Easily accessible from Delhi, Mathura, and Agra.

Chandrodaya Temple is a spiritual sanctuary, offering a serene environment for prayer and devotion. With its stunning architecture, daily aartis, and educational programs, it is a must-visit destination in Vrindavan.
  `,
      },
      {
        heading: "#5 - ISKCON Temple: A Hub of Krishna Consciousness",
        image: "/images/isckon.jpg", // Replace with actual image URL
        description: `
The **ISKCON Temple** in Vrindavan, also known as the Sri Sri Krishna Balaram Mandir, is one of the most significant temples in the town of Vrindavan, Uttar Pradesh. It is part of the global International Society for Krishna Consciousness (ISKCON) movement, founded by A.C. Bhaktivedanta Swami Prabhupada. The temple is dedicated to Lord Krishna and Lord Balaram, the elder brother of Krishna, and it serves as a spiritual hub for followers of the Gaudiya Vaishnavism tradition.

### Significance:
- **Dedicated to Krishna & Balaram**: The main deities are Krishna and his elder brother Balaram, worshipped by devotees in a serene environment.
- **Srila Prabhupada’s Legacy**: A key project of Srila Prabhupada, it spreads Bhakti Yoga and Krishna Consciousness.
- **Cultural & Educational Hub**: The temple hosts kirtans, Bhagavad Gita classes, and spiritual workshops.

### Key Features:
1. **Deities**: Krishna and Balaram, central to the temple’s spiritual atmosphere.
2. **Architecture**: Traditional North Indian style with modern elements, spacious sanctum, and beautiful gardens.
3. **Cultural Programs**: Kirtans, bhajans, and lectures on sacred texts like the Bhagavad Gita.
4. **ISKCON Education**: Classes on Bhakti Yoga and Vedic literature for spiritual growth.
5. **Prasadam**: Delicious vegetarian meals offered as blessings to devotees.
6. **Temple Facilities**: Guest houses, library, and halls for spiritual gatherings.

### Timings:
- **Morning**: 4:30 AM to 12:00 PM (Mangala Aarti at 4:30 AM).
- **Evening**: 4:30 PM to 9:00 PM (Sandhya Aarti at 6:00 PM).

### Special Programs:
- **Kirtans & Bhajans**: Devotional chanting sessions.
- **Bhagavad Gita Classes**: Spiritual learning for all ages.
- **Festivals**: Grand celebrations for Janmashtami, Radhashtami, and more.



### Location & Accessibility:
- **Location**: Vrindavan, Uttar Pradesh (15 km from Mathura).
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, Vishram Ghat,Shri Krishna Janmabhoomi.
- **By Air**: Agra Airport (60 km) or Delhi Airport (180 km).
- **By Train**: Vrindavan station or Mathura Junction (15 km).
- **By Road**: Easily accessible from Delhi (180 km), Agra (60 km), and Mathura (15 km).

ISKCON Vrindavan is not just a place of worship, but a center for spiritual learning, cultural exchange, and devotion. It offers a peaceful retreat with its vibrant aartis, educational programs, and deep connection to Lord Krishna's teachings.
  `,
      },
      {
        heading:
          "#6 - Bankey Bihari Temple: A Spiritual Hub of Krishna Devotion",
        image: "/images/Bankey-Bihari.jpg", // Replace with actual image URL
        description: `
The **Bankey Bihari Temple** is one of the most famous and revered temples in Vrindavan, Uttar Pradesh. It is dedicated to Lord Krishna, in his form as Bankey Bihari, a deity worshipped primarily for his playful and charming nature. This temple holds immense significance among Krishna devotees, especially within the tradition of Gaudiya Vaishnavism. It is a place of deep spiritual connection, where devotees gather to offer their prayers, participate in devotional singing, and celebrate the divine pastimes of Lord Krishna.

### Significance:
- **Dedicated to Bankey Bihari**: The temple’s main deity is Lord Krishna in the Bankey Bihari form, a playful and charming representation.
- **Swami Haridas’ Legacy**: Established in the 16th century by Swami Haridas, a prominent devotee and musicologist.
- **Cultural & Spiritual Hub**: The temple is a center for Krishna Bhakti, with kirtans, bhajans, and spiritual discourses.

### Key Features:
1. **Deity of Bankey Bihari**: The main idol of Krishna in a bent pose, exuding divine charm.
2. **Traditional Architecture**: Built in a traditional North Indian style with intricate carvings.
3. **Vibrant Rituals**: Aartis like Mangala Aarti and Sandhya Aarti held daily with devotion.
4. **Spiritual Atmosphere**: A place where devotees engage in prayer, singing, and chanting.

### Timings:
- **Morning**: 7:00 AM to 12:00 PM (Morning Aarti at 7:00 AM).
- **Evening**: 4:00 PM to 9:00 PM (Evening Aarti at 6:00 PM).

### Special Programs:
- **Kirtans & Bhajans**: Chanting and devotional singing sessions.
- **Festivals**: Special celebrations for Janmashtami, Radhashtami, Holi, and Diwali.

### Location & Accessibility:
- **Location**: In the heart of Vrindavan, near the Yamuna River.
- **Nearby Attractions**: ISKCON Temple, Prem Mandir, Vishram Ghat, Shri Krishna Janmabhoomi.
- **By Air**: Agra Airport (60 km) or Delhi Airport (180 km).
- **By Train**: Vrindavan station or Mathura Junction (15 km).
- **By Road**: Easily accessible from Delhi (180 km), Agra (60 km), and Mathura (15 km).

The Bankey Bihari Temple is a center of devotion, cultural activity, and spiritual connection with Lord Krishna. It offers a peaceful environment for worship and a deep, immersive spiritual experience.
  `,
      },
      {
        heading: "#7 - Temple of Glass: A Modern Spiritual Experience",
        image: "/images/temple-of-Glasses.jpg", // Replace with actual image URL
        description: `
The **Temple of Glass**, also known as the Shree Glass Temple or Glass Mandir, is a relatively modern and unique temple located in Vrindavan, Uttar Pradesh. The temple is famous for its distinctive architecture, which is primarily constructed using glass. This temple is a prominent attraction for both devotees and tourists visiting Vrindavan, as it is one of the few temples in India that features glass as its main construction material, creating a spectacular visual experienceThe Garun Govind Temple is a prominent religious site in Vrindavan, Uttar Pradesh, dedicated to Lord Krishna and his revered vehicle Garuda, the mighty eagle who is considered the divine carrier of Lord Vishnu. This temple is significant not only for its devotion to Lord Krishna but also for its association with Garuda, who plays an important role in Hindu mythology.


### Key Features:
1. **Unique Architecture**: Glass panels cover the walls, ceiling, and altar, creating a radiant and colorful atmosphere.
2. **Lord Krishna's Depiction**: The temple's central deity is an idol of Krishna in his youthful form, surrounded by intricate glass artwork depicting his life.
3. **Glasswork & Aesthetics**: The temple features vibrant glasswork that reflects sunlight during the day and lights at night, creating a mesmerizing effect.
4. **Modern Spirituality**: A fusion of contemporary architecture and traditional devotion, offering a new-age spiritual experience.

### Timings:
- **Morning Darshan**: 6:00 AM to 12:00 PM
- **Evening Darshan**: 4:00 PM to 9:00 PM

### Special Events:
- **Janmashtami & Radhashtami**: Grand celebrations with kirtans and prayers.
- **Holi**: Special decorations and prayers during the festival of colors.



### Location & Accessibility:
- **Location**: Vrindavan, Uttar Pradesh
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir,ISKCON Vrindavan and Radha Kund are nearby and offer further insights into Krishna’s life and teachings.
- **By Air**: Agra (60 km) or Delhi (180 km)
- **By Train**: Vrindavan station or Mathura Junction (15 km)
- **By Road**: Easily accessible from Delhi, Agra, and Mathura.

The Temple of Glass is a must-visit for its unique architecture, spiritual significance, and the serene atmosphere that enhances devotion to Lord Krishna.
  `,
      },
      {
        heading: "#8 - Radha Vallabha Temple: A Divine Center of Bhakti",
        image: "/images/Radha vallabh.jpg", // Replace with actual image URL
        description: `
The **Radha Vallabha Temple** is one of the oldest and most important temples in Vrindavan, Uttar Pradesh, dedicated to Lord Krishna and Radha, his divine consort. This temple is highly revered by devotees of Gaudiya Vaishnavism and holds a significant place in the spiritual landscape of Vrindavan. 
### Key Features:
1. **Deity of Radha Vallabha**: The temple features a unique form of Krishna worship, where Radha is symbolically present through a veil over Krishna's idol, reflecting their spiritual unity.
2. **Historical Background**: Established in the 16th century by Raghunath Das Goswami, the temple is a key site for devotional singing, kirtans, and bhakti practices.
3. **Traditional Architecture**: The temple follows the North Indian style, with simple yet elegant designs enhancing the spiritual experience.
4. **Distinct Rituals**: Worship includes daily aartis, bhajans, and chanting the Hare Krishna Maha Mantra. Radha's presence is symbolized during rituals.

### Timings:
- **Morning Darshan**: 6:00 AM to 12:00 PM
- **Evening Darshan**: 4:00 PM to 9:00 PM

### Special Aartis and Rituals:
- **Mangala Aarti** (Morning)
- **Sandhya Aarti** (Evening)
- **Shayan Aarti** (Night)

### Festivals:
- **Janmashtami & Radhashtami**: Celebrations with prayers, aartis, and kirtans.
- **Holi & Diwali**: Special prayers and decorations for these festivals.

### Location & Accessibility:
- **Location**: Central Vrindavan, near the Yamuna River
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir,ISKCON Vrindavan, Vishram Ghat and Radha Kund are some key sites near the temple.
- **By Air**: Agra (60 km) or Delhi (180 km)
- **By Train**: Vrindavan station or Mathura Junction (15 km)
- **By Road**: Easily accessible from Delhi, Agra, and Mathura.

The Radha Vallabha Temple offers a spiritual journey through its unique traditions, rituals, and serene atmosphere. A visit here is a profound experience of devotion to Radha and Krishna.
  `,
      },
      {
        heading: "#9 - Radha Damodar Temple: A Hub of Devotion and History",
        image: "/images/radha-damodar.jpg", // Replace with actual image URL
        description: `
The **Radha Damodar Temple** is one of the most revered temples in Vrindavan, Uttar Pradesh, and holds a significant place in the devotional landscape of Gaudiya Vaishnavism. It is dedicated to Lord Krishna and Radha, and the temple has a deep historical and spiritual connection with the early days of ISKCON (International Society for Krishna Consciousness).
### Key Features:
1. **Deity of Radha Damodar**: The temple enshrines Radha Damodar, symbolizing the divine union of Lord Krishna and Radha. The idol is adorned beautifully, embodying their eternal love.
2. **Historical Significance**: Established in the 16th century by Jiva Goswami, the temple holds immense importance in the development of the Gaudiya Vaishnavism tradition. It has strong ties to ISKCON's early days and Srila Prabhupada's time in Vrindavan.
3. **Sacred Relics**: The temple preserves sacred manuscripts and artifacts linked to the Goswamis, including items related to Chaitanya Mahaprabhu and Rupa Goswami, further enhancing its historical value.
4. **Devotional Practices**: The temple is renowned for its peaceful atmosphere, where devotees participate in daily aartis, kirtans, and chanting the Hare Krishna mantra.

### Timings:
- **Morning Darshan**: 5:30 AM to 12:00 PM
- **Evening Darshan**: 4:00 PM to 9:00 PM

### Special Aartis and Rituals:
- **Mangala Aarti** (Morning)
- **Sandhya Aarti** (Evening)
- **Shayan Aarti** (Night)

### Festivals:
- **Janmashtami & Radhashtami**: The temple celebrates these festivals with vibrant prayers, processions, and kirtans.
- **Holi & Diwali**: Celebrations with special prayers and colorful decorations.
- **Vasant Panchami**: Marking the arrival of spring with devotion to Radha and Krishna.

### Location & Accessibility:
- **Location**: Central Vrindavan, near the Yamuna River
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, ISKCON Vrindavan, Vishram Ghat, and Shri Krishna Janmabhoomi are some key sites near the temple.
- **By Air**: Agra (60 km) or Delhi (180 km)
- **By Train**: Vrindavan station or Mathura Junction (15 km)
- **By Road**: Easily accessible from Delhi, Agra, and Mathura.

The Radha Damodar Temple is a profound place of worship that connects the past to the present, offering a rich spiritual experience for devotees.
  `,
      },
      {
        heading: "#10 - Kali Deh Temple: A Sacred Haven of Kali Ma Worship",
        image: "/images/Kale-deh.jpg", // Replace with actual image URL
        description: `
The **Kali Deh Temple** in Vrindavan is a serene and sacred place dedicated to Goddess Kali. Known for its peaceful atmosphere and spiritual significance, the temple attracts devotees seeking strength, protection, and divine blessings.

### Key Features:
1. **Deity of Kali Ma**: Dedicated to the fierce goddess, offering blessings for courage, protection, and transformation.
2. **Historical and Spiritual Significance**: A serene and lesser-known retreat for Kali Ma devotees, perfect for meditation and prayer.
3. **Devotional Practices**: Daily aartis and rituals enhance the spiritual atmosphere, with special prayers during festivals.
4. **Unique Festivals**: Grand celebrations for Navaratri, Kali Puja, and Shyama Puja, drawing many devotees.

### Timings:
- **Morning Darshan**: 6:00 AM to 12:00 PM
- **Evening Darshan**: 4:00 PM to 9:00 PM

### Special Aartis and Rituals:
- **Mangala Aarti** (Morning): Held at 6:00 AM, marking the start of daily rituals.
- **Sandhya Aarti** (Evening): Conducted at 6:00 PM, this is the most vibrant prayer of the day, featuring kirtans and chanting.
- **Shayan Aarti** (Night): Performed at 8:30 PM, this marks the end of the temple’s daily prayers.

### Festivals:
- **Navaratri**: Celebrated with special prayers, fasting, and rituals dedicated to Kali Ma over nine nights.
- **Kali Puja**: Held during Diwali, this festival sees large gatherings of devotees worshipping Kali Ma for blessings and protection.
- **Shyama Puja**: A grand celebration of Goddess Kali, accompanied by music, rituals, and devotional offerings.

### Location & Accessibility:
- **Location**: Located in a peaceful part of Vrindavan, away from the hustle of the main Krishna temples, offering a tranquil environment for devotees.
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, ISKCON Vrindavan, Radha Rani Temple, Vishram Ghat, and Shri Krishna Janmabhoomi are key sites nearby.
- **By Air**: Agra Airport (60 km) and Indira Gandhi International Airport, Delhi (180 km).
- **By Train**: Vrindavan station or Mathura Junction (15 km).
- **By Road**: Easily accessible from Delhi (180 km), Mathura (15 km), and Agra (60 km).

The Kali Deh Temple provides a unique spiritual retreat, blending devotion and tranquility. A visit to this temple offers a profound connection to the divine feminine energy, making it a cherished destination for Kali Ma worshippers.
  `,
      },

      {
        heading:
          "#11 - Garun Govind Temple: A Symbol of Devotion and Protection",
        image: "/images/Garun-Govind.jpg", // Replace with actual image URL
        description: `
The **Garun Govind Temple** is a prominent religious site in Vrindavan, Uttar Pradesh, dedicated to Lord Krishna and his revered vehicle Garuda, the mighty eagle who is considered the divine carrier of Lord Vishnu. This temple is significant not only for its devotion to Lord Krishna but also for its association with Garuda, who plays an important role in Hindu mythology.
### Key Highlights:
- **Unique Deity**: Combined idol of Krishna and Garuda, symbolizing devotion and power.  
- **Mythological Connection**: Celebrates Garuda’s bravery and his role in Hindu mythology.  
- **Spiritual Haven**: A peaceful space ideal for prayers and meditation.
- **Connection to Vrindavan**: Nestled in the spiritual heart of Vrindavan, the temple highlights the interconnectedness of deities in Vaishnavism, where devotion to Krishna and his divine companions coexist seamlessly.  

### Special Aartis and Rituals:
- **Mangala Aarti** (Morning): Held at 5:30 AM, it marks the start of the day with prayers and offerings.
- **Sandhya Aarti** (Evening): Conducted at 6:00 PM, the temple resonates with bhajans and kirtans as devotees gather for evening prayers.
- **Shayan Aarti** (Night): Performed around 8:30 PM, this is the final prayer of the day, invoking peace and blessings.

### Timings:
- **Morning Darshan**: 5:30 AM - 12:00 PM  
- **Evening Darshan**: 4:00 PM - 9:00 PM  

### Festivals:  
- **Janmashtami**: Celebrates Krishna’s birth.  
- **Navaratri & Kartik Month**: Dedicated prayers and kirtans.  

### Accessibility:
- **Location**: Quiet spot in Vrindavan, near major temples like Banke Bihari and Prem Mandir.  
- **Travel**: Accessible via Agra (60 km), Delhi (180 km), or Mathura (15 km).  

The **Garun Govind Temple** is a serene retreat for devotees, combining mythology with spirituality in the heart of Vrindavan.  
  
  `,
      },
      {
        heading:
          "#12 - Rangji Mandir: A Blend of South and North Indian Architecture",
        image: "/images/Ranji-temple.jpg", // Replace with actual image URL
        description: `
**Rangji Mandir** is one of the most prominent and unique temples in Vrindavan, dedicated to Lord Ranganatha, a form of Lord Vishnu. The te
mple holds significant spiritual and cultural value for devotees of Vaishnavism and is a beautiful example of the fusion of South Indian and North Indian temple architecture. It is famous for its distinct architectural style and the worship of Lord Vishnu in his Ranganatha form.

### **Key Features**:
1. **Deity of Lord Ranganatha**: The temple enshrines Lord Vishnu in a reclining posture on the serpent **Shesha**, symbolizing peace and cosmic balance.
2. **Architectural Fusion**: A blend of **Dravidian** (South Indian) and **Mughal** (North Indian) styles, with a grand **Raj Gopuram** (tower) and intricate carvings.
3. **Vaishnavism Tradition**: Following the **Sri Vaishnavism** teachings of **Ramanujacharya**, it is a key spiritual center for Lord Vishnu's devotees.

### **Timings**:
- **Morning Darshan**: 5:30 AM to 12:00 PM  
- **Evening Darshan**: 4:00 PM to 9:00 PM  

### **Special Aartis and Rituals**:
- **Mangala Aarti** (Morning): Conducted at 5:30 AM to welcome the deity.  
- **Sandhya Aarti** (Evening): Held at 6:00 PM with bhajans and kirtans.  
- **Shayan Aarti** (Night): Performed at 8:30 PM to mark the day’s end.  

### **Festivals**:
- **Vaikuntha Ekadashi**: Special prayers to Lord Vishnu with fasting and kirtans.  
- **Janmashtami**: Celebrations of Lord Krishna’s birth with processions and devotional songs.  
- **Rama Navami**: Honoring Lord Rama with special rituals and prayers.  
- **Kartik Purnima**: A month-long celebration with increased devotional activities.  

### **Location & Accessibility**:
- **Location**: Situated in Vrindavan, near other major temples like **Banke Bihari Temple** and **Prem Mandir**.  
- **By Air**: Agra Airport (60 km) and Indira Gandhi International Airport, Delhi (180 km).  
- **By Train**: Vrindavan Railway Station or Mathura Junction (15 km).  
- **By Road**: Connected to Delhi (180 km), Mathura (15 km), and Agra (60 km).  

The Rangji Mandir is a perfect example of architectural brilliance and deep devotion, offering visitors a serene environment for worship and meditation. It stands as a cherished destination for those seeking a unique spiritual experience.
  `,
      },
      {
        heading: "#13 - Nidhivan: A Sacred and Mystical Grove of Divine Leelas",
        image: "/images/Nidhivan.jpg", // Replace with actual image URL
        description: `
**Nidhivan** is one of the most sacred and mystical places in Vrindavan, located near the Banke Bihari Temple. It is believed to be the spot where Lord Krishna performed his divine leelas (pastimes) with Radha and the gopis. The place is associated with divine presence, and many devotees and locals regard it as a mystical and spiritually charged location. It is one of the most visited sites in Vrindavan, drawing pilgrims and devotees from all over the world.


### **Key Features**:
1. **Divine Leelas**:  According to legend, Krishna's Raas Leelas still continue here every night. The grove is said to come alive with the sounds of divine music and dance, hidden from mortal eyes.
2. **Sacred Tulsi Plants**: Unique tulsi trees in Nidhivan are believed to transform into gopis at night, symbolizing their eternal devotion to Lord Krishna.
3. **Mystical Atmosphere**: Visitors often report feeling an inexplicable spiritual energy. Entry is strictly prohibited after sunset, as it is believed Krishna and Radha's divine activities occur during this time.
4. **Radha-Krishna Temple**: A small temple inside Nidhivan serves as a focal point of devotion, with morning aartis and offerings by devotees.

### **Timings**:
- **Morning**: 5:00 AM - 12:00 PM  
- **Evening**: 4:00 PM - 6:30 PM  
- **Night Restrictions**: No entry allowed after sunset due to beliefs about ongoing divine leelas.

### **Special Customs**:
- **Silent Reverence**: Devotees are encouraged to maintain silence and meditate during their visit.  
- **Offerings and Prayers**: Flowers, fruits, and incense are commonly offered to the deity.  
- **Legends**: Local stories narrate mystical occurrences for those attempting to witness the nighttime leelas.



### **Location & Accessibility**:
- **Location**: Nidhivan is centrally located in Vrindavan, close to the Banke Bihari Temple.  
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, ISKCON Vrindavan and Vishram Ghat.
- **By Air**: Agra Airport (60 km) or Delhi Airport (180 km).  
- **By Train**: Vrindavan Railway Station or Mathura Junction (15 km).  
- **By Road**: Well-connected to Delhi (180 km), Mathura (15 km), and Agra (60 km).

Nidhivan offers a unique experience steeped in mystery, devotion, and spiritual significance. It stands as a testament to Krishna's eternal presence and the unyielding devotion of his followers.
  `,
      },
      {
        heading: "#14 - Madan Mohan Temple: A Sacred Pilgrimage of Devotion",
        image: "/images/madan-mohan.jpg", // Replace with actual image URL
        description: `
The **Madan Mohan Temple** is one of the oldest and most revered temples in Vrindavan, dedicated to Lord Krishna in his form as Madan Mohan. This ancient temple holds significant spiritual and historical importance, attracting thousands of devotees and pilgrims from all around the world, especially those with a deep connection to the teachings of Gaudiya Vaishnavism.


### Key Features:
1. **Historical Importance**: Built by Gopala Bhatta Goswami, the temple is one of the first constructed in Vrindavan.
2. **Deity of Madan Mohan**: The idol of Krishna holding a flute, symbolizing divine love and charm.
3. **Spiritual Atmosphere**: Filled with kirtans and incense, offering a serene space for meditation.

### Rituals & Festivals:
- **Aartis**: **Mangala Aarti** (morning), **Sandhya Aarti** (evening), and **Shayan Aarti** (night).
- **Festivals**: Celebrates **Janmashtami**, **Radhashtami**, **Holi**, and **Diwali** with special prayers and vibrant festivities.
- **Kirtans & Bhajans**: Regular devotional songs that energize the temple atmosphere.

### Timings:
- **Morning Darshan**: 5:30 AM - 12:00 PM
- **Evening Darshan**: 4:00 PM - 9:00 PM

### Location & Accessibility:
- **Location**: Near the Yamuna River in Vrindavan.
- **Nearby Attractions**: Banke Bihari Temple, Prem Mandir, ISKCON, Vishram Ghat.
- **By Air**: Agra (60 km), Delhi (180 km).
- **By Train**: Vrindavan Station or Mathura Junction (15 km).
- **By Road**: Easily accessible from Delhi, Mathura, and Agra.

The **Madan Mohan Temple** offers a divine, peaceful space for devotion and spiritual growth, making it a must-visit for pilgrims and spiritual seekers.
  `,
      },
      {
        heading: "#15 - Pagal Baba Temple: A Tranquil Retreat of Devotion",
        image: "/images/pagal-baba.jpg", // Replace with actual image URL
        description: `
**Pagal Baba Temple**, located in Vrindavan, is a popular and revered temple dedicated to Pagal Baba, a spiritual figure who is known for his unique teachings and devotion to Lord Krishna. Vrindavan itself is a major religious town, famous for being the place where Lord Krishna spent his childhood and youth, and is home to many temples and spiritual sites. The Pagal Baba Temple in Vrindavan is a notable spot among the many attractions in this holy city.

### Key Features:
1. **Pagal Baba's Devotion**: Known for his unique spiritual practices, Pagal Baba's life was a symbol of love and devotion to Krishna.
2. **Serene Atmosphere**: The temple is a peaceful space for prayer, meditation, and reflection, with lush greenery and a calm ambiance.
3. **Spiritual Significance**: Devotees believe Pagal Baba’s connection with the divine brought many miracles, making it a place of deep reverence.

### Timings:
- **Morning**: 5:00 AM - 9:00 PM
- **Best Time to Visit**: Early morning or evening for a tranquil experience.

### Best Time to Visit:
- **Early Morning**: For a quiet and peaceful experience perfect for reflection and prayer.  
- **Evening**: To witness the illuminated temple and soak in its calming atmosphere.  
- **Festivals**: While the temple may be crowded, visiting during festivals like **Janmashtami** or **Holi** offers a vibrant devotional experience with kirtans and special prayers.


### How to Reach:
- **By Road**: Accessible from **Mathura (15 km)** and **Agra (60 km)**.
- **By Train**: Nearest station is **Vrindavan Railway Station**.
- **By Air**: **Indira Gandhi International Airport**, Delhi (160 km).

### Visitor Tips:
- **Dress Modestly**: Conservative clothing is recommended.
- **Respect the Quiet**: Maintain a peaceful atmosphere for prayer and reflection.
- **Nearby Temples**: Explore **Banke Bihari Temple**, **ISKCON Vrindavan**, and others in Vrindavan.

The **Pagal Baba Temple** is a place for spiritual solace and connection with the divine, offering a unique, peaceful retreat for all visitors.
  `,
      },
    ],
  },

  "how-to-reach-vrindavan": {
  title: "How to Reach Vrindavan",
  sections: [
    {
      heading: "By Air",
      image: "/images/plane.jpg",
      description: `
The nearest airport to Vrindavan is **Agra Airport**, located approximately **60 km** away. However, the main airport is in **Delhi**.

### Key Airports:
- **Agra Airport**: Limited flights but convenient for reaching Vrindavan. From here, you can take a taxi or hire private transport.
- **Indira Gandhi International Airport (Delhi)**: The primary international gateway. From Delhi, it's around **3-4 hours** by road to reach Vrindavan.

### Significance:
Air travel is ideal for those coming from far distances, especially international travelers, though you will need to use additional transport to reach Vrindavan.`,
    },
    {
      heading: "By Train",
      image: "/images/train.jpg",
      description: `
Vrindavan is easily accessible by train, with the nearest railway station being **Mathura Junction**, located approximately **15 km** away.

### Key Details:
- **Mathura Junction** is well connected to major cities such as **Delhi**, **Agra**, **Mumbai**, and **Kolkata**.
- From **Mathura Junction**, you can take a **local taxi**, **auto-rickshaw**, or **bus** to reach **Vrindavan**. The journey is around **30 minutes** by road.
- Some trains also connect directly to **Vrindavan Railway Station**, although they are less frequent. 

### Significance:
Train travel is one of the most common and affordable ways to reach Vrindavan, offering a comfortable journey from nearby cities.

### Where to Buy Tickets:
Tickets can be purchased at the **Mathura Junction Railway Station** or booked online through Indian Railway websites or apps.`,
    },
    {
      heading: "By Road",
      image: "/images/road.jpg",
      description: `
Vrindavan is well-connected by road to nearby cities like **Delhi**, **Agra**, and **Mathura**.

### Key Routes:
- **From Delhi**: It’s about **150 km** (3-4 hours) via **Yamuna Expressway** or **National Highway 44 (NH44)**. You can hire a **taxi**, use a **bus**, or **self-drive**.
- **From Agra**: The distance is about **70 km** (1.5-2 hours) via **NH44**.
- **From Mathura**: It’s approximately **15 km** (30-40 minutes) by road.

### Significance:
Road travel provides flexibility and comfort, especially if you're traveling with a group or family. You can also enjoy the scenic route along the **Yamuna Expressway**.

### Bus Services:
Both government and private buses regularly operate from **Mathura**, **Delhi**, and **Agra** to Vrindavan.`,
    },
    
    {
      heading: "By Private Taxi or Car",
      image: "/images/cab.jpg",
      description: `
For those seeking a more comfortable and private option, hiring a **private taxi** or **self-driving** is a great choice.

### Key Benefits:
- **Flexibility**: You can stop and explore places on the way, especially if traveling in a group or family.
- **Comfort**: Private taxis offer a more personalized and relaxed journey.
- **Convenience**: A direct journey from your starting location to Vrindavan.

### Significance:
This is the most convenient way for a personalized experience, especially for those looking to explore the surroundings while traveling.

### How to Hire:
Taxis and car rentals can be booked through online platforms or local agencies in nearby cities like **Delhi** and **Agra**.`
},

  ],
},

 "festivals-in-vrindavan": {
    title: "Festivals in Vrindavan",
    sections: [
      {
        heading: "1# - Janmashtami (Krishna's Birth Anniversary)",
        image: "/images/janam.jpeg",
        description: `

**Janmashtami** is the most important and widely celebrated festival in Mathura, as it marks the birth of Lord Krishna. The entire city comes alive during this festival with grand processions, prayers, and devotional singing.

### Celebration Highlights
- The festival begins at midnight, the exact time of Krishna's birth.
- Temples like **Shri Krishna Janmabhumi** and **Keshav Dev Temple** host special prayers, dahi handi (human pyramid formation), and bhajans.
- Devotees fast throughout the day and celebrate the divine birth of Krishna with joy and devotion.

### Special Rituals
- The night is filled with the chanting of Krishna’s name, dancing, and dramatic reenactments of his childhood deeds (*Krishna Leela*).
- The festival culminates with offerings of butter, sweets, and milk, symbolizing Krishna’s love for these simple delights.

**Janmashtami in Mathura** is not just a religious event but a grand celebration that brings together spiritual fervor, cultural heritage, and community joy.`,
      },
      {
        heading: "2# - Holi (Festival of Colors)",
        image: "/images/holi.jpeg",
        description: `
  **Holi**, known as the festival of colors, is one of the most famous and vibrant festivals in Mathura. The celebrations here hold a special significance, reflecting the divine love story of **Lord Krishna and Radha**. With a unique blend of tradition and enthusiasm, Mathura transforms into a colorful paradise during this festival.
  
  ### Celebration Highlights
  - **Lathmar Holi in Barsana**: One of the most iconic Holi celebrations happens in Barsana, near Mathura. Women playfully beat men with sticks (*lathis*) while men attempt to shield themselves, recreating a playful episode from Krishna's life. This unique tradition attracts visitors from around the world.
  - **Holi in Vrindavan**: The celebrations spill over into Mathura from the nearby town of Vrindavan, where the Banke Bihari Temple hosts grand events. The air is filled with the joyful sounds of music, singing, and chanting.
  - **Colorful Streets**: The streets of Mathura come alive with people throwing vibrant powders (*gulal*), dancing, and drenching each other with colored water, embodying the playful and joyous nature of Krishna.
  
  ### Significance
  Holi in Mathura is deeply rooted in the legends of Krishna. According to folklore, Krishna playfully complained to his mother about the fairness of Radha's complexion compared to his own. To pacify him, his mother suggested smearing colors on Radha’s face, symbolizing unity and love. This story is at the heart of Mathura's Holi celebrations.
  
  ### Unique Experiences
  - **Phoolon Ki Holi**: At select temples, Holi is celebrated with flowers instead of colors, creating a mesmerizing and fragrant spectacle.
  - **Cultural Performances**: Traditional dance and drama, particularly reenactments of Krishna’s life (*Krishna Leela*), add a spiritual and artistic touch to the celebrations.
  - **Special Offerings**: Sweet delicacies like *gujiya*, *thandai*, and other festive treats are prepared and shared among families and communities.
  
  ### Tips for Visitors
  - **Plan Ahead**: Holi in Mathura attracts massive crowds, so it’s advisable to book accommodations in advance.
  - **Dress Comfortably**: Wear clothes you don’t mind getting stained with colors.
  - **Join the Festivities**: Don’t just watch—immerse yourself in the joy and exuberance of Holi in Mathura.
  
  Holi in Mathura is more than just a festival—it’s a vibrant and unforgettable celebration of life, love, and unity, making it a must-visit event for everyone.`,
      },
      {
        heading: "3# - Govardhan Puja",
        image: "/images/goverdhanpooja.jpeg",
        description: `
  **Govardhan Puja** is an important festival in Mathura, celebrated to honor the divine intervention of **Lord Krishna**, who lifted the Govardhan Hill to protect the people of Gokul from the wrath of Lord Indra. This event is an embodiment of Krishna’s devotion to his devotees, showcasing his power and compassion.
  
  ### Celebration Highlights
  - **Offering Food to Krishna**: Devotees prepare and offer traditional food items like **dal, bati, and churma**, which are associated with the story of Krishna lifting the hill. These dishes represent the nourishment and protection Krishna provided to the people of Gokul during the torrential rains.
  - **Govardhan Parikrama**: The day is marked by the sacred act of **Govardhan Parikrama**, where devotees walk around the Govardhan Hill in a ritualistic circle, praying and offering their gratitude for Krishna’s divine protection.
  - **Special Prayers at Temples**: Temples dedicated to Lord Krishna, such as the Shri Krishna Janmabhoomi and the Dwarkadheesh Temple, host special prayers and hymns to commemorate this day. The celebrations are marked by devotional singing, chanting of mantras, and offerings of flowers and sweets.
  
  ### Significance
  The story behind **Govardhan Puja** is rooted in Hindu mythology. According to the legend, Lord Krishna saved the people of Gokul from a catastrophic flood caused by Lord Indra, the god of rain, who was angered when the villagers stopped offering him annual tributes. In response to this, Krishna lifted the Govardhan Hill on his little finger, providing shelter to the people and cattle from the heavy rains. The festival is a celebration of Krishna’s protection and the community's gratitude towards him.
  
  ### Unique Rituals
  - **Creating Govardhan Hill Replicas**: In many places, small replicas of Govardhan Hill are created using **mud or stones**, and devotees offer food and prayers to these hills as a symbol of Krishna's protective power.
  - **Community Feasts**: Families and communities come together to prepare and share special dishes, symbolizing the unity and love among the people of Mathura.
  - **Ritualistic Worship**: The rituals are observed with great devotion, often including the lighting of lamps and the chanting of Krishna's name, invoking his blessings for prosperity, health, and happiness.
  
  ### Tips for Visitors
  - **Participate in the Rituals**: If visiting during Govardhan Puja, join the **Govardhan Parikrama** to experience the spiritual essence of the festival.
  - **Taste Traditional Dishes**: Don’t miss the opportunity to taste the **dal, bati, and churma**, which are an integral part of the festival's food offerings.
  
  Govardhan Puja in Mathura is a time to reflect on the power of faith, the importance of gratitude, and the boundless love that Lord Krishna has for his devotees. This celebration is a truly memorable experience for all who participate.`,
      },
      {
    heading: "4# - Radhashtami",
    image: "/images/radhaashtami.jpeg",
    description: `
**Radhashtami** is a significant festival celebrated in Vrindavan and Mathura to honor the birth of **Goddess Radha**, the divine consort of Lord Krishna. This auspicious day occurs 15 days after Janmashtami, Krishna’s birthday, and highlights Radha’s central role in Krishna’s life and the spiritual traditions of Braj.

### Celebration Highlights
- **Temple Decorations**: Temples dedicated to Radha, such as the **Radha Vallabh Mandir** and **Radha Raman Temple**, are adorned with flowers, lights, and colorful decorations. The ambiance is filled with divine energy as devotees gather to celebrate Radha’s grace and beauty.
- **Special Prayers and Kirtans**: Devotees engage in melodious kirtans and bhajans praising Radha’s divine love for Krishna. The chanting of **‘Radhe Radhe’** resonates through Vrindavan, creating a deeply spiritual atmosphere.
- **Abhishek Rituals**: The idols of Radha are bathed in milk, honey, and other sacred ingredients as part of the **Abhishek ceremony**, symbolizing purification and devotion. The ritual is accompanied by the recitation of hymns and prayers.
  
### Significance
Radhashtami celebrates the **embodiment of pure love and devotion** through Radha, who is considered the eternal companion of Lord Krishna. According to Hindu mythology, Radha’s birth is believed to have occurred in **Barsana**, near Vrindavan, where she descended as an avatar of the goddess Lakshmi. Her love and unwavering devotion to Krishna symbolize the ultimate path to divine union.

### Unique Rituals
- **Processions and Cultural Programs**: The day is marked by processions where idols of Radha and Krishna are carried on beautifully decorated palanquins, accompanied by music and dance performances.
- **Distribution of Prasad**: Devotees offer sweets, fruits, and flowers to Radha, which are later distributed as **prasad** to visitors.
- **Special Offerings**: Traditional dishes like **makhan (butter)**, sweets, and seasonal fruits are offered to the goddess, symbolizing her simplicity and divine connection with Krishna.

### Tips for Visitors
- **Visit Barsana**: Witness the grandeur of Radhashtami celebrations in Radha’s birthplace for a truly unique experience.
- **Participate in Devotional Singing**: Join the kirtans and bhajans to immerse yourself in the spiritual energy of the festival.
- **Explore the Temples**: Experience the splendor of Vrindavan’s temples, especially during the festival, when they are beautifully illuminated.

Radhashtami is not just a festival but a celebration of divine love and devotion. Being part of the Radhashtami festivities in Vrindavan is a soul-stirring experience that leaves a lasting impression on the hearts of all who visit.`,
},

    ],
  },

  "foods-of-vrindavan": {
    title: "Local Foods of Vrindavan",
    sections: [
      {
        heading: "Vrindavan's Peda -",
        image: "/images/peda.jpeg",
        description: `
  **Peda** is Mathura's most iconic sweet and a must-try for anyone visiting this sacred city. It is a popular dessert that holds deep cultural significance and is loved by people of all ages. Made from **condensed milk**, **sugar**, and **ghee**, peda is rich, creamy, and has a dense texture that makes it a delightful treat.
  
  ### Ingredients and Preparation
  The primary ingredients used in making peda include **full-fat milk**, which is reduced to a dense consistency by cooking it with **sugar** and **ghee** (clarified butter). Often, **cardamom** or **saffron** is added to enhance the flavor and give it a distinct aroma. The peda is then garnished with crushed **pistachios** or **almonds** for an extra layer of flavor and a crunchy texture.
  
  ### Popular Variants
  Mathura's peda comes in different varieties, each offering unique flavors:
  - **Mawa Peda**: The most traditional variety, made with **khoya (mawa)**, a milk reduction.
  - **Kesar Peda**: Infused with **saffron** for a rich, golden color and aromatic flavor.
  - **Pistachio Peda**: Garnished generously with **pistachios**, giving it a nutty crunch.
  
  ### Significance
  Mathura's peda is not only a treat for the taste buds but also an important part of the local culture. It is often offered at temples as part of religious rituals and is a favorite during festivals like **Janmashtami**. It symbolizes the abundance and sweetness of Lord Krishna's birthplace.
  
  ### Where to Buy
  Peda can be found in most sweet shops throughout Mathura, especially near the **Shri Krishna Janmabhumi Temple**. Many shops in the area specialize in making fresh peda throughout the day.
  
  Mathura’s peda is a must-try delicacy for those seeking to indulge in the rich culinary tradition of the region.`,
      },
     
    {
      heading: "Vrindavan's Kachori -",
      image: "/images/kachori.jpeg",
      description: `
**Kachori** is one of the most beloved snacks in Mathura, offering a delightful combination of crispy texture and flavorful filling. This popular dish is made by deep-frying a round dough shell filled with a spiced mixture of **moong dal** (yellow lentils) or **peas**. It is typically enjoyed as a breakfast or an evening snack and is a favorite among locals and tourists alike.

### Ingredients and Preparation
The dough for the kachori is made with **flour** and **ghee**, creating a crispy outer layer when deep-fried. The filling varies, but the most common ones are made with **moong dal** or **spiced peas**, flavored with a blend of **cumin**, **coriander**, **ginger**, and **chili powder**. The spicy and aromatic filling contrasts beautifully with the crispy outer shell.

### Accompaniments
Kachoris are usually served with **tangy tamarind chutney** or **mint chutney**, which adds a refreshing burst of flavor and balances the spice. Some vendors also serve it with **aloo ki sabzi** (spiced potato curry), making it a more filling meal.

### Popularity and Significance
Kachori is a staple street food in Mathura and is often enjoyed by devotees visiting the temples or people seeking a quick snack during their visit to the city. It is especially popular during **festivals** like **Holi** and **Diwali**, when the streets are filled with food stalls offering a variety of snacks.

### Where to Buy
Kachoris can be found in local markets around Mathura, including **Holy Gate Market** and **Chatta Bazaar**, where vendors serve them fresh and hot. Many shops specialize in making kachoris, and they are often paired with a cup of **masala chai** for a perfect snack.

Mathura's kachori is a must-try for anyone visiting this historic city, combining the richness of local flavors with a crispy, satisfying texture.`,
    },
    {
      heading: "Vrindavan's Mathri -",
      image: "/images/mathri.jpeg",
      description: `
**Mathri** is a popular savory snack in Mathura, known for its crispy texture and delightful flavor. Made from **whole wheat flour**, this deep-fried snack is seasoned with a blend of **carom seeds (ajwain)** and **cumin**, giving it a unique taste. Mathri is commonly served with **pickles** or **yogurt**, making it a perfect accompaniment to meals or enjoyed as a snack on its own.

### Ingredients and Preparation
The dough for Mathri is prepared using **whole wheat flour**, **ghee**, and a variety of spices like **cumin** and **carom seeds (ajwain)**. The dough is rolled out into flat, round discs and deep-fried until golden and crispy. The spices add a distinctive flavor to the dough, while the deep-frying process gives Mathri its crunchy texture.

### Significance and Popularity
Mathri is a traditional snack often enjoyed during festivals such as **Diwali**, **Holi**, and **Govardhan Puja**. Its crispness and savory taste make it a perfect snack for celebrations and gatherings. It’s often served with a side of tangy **pickle** or cooling **yogurt** to complement the spiciness.

### Where to Buy
Mathri can be found in various markets across Mathura, especially in local markets like **Chatta Bazaar** and **Holy Gate Market**. Many vendors sell Mathri freshly prepared, making it an essential treat for visitors and locals alike. The snack can also be found in sweet shops that specialize in traditional Mathura snacks.

Mathri is a must-try for anyone visiting Mathura, offering a taste of local tradition and a satisfying crunch with every bite.`,
    },
    {
  heading: "Aloo Tikki and Chaat -",
  image: "/images/alootikki.jpeg", // Replace with the correct image path
  description: `
**Aloo Tikki** and **Chaat** are quintessential street foods of Vrindavan, offering a burst of flavors and textures in every bite. Aloo Tikki, made from **mashed potatoes** seasoned with aromatic spices, is shallow-fried until crispy and golden brown. This delicious potato patty serves as the base for a variety of chaats, which are further enhanced with tangy chutneys, creamy yogurt, and a medley of spices.

### Ingredients and Preparation
The **Aloo Tikki** is prepared using boiled and mashed potatoes mixed with spices like **cumin**, **coriander powder**, and **green chilies**. The mixture is shaped into round patties and fried until crisp. The chaat toppings include **tamarind chutney**, **mint chutney**, **beaten yogurt**, and a sprinkle of **chaat masala**, **sev**, and fresh **coriander leaves**. Some versions also include boiled chickpeas or spiced lentils for added texture and flavor.

### Flavor Explosion
The magic of Aloo Tikki Chaat lies in its contrasting flavors and textures. The crispy tikki, combined with the tanginess of the chutneys, the creaminess of yogurt, and the crunch of sev, creates a perfect harmony of sweet, spicy, and sour notes. Every bite is a delightful medley that leaves you craving more.

### Significance and Popularity
Aloo Tikki Chaat is not just a favorite among locals but also a must-try for visitors. It’s a staple during festivals like **Holi** and **Janmashtami**, where its vibrant flavors match the celebratory spirit. The dish is widely available across street vendors and local eateries, making it an accessible treat for anyone exploring Vrindavan.

### Where to Try
You can find Aloo Tikki Chaat in **local markets** and **street food stalls** across Vrindavan, particularly near temple complexes and popular tourist spots. The bustling lanes near the **Banke Bihari Temple** and **ISKCON Temple** are known for serving some of the most flavorful and authentic versions.

Aloo Tikki and Chaat are more than just snacks—they’re an essential part of Vrindavan’s culinary culture and a delicious way to immerse yourself in the local flavors.`,
},
{
  heading: "Puri-Sabzi -",
  image: "/images/puri.jpg", // Replace with the correct image path
  description: `
**Puri-Sabzi** is a beloved traditional dish of Vrindavan, combining the simplicity of fluffy puris with the rich, spiced flavors of potato curry (sabzi). This hearty meal is a staple in local households and temples, often served during festivals and religious gatherings.

### Ingredients and Preparation
The **puri** is made from whole wheat flour, kneaded into a soft dough, rolled into small discs, and deep-fried until golden and puffed. The **sabzi** is a flavorful potato curry, prepared with boiled potatoes, tomatoes, and a mix of spices like **cumin**, **turmeric**, **red chili powder**, and **garam masala**. Garnished with fresh coriander, the sabzi adds a burst of flavor to the puri.

### A Complete Meal
The dish offers a delightful balance of textures and flavors—the crispiness of puris complements the creamy and spiced potato curry. It is often accompanied by tangy pickles, yogurt, or green chutney, making it a wholesome and satisfying meal.

### Cultural Significance
Puri-Sabzi is not just a meal but a part of Vrindavan’s culinary and spiritual traditions. It is frequently prepared as **bhog** (offering) in temples and enjoyed during festive occasions like **Holi**, **Diwali**, and **Janmashtami**, symbolizing abundance and devotion.

### Where to Relish
Visitors can enjoy Puri-Sabzi in **local eateries**, **temple kitchens**, and street food stalls around Vrindavan. The lanes near **Banke Bihari Temple** and **Prem Mandir** are especially known for serving authentic and delicious versions of this classic dish.

Puri-Sabzi is more than just food—it’s a culinary experience that reflects the simplicity and warmth of Vrindavan’s culture.`,
},
{
  heading: "Besan Laddu -",
  image: "/images/besan.jpg", // Replace with the correct image path
  description: `
**Besan Laddu** is a quintessential sweet delicacy of Vrindavan, cherished for its rich flavor and melt-in-the-mouth texture. Made with **gram flour (besan)**, **ghee**, and **sugar**, these laddus are often offered as **prasad** in temples and hold a special place in Vrindavan’s culinary traditions.

### Ingredients and Preparation
The laddus are prepared by roasting gram flour in **pure ghee** until it turns golden brown and emits a nutty aroma. The roasted flour is then mixed with powdered sugar and flavored with **cardamom**. The mixture is shaped into round balls, sometimes garnished with chopped nuts like almonds or pistachios for added richness.

### Sweet and Symbolic
Besan laddus are not only delicious but also symbolize **purity and devotion**. Their simple ingredients and wholesome preparation make them a popular offering during religious rituals and festive occasions like **Janmashtami** and **Holi**.

### Temple Prasad
In Vrindavan, besan laddus are often distributed as **prasad** in temples, making them an integral part of spiritual experiences. The laddus, blessed during temple rituals, are believed to carry the divine essence of the deity.

### Where to Savor
You can find besan laddus at **sweet shops** near major temples like **Banke Bihari Temple** and **ISKCON Vrindavan**. Many shops specialize in freshly prepared laddus, ensuring their authentic taste and quality.

Besan laddus are more than just sweets—they’re a blend of tradition, devotion, and unparalleled taste, offering visitors a true taste of Vrindavan’s sacred charm.`,
},







  ],
},
"things-to-do-in-vrindavan":{
  title:"Things to do in vrindavan",
  sections:[
{
  heading: "1# - Visit the Banke Bihari Temple",
  image: "/images/Bankey-Bihari.jpg",
  description: `
The **Banke Bihari Temple** is a spiritual and architectural gem of Vrindavan, dedicated to Lord Krishna in his playful child form. The temple is a must-visit for devotees and travelers alike due to its unique rituals and vibrant atmosphere. Built in the 19th century by Swami Haridas, the temple's deity, Banke Bihari Ji, holds a special place in the hearts of millions.  

One of the temple’s most distinctive features is the darshan (viewing) style, where curtains between the deity and devotees are opened and closed frequently to preserve the spiritual energy of the idol. This ritual adds an air of mystery and devotion, leaving visitors mesmerized by the divine charm of Lord Krishna.  

The temple sees large crowds, especially during Janmashtami and Holi, when the premises are decorated with flowers, lights, and festive colors. Devotees sing bhajans and chant Krishna's name, creating an electrifying spiritual ambiance. The aarti, held daily, is an unmissable experience as it fills the temple with divine music and energy.  

The temple's architectural beauty lies in its intricate carvings and traditional Rajasthani-style design, offering a glimpse into the region’s rich cultural heritage. While visiting, ensure to explore the nearby market selling Krishna-themed souvenirs, such as idols, jewelry, and clothes.  

**Tips for Visitors**:  
- Arrive early in the morning to avoid the rush and enjoy peaceful darshan.  
- Dress modestly and follow temple guidelines.  
- Don’t miss the prasad, which is considered highly auspicious.  

A visit to the **Banke Bihari Temple** is a journey into devotion, culture, and history, making it an essential part of your Vrindavan experience.`
},
{
  heading: "2# - Attend Evening Aarti at Yamuna River",
  image: "/images/Yamuna-Arti.jpg",
  description: `
The **Evening Aarti at the Yamuna River** is a mesmerizing experience that blends devotion, natural beauty, and spiritual energy. Held at the ghats of the sacred Yamuna River, this ritual is a must-attend for anyone visiting Vrindavan. The aarti is performed by priests who chant Vedic mantras, accompanied by the rhythmic sounds of bells and the serene flow of the river.

As the evening descends, the ghats come alive with the glow of oil lamps and the fragrance of flowers offered to the river. Devotees gather with deep reverence, holding diya lamps in their hands, and releasing them into the river as a symbol of their prayers and wishes. The sight of hundreds of floating lamps lighting up the Yamuna is truly magical and creates a mystical atmosphere.  

The aarti is more than just a ritual; it is a celebration of the Yamuna, revered as a goddess and closely associated with Lord Krishna's divine pastimes. The chants and devotional songs during the ceremony transport you to a different realm, making you feel connected to the spiritual essence of Vrindavan.  

**Tips for Visitors**:  
- Reach the ghats early to secure a good viewing spot.  
- Participate in the diya floating ritual for a truly immersive experience.  
- Capture the moment, but be mindful of maintaining the sanctity of the ritual.  

Attending the **Evening Aarti** at the Yamuna River is not just a visual delight but also a soulful experience that leaves you with a deep sense of peace and devotion.`
},
{
  heading: "3# - Explore the Prem Mandir",
  image: "/images/prem-mandir.jpg",
  description: `
The **Prem Mandir**, also known as the "Temple of Divine Love," is one of Vrindavan's most magnificent attractions. Built entirely from Italian white marble, this architectural marvel is dedicated to the eternal love of Radha and Krishna, as well as Sita and Ram.  

The temple was established by Jagadguru Kripalu Maharaj and is adorned with intricate carvings that depict the life and pastimes of Lord Krishna. As you walk through its grand gates, the breathtaking sculptures and beautiful gardens immediately transport you to a divine realm.  

One of the highlights of Prem Mandir is the light and sound show held in the evening, where the entire temple is illuminated in vibrant colors. This spectacle, combined with the melodious bhajans played in the background, creates a serene and uplifting experience. The inner sanctum houses stunning idols of Radha-Krishna and Sita-Ram, crafted with exquisite detail and devotion.  

The temple also features elaborately designed panels on its walls, showcasing scenes from Krishna’s life, including the Ras Leela, Govardhan Puja, and his playful acts in Vrindavan. The lush gardens surrounding the temple add to its beauty, making it an ideal place for meditation and relaxation.  

**Tips for Visitors**:  
- Visit during the evening to witness the enchanting light and sound show.  
- Take your time exploring the gardens and admiring the intricate sculptures.  
- Follow the temple’s decorum, as it’s a place of worship.  

A visit to **Prem Mandir** is a journey into spirituality and art, offering a perfect blend of devotion and architectural grandeur. It’s an unmissable experience that leaves visitors with a sense of awe and inner peace.`
},
{
  heading: "4# - Participate in Holi Celebrations",
  image: "/images/holi.jpeg",
  description: `
Vrindavan’s **Holi celebrations** are renowned worldwide for their vibrant and spiritual nature. This festival, dedicated to **Lord Krishna**, is celebrated with unmatched enthusiasm, making it a truly unique experience. The **Banke Bihari Temple** is the focal point of the celebrations, where devotees and visitors come together to play with flower petals, colored powders, and sing joyful devotional songs.

The festival reenacts the playful Holi of Krishna with Radha and the Gopis, infusing the atmosphere with love and joy. Visitors can witness the **Phoolon Ki Holi** (Flower Holi), where petals are thrown instead of colors, creating a serene yet festive experience. The **Widow’s Holi**, a remarkable event that celebrates inclusion, allows widows, traditionally excluded from such festivities, to join the celebration, symbolizing unity and spiritual equality.

Vrindavan's Holi is not just about colors but is also deeply devotional. The **dhol** beats, chants of **Hare Krishna**, and the fervor in the air make this celebration feel divine. It transcends boundaries of age, nationality, and culture, as participants come together to honor Krishna's love and spirit.

For those visiting Vrindavan, participating in the Holi celebrations is a spiritual and joyous experience. It’s an opportunity to connect with the sacred history of the place, engage in rituals, and celebrate the divine love that Lord Krishna shared with Radha and his devotees. Vrindavan’s Holi is more than just a festival of colors—it’s a vibrant expression of faith, love, and community that will leave a lasting impact on all who experience it.
  `,
}

,  
{
heading: "5# - Take a Boat Ride on the Yamuna River",
image: "/images/Boat.jpeg",
description: `
A **boat ride on the Yamuna River** offers a serene and spiritual experience, allowing visitors to connect deeply with Vrindavan’s sacred heritage. As you glide through the calm waters, the view of the **ghats** and ancient temples lining the riverbanks presents a picturesque and peaceful setting. The boat ride provides a unique opportunity to witness the river's spiritual significance, often linked to Lord Krishna's divine pastimes in Vrindavan.

The early morning rides are particularly enchanting. As the first rays of the sun touch the river, the water reflects a soft golden glow, creating an atmosphere of tranquility and peace. This is an ideal time for meditation or quiet contemplation, as the gentle sounds of the water and the distant chants from nearby temples enhance the spiritual ambiance.

Evening boat rides, however, offer a completely different experience. The sunset over the Yamuna transforms the sky into a canvas of vibrant colors, with shades of orange, pink, and purple reflecting on the water’s surface. The sight is mesmerizing and perfect for introspection or prayer, as the evening aarti rituals on the ghats fill the air with devotion and peace. 

The boat ride also provides an opportunity to observe the daily rituals performed by devotees along the river, from offering prayers to lighting lamps. This adds a layer of cultural and spiritual depth to the experience, making it more than just a boat ride but a journey into Vrindavan's soul.

Whether at sunrise or sunset, a boat ride on the Yamuna River offers a profound connection to Vrindavan’s spiritual essence, providing visitors with memories to cherish forever.
`,
}

]

},    


  //Barsana
  "places-to-visit-in-barsana": {
    title: "Place to visit in Barsana",
    sections: [
      {
        heading: "#1 - Radha Rani Temple: A Devotional Haven in Barsana",
        image: "/images/Radha rani temple.jpg",
        description: `
The **Radha Rani Temple**, also known as the Radha Rani Mandir, is a famous Hindu temple dedicated to Goddess Radha, the consort of Lord Krishna. It is one of the most revered temples in India, attracting pilgrims and devotees from across the world, especially those who follow the Vaishnavism tradition.

### Significance:
- Dedicated to **Radha Rani**, symbolizing love, devotion, and compassion.
- Barsana, her **birthplace**, is a major pilgrimage site for Vaishnavism followers.

### Architecture:
- Intricate carvings and traditional North Indian design.
- The **idol of Radha Rani**, made of marble, exudes serenity and divinity.

### Rituals & Festivals:
- Daily **aartis**, kirtans, and prayers.
- **Holi** and **Radha Ashtami** are celebrated with grandeur.
- Special festivities during **Janmashtami** and **Lathmar Holi**, unique to Barsana.

### Timings:
- **Morning Darshan**: 5:00 AM - 12:00 PM (Mangala Aarti at 5:30 AM).
- **Evening Darshan**: 4:00 PM - 9:00 PM.

### Location & Accessibility:
- Situated in **Barsana**, near Mathura in Uttar Pradesh.
- **By Air**: Agra Airport (70 km), Delhi Airport (150 km).
- **By Train**: Mathura Junction (45 km).
- **By Road**: Connected by buses and taxis from Mathura and nearby cities.

### Nearby Attractions:
- **Ladli Ji Temple**, **Brij Kshetra**, and **Govardhan Hill** are must-visit sites.

The **Radha Rani Temple** is not just a temple but a spiritual experience that embodies divine love, devotion, and the legacy of Radha and Krishna.
  `,
      },
      {
        heading: "#2 - Peeli Pokhara: A Sacred Pond in Barsana",
        image: "/images/peeli-pokhar.jpg",
        description: `
The **Peeli Pokhara** in Barsana, Uttar Pradesh, is a sacred pond associated with the divine pastimes of Radha and Krishna, offering pilgrims a serene and spiritual retreat. Here's all about it, including details on its significance and timings:

### What is Peeli Pokhara?
- Peeli Pokhara translates to the "Yellow Pond" in Hindi. The name "Peeli" (Yellow) is derived from the yellow color of the pond, and "Pokhara" refers to a body of water, often used to describe a pond or lake.
- It is a sacred pond or water body located in Barsana, associated with the legend of Radha and Krishna. It is one of the important sites in Barsana for pilgrims who visit the area in connection with their devotion to Radha and Krishna.

### Significance:
- **Legend of Radha & Krishna**: Associated with their playful and loving interactions, symbolizing divine love and joy.
- **Spiritual Importance**: Pilgrims believe taking a dip here purifies the soul and brings blessings.

### Nearby Attractions:
- **Radha Rani Temple**: A revered site dedicated to Radha, located nearby.
- Other sacred spots in Barsana, including **Ladli Ji Temple** and **Brij Kshetra**, are worth exploring.

### Timings:
- **Open Access**: Best visited between **5:00 AM and 6:00 PM** for a peaceful experience.
- **Festivals**: Radha Ashtami and Holi bring special celebrations and rituals to the site.

### How to Reach:
- **By Train**: Mathura Junction (45 km).
- **By Road**: Well-connected by local transport from Mathura, Vrindavan, and Agra.
- **By Air**: Agra Airport (70 km) or Delhi Airport (150 km).

### Festivals:
- **Radha Ashtami**: Special prayers and rituals celebrating Radha’s birth.
- **Holi**: Unique celebrations like **Lathmar Holi**, adding to the site’s charm.

The **Peeli Pokhara** is a tranquil destination that embodies the spiritual essence of Radha and Krishna’s love, making it an essential stop for devotees exploring the Braj region.
  `,
      },
      {
        heading: "#3 - Maan Ghar: The Symbolic House of Divine Love",
        image: "/images/maan-ghar.jpg",
        description: `
The **Maan Ghar** in Barsana, Uttar Pradesh, is a spiritually significant site associated with the divine leelas (pastimes) of Radha and Krishna. It symbolizes Radha's playful anger (maan) and Krishna's heartfelt efforts to reconcile, making it a place of devotion and spiritual reflection.

### What is Maan Ghar?
- **"House of Pride"**: Named after Radha’s playful mood of maan, where she expressed loving displeasure towards Krishna.
- **Divine Story**: Krishna's teasing led to Radha's temporary anger, prompting Krishna's sincere attempts to win back her affection. This leela reflects the depth and playfulness of their eternal love.

### Significance:
- **Symbol of Divine Love**: Represents the playful, teasing, and deeply loving bond between Radha and Krishna, embodying the highs and lows of divine relationships.
- **Pilgrimage Site**: A sacred stop for devotees meditating on the eternal connection between Radha and Krishna.
- **Spiritual Lessons**: Highlights devotion, reconciliation, and the beauty of emotional expressions in divine relationships.

- **Best Times to Visit**:
  - **Morning**: 5:00 AM - 8:00 AM for a peaceful atmosphere.
  - **Daytime**: Before 6:00 PM for easier access and convenience.


### Location:
- **Barsana, Uttar Pradesh**: A key site in the Braj region, closely tied to Radha’s childhood and her divine relationship with Krishna.

- **Nearby Attractions**: Radha Rani Temple, Peeli Pokhara, Ladli Ji Temple and Brij

### How to Reach:
- **By Train**: Mathura Junction (45 km) is the nearest railway station. From there, take local transport to Barsana.
- **By Road**: Well-connected to Mathura, Vrindavan, and Agra via buses and taxis.
- **By Air**: Agra Airport (70 km) or Delhi’s Indira Gandhi International Airport (150 km) are the nearest airports.

### Festivals & Events:
- **Radha Ashtami**: Celebrates Radha’s birth with rituals and processions.
- **Holi**: Features Barsana’s famous Lathmar Holi, where Radha’s mood of maan is reenacted.
- **Janmashtami**: Devotees gather for special prayers and festivities.

The **Maan Ghar** stands as a beautiful testament to the love and playful nature of Radha and Krishna’s relationship. It offers devotees a chance to reflect on divine love, where even moments of separation deepen the bond. A visit to Maan Ghar is an essential part of any spiritual journey through the Braj region.
  `,
      },
      {
        heading: "#4 - Vilas Garh: The Divine Seat of Luxuriant Pastimes",
        image: "/images/vilas-ghar.jpg",
        description: `
The **Vilas Garh**, located atop the **Sankri Khor** (the sacred narrow path) in Barsana, Uttar Pradesh, is a place of immense spiritual significance. As its name suggests, "Vilas" refers to divine luxuriance or enjoyment, and the site is associated with the pastimes of Radha and Krishna as described in sacred texts.


### Historical & Religious Significance:
- **Radha’s Royal Persona**: As per the **Brahma Vaivarta Purana**, Radha was honored here as a queen, symbolized by a regal umbrella held over her.
- **Divine Pastimes**: It celebrates Krishna’s adoration for Radha and their joyful, luxurious leelas (pastimes), a hallmark of Braj culture.

### Timings:
- **Open Access**: Like other open pilgrimage spots, Vilas Garh doesn’t have fixed timings but is best visited during daylight hours for safety and ease of exploration.
  - **Morning**: 5:00 AM - 8:00 AM, ideal for a peaceful atmosphere.
  - **Day Visit**: Until 6:00 PM, to enjoy the panoramic views and spiritual serenity.
- **Festival Periods**: The site is especially lively during **Radha Ashtami**, **Holi**, and **Janmashtami**, with special prayers, rituals, and processions.

### Location:
- **Barsana, Uttar Pradesh**: Situated in the Braj region, Barsana is a hub of spiritual activity connected to Radha's life and divine leelas.
- **Nearby Sacred Path**: Sankri Khor, the narrow sacred way leading to Vilas Garh, adds to the journey's spiritual symbolism.

### How to Reach:
- **By Train**: Mathura Junction (45 km) is the nearest railway station.
- **By Road**: Accessible by taxis or buses from Mathura, Vrindavan, and Agra.
- **By Air**: Agra Airport (70 km) or Delhi Airport (150 km).


### Spiritual Importance:
- **Royal Symbolism**: Vilas Garh highlights Radha’s divine sovereignty and Krishna’s adoration for her as the queen of his heart.
- **Meditative Serenity**: The site offers a tranquil atmosphere, ideal for meditation and reflection on the eternal love of Radha and Krishna.
- **Pilgrimage Value**: It serves as a significant stop for devotees exploring Barsana and the larger Braj region.

The **Vilas Garh** stands as a beacon of divine luxury and joy, celebrating Radha and Krishna's playful and regal moments. Positioned above the sacred Sankri Khor, it combines spiritual depth with historical allure. Visiting Vilas Garh is not just a journey through the Braj region but a heartfelt step into the divine leelas of Radha and Krishna.
  `,
      },
      {
        heading: "#5 - Dan Bihari Temple: A Sacred Abode of Lord Krishna",
        image: "/images/Dab-bihari.jpg",
        description: `
The **Dan Bihari Temple**, located in **Mathura**, Uttar Pradesh, is a revered Hindu temple dedicated to **Lord Krishna**. Mathura, the birthplace of Krishna, is home to several temples, and Dan Bihari is among the prominent ones, celebrated for its divine connection to Krishna’s playful nature.

### Key Information:
- **Deity**: The temple houses a beautiful idol of **Lord Krishna**, depicted in a vibrant and artistic form.
- **Location**: Situated in the heart of Mathura, close to key spots like **Vishram Ghat** and **Kesava Deo Temple**.

### Significance:
- The name **Dan Bihari** reflects Krishna’s playful nature, particularly his mischievous childhood pastimes.
- Devotees believe visiting the temple brings blessings and divine grace.

### Architecture:
- The temple is built in traditional **North Indian** style, with intricate carvings and a beautifully decorated structure.

### Festivals:
- **Janmashtami** (Lord Krishna's birthday), **Holi**, and **Diwali** are celebrated with grand prayers and processions at the temple.

### Timings:
- **Morning Darshan**: 5:00 AM - 12:00 PM
- **Evening Darshan**: 4:00 PM - 9:00 PM
- **Aarti**: Morning Aarti at 5:00 AM, Evening Aarti at 6:30 PM.

### How to Reach:
- **By Train**: Easily accessible from **Mathura Railway Station**.
- **By Road**: Accessible by taxis or local transport from within Mathura.
- **By Air**: Nearest airport is **Agra** (60 km away).

The Dan Bihari Temple offers a serene spiritual experience, allowing devotees to connect deeply with Lord Krishna’s divine presence, especially during major festivals. It’s an essential visit for Krishna devotees in Mathura.
  `,
      },
      {
        heading:
          "#6 - Mayur Kuti (Mor Kuti): The Divine Spot of Radharani's Maan Leela",
        image: "/images/morkuti.jpg",
        description: `
**Mayur Kuti**, also known as **Mor Kuti**, is a sacred site located near **Daan-garh** and **Gehvar Van** in **Barsana**, Uttar Pradesh. This spot is known for its deep spiritual significance and is tied to the divine pastimes (leelas) of **Radha** and **Krishna**.

### Key Information:
- **Location**: Situated beyond the upper section of Daan-garh near Gehvar Van in Barsana.
- **Legend**: According to local legend, **Radha** engaged in **Maan Leela** (playful displeasure) at **Maangarh**, located opposite **Mayur Kuti**. Krishna tried several ways to reconcile with Radha and dispel her 'maan,' but was unsuccessful initially, which adds to the charm and significance of this site.

### Spiritual Significance:
- **Maan Leela**: The site is deeply connected with the spiritual leela where Radha's playful displeasure and Krishna’s efforts to win her back are central. This episode symbolizes the divine love between Krishna and Radha, highlighting their emotional bond.

### Timings:
- **Visit**: Mayur Kuti is an open-air site with no specific timings for entry. It is best to visit in the early morning or during the day to soak in the serene atmosphere.

### How to Reach:
- **By Train**: The nearest railway station is **Mathura Junction**, which is well connected to Barsana.
- **By Road**: Barsana is easily accessible by road from Mathura, and you can hire taxis or use local transportation.
- **By Air**: The nearest airport is **Agra**, around 70 km away from Barsana.

### Nearby Attractions:
- **Daan-garh** and **Gehvar Van** are nearby spiritual sites.
- **Maan-garh** and other places in Barsana tied to Radha and Krishna's divine pastimes.

Mayur Kuti, with its historical and divine significance, is a must-visit for those following the **Radha-Krishna** tradition. It provides a peaceful, spiritually charged atmosphere for devotees to reflect on the deep emotional bond between the two divine figures.
  `,
      },
    ],
  },

  

"festivals-in-barsana": {
  title: "Festivals in Barsana",
  sections: [
    {
      heading: "1# - Janmashtami (Krishna's Birth Anniversary)",
      image: "/images/janam.jpeg",
      description: `
**Janmashtami** in Barsana is a grand spiritual celebration commemorating the birth of **Lord Krishna**, deeply tied to the town's devotion to Radha-Krishna. The festivities here blend devotion, culture, and divine love, attracting pilgrims and visitors from all over.

### Celebration Highlights
- Barsana's temples, including the iconic **Radha Rani Temple**, are adorned with lights, flowers, and decorations, creating a celestial ambiance.
- The celebrations peak at midnight, believed to be the time of Krishna’s birth, with joyous kirtans, special prayers, and aartis.
- Dramatic enactments of **Krishna Leela**, depicting Krishna’s childhood stories, captivate audiences and bring alive the mythology.

### Special Rituals
- Devotees fast throughout the day and break their fast after midnight with offerings of butter, milk, and sweets—Krishna’s favorite foods.
- Chanting Krishna's names and dancing to devotional songs fill the air with a divine energy, making Janmashtami a memorable experience.

Janmashtami in Barsana offers a unique glimpse into the eternal love of Radha-Krishna and the deep-seated spirituality of this sacred town.`,
    },
    {
      heading: "2# - Lathmar Holi",
      image: "/images/laath-holi.jpg",
      description: `
**Lathmar Holi**, celebrated in Barsana, is an extraordinary event where tradition and playfulness come together. This festival captures the essence of Radha-Krishna’s divine love and draws thousands of visitors every year.

### Celebration Highlights
- Women, representing Radha and her companions, playfully “beat” men with sticks (*lathis*), while men shield themselves, recreating a joyful episode from Krishna’s youth.
- The **Radha Rani Temple** becomes the heart of the festivities, adorned with vibrant decorations and filled with chanting and singing.

### Unique Rituals
- Colored powders (*gulal*) fill the air, symbolizing love and unity.
- Traditional songs, drums, and dhol beats accompany the playful festivities, creating an electrifying atmosphere.

**Lathmar Holi** is a must-visit event that blends joy, tradition, and spirituality, offering an unforgettable cultural experience.`,
    },
    {
      heading: "3# - Radha Ashtami",
      image: "/images/radha-ashtmi.jpg",
      description: `
**Radha Ashtami**, celebrated as the birth anniversary of **Radha Rani**, is a deeply spiritual festival in Barsana. The town, being Radha’s birthplace, transforms into a haven of devotion and festivity.

### Celebration Highlights
- The **Radha Rani Temple** hosts grand aartis, kirtans, and bhajans, immersing devotees in Radha’s divine love.
- Priests narrate Radha’s life stories, emphasizing her eternal bond with Krishna.
- Elaborate processions, adorned with flowers and music, parade through the streets, showcasing Barsana’s devotion.

### Significance
Radha Ashtami celebrates the goddess who embodies unconditional love and devotion. The festival is marked by prayers and offerings of sweets, flowers, and fruits to honor Radha Rani.

Radha Ashtami in Barsana is a profound celebration of love, devotion, and spirituality, making it an unmissable event for Krishna devotees.`,
    },
    {
      heading: "4# - Sharad Purnima",
      image: "/images/fullmoon.jpg",
      description: `
**Sharad Purnima**, celebrated on the full moon night, holds immense significance in Barsana as it is associated with **Krishna’s Raas Leela**. The festival symbolizes divine love and the spiritual connection between Krishna and his devotees.

### Celebration Highlights
- Devotees gather under the moonlight at the **Radha Rani Temple** for prayers and kirtans, creating a serene and spiritual atmosphere.
- The town is lit with oil lamps, symbolizing enlightenment and divine blessings.
- Special delicacies made of milk and rice are offered to Krishna, reflecting his childhood fondness for these simple foods.

Sharad Purnima in Barsana is a night of devotion, joy, and divine connection, offering a tranquil yet deeply spiritual experience.`,
    },
  ],
},

"things-to-do-in-barsana": {
  title: "Things to Do in Barsana",
  sections: [
    {
      heading: "1# - Visit the Radha Rani Temple",
      image: "/images/Radha rani temple.jpg",
      description: `
The **Radha Rani Temple**, perched atop the Bhanugarh Hill, is the most iconic landmark in Barsana. Dedicated to Radha Rani, the consort of Lord Krishna, the temple exudes a spiritual aura that draws thousands of devotees annually.

### Key Highlights
- The temple’s stunning architecture features intricate carvings and colorful paintings depicting scenes from Radha and Krishna’s divine love story.
- The panoramic views of Barsana from the temple are breathtaking, especially during sunrise and sunset.
- The daily aartis and kirtans create a divine atmosphere, offering visitors a sense of peace and connection with the divine.

### Tips for Visitors
- The temple gets crowded during festivals like **Radha Ashtami**, so plan your visit early in the morning for a serene experience.
- Climbing the steps to the temple might be challenging for some, but the spiritual ambiance makes it worth the effort.`,
    },
    {
      heading: "2# - Experience Lathmar Holi",
      image: "/images/laath-holi.jpg",
      description: `
Barsana is world-famous for its unique and playful **Lathmar Holi**, celebrated days before the main Holi festival. This event is steeped in tradition and brings alive the legends of Radha and Krishna.

### Highlights of the Festival
- Women symbolically “beat” men with sticks (*lathis*), while men shield themselves with traditional props. This playful act recreates an episode from Krishna’s life.
- The streets of Barsana come alive with colors, music, and dance, creating a joyous and festive atmosphere.
- The **Radha Rani Temple** serves as the focal point of the celebrations, hosting rituals and cultural performances.

**Lathmar Holi** is a must-attend event for visitors seeking a vibrant and culturally rich experience.`,
    },
    {
      heading: "3# - Explore the Sacred Kusum Sarovar",
      image: "/images/usum-sarovar.jpg",
      description: `
**Kusum Sarovar** is a serene and sacred tank located near Barsana, known for its spiritual significance and picturesque beauty. Surrounded by lush greenery and historical structures, it’s an ideal spot for peace-seekers and devotees.

### Highlights
- The steps leading down to the water and the surrounding sandstone pavilions create a tranquil ambiance.
- The site is associated with Radha-Krishna legends, making it a revered location for pilgrims.
- Visitors can meditate or simply enjoy the calm surroundings, away from the bustling town.

### Visitor Tips
- Morning visits offer the best experience, as the sunlight enhances the beauty of the sarovar.
- Don’t forget to carry your camera to capture the scenic views.`,
    },
    {
      heading: "4# - Attend Radha Ashtami Celebrations",
      image: "/images/radha.jpg",
      description: `
Barsana is the epicenter of the grand **Radha Ashtami** festival, celebrating the birth of **Radha Rani**, the beloved of Lord Krishna. The town transforms into a vibrant hub of devotion and festivities during this occasion.

### Highlights of the Celebration
- The **Radha Rani Temple** becomes the focal point of the festivities, hosting elaborate prayers, aartis, and devotional singing.
- Grand processions with beautifully adorned idols of Radha Rani and Krishna parade through the streets.
- Special offerings of sweets, fruits, and flowers are made to Radha Rani, symbolizing love and devotion.

Radha Ashtami in Barsana is a deeply spiritual experience that allows visitors to immerse themselves in the love and devotion of Radha-Krishna.`,
    },
    {
      heading: "5# - Trek to the Bhanugarh Hill",
      image: "/images/bhanugarh-hill.jpeg",
      description: `
**Bhanugarh Hill**, on which the Radha Rani Temple is situated, offers an adventurous yet spiritual trekking experience. The hill is not only a place of devotion but also provides stunning views of Barsana’s lush landscape.

### Highlights
- The trek is dotted with small shrines and natural beauty, making the journey as enriching as the destination.
- The hill offers panoramic views of Barsana and the surrounding villages, especially during sunrise and sunset.
- At the top, visitors can relax and reflect in the serene atmosphere, feeling closer to the divine.

### Tips for Trekkers
- Wear comfortable shoes and carry water, especially if visiting during summer.
- Take breaks along the way to enjoy the scenic views and take photographs.

Bhanugarh Hill combines spirituality, adventure, and natural beauty, making it a must-visit attraction in Barsana.`,
    },
  ],
},
"how-to-reach-barsana": {
  title: "How to Reach Barsana",
  sections: [
    {
      heading: "By Air -",
      image: "/images/plane.jpg",
      description: `
The nearest airport to Barsana is **Agra Airport**, situated approximately **85 km** away. For international travelers, the best option is **Indira Gandhi International Airport (Delhi)**, which is about **150 km** from Barsana.

### Key Airports:
- **Agra Airport**: Limited connectivity, but convenient for domestic travelers. From here, you can hire a taxi or take local transport to Barsana.
- **Indira Gandhi International Airport (Delhi)**: The primary hub for international and domestic flights. A road trip from Delhi to Barsana takes around **3-4 hours** via the **Yamuna Expressway**.

### Recommendations:
- Hire a taxi or book private transport in advance for a hassle-free journey from either airport.
- Air travel is ideal for those coming from distant locations, although you will need to combine it with road travel.`,
    },
    {
      heading: "By Train -",
      image: "/images/train.jpg",
      description: `
Barsana does not have a railway station, but the nearest major station is **Mathura Junction**, located around **42 km** away. This station is well connected to major cities like Delhi, Agra, and Jaipur.

### Key Details:
- **Mathura Junction** serves as the main railhead for Barsana. Trains from cities like **Delhi**, **Mumbai**, **Agra**, and **Varanasi** frequently stop here.
- From Mathura, you can hire a taxi, take an auto-rickshaw, or board a local bus to reach Barsana. The journey takes approximately **1 hour**.

### Tips for Travelers:
- Pre-book train tickets during peak seasons, especially during festivals like **Holi** or **Radha Ashtami**, as trains can get fully booked.
- Ensure you have onward transport arranged from Mathura to Barsana for a smooth journey.`,
    },
    {
      heading: "By Road -",
      image: "/images/road.jpg",
      description: `
Barsana is well-connected by road, making it an easily accessible destination from nearby cities like **Delhi**, **Agra**, and **Mathura**.

### Key Routes:
- **From Delhi**: Barsana is about **150 km** away. The most common route is via the **Yamuna Expressway**, followed by connecting highways. The journey takes **3-4 hours**.
- **From Agra**: The distance is approximately **85 km**, taking about **2 hours** via **NH509**.
- **From Mathura**: It’s a short drive of around **42 km**, typically taking **1 hour**.

### Transport Options:
- **Private Cars or Taxis**: Best for comfort and convenience, especially for families or groups.
- **Buses**: Regular government and private buses operate from **Mathura** to Barsana. Direct buses from Delhi and Agra are also available.

### Recommendations:
- Self-drive or taxi services offer more flexibility, especially if you plan to visit multiple nearby locations like **Nandgaon** or **Vrindavan**.
- The roads leading to Barsana are generally well-maintained, making road travel a popular choice.`,
    },
  ],
},
"foods-of-barsana": {
  title: "Foods of Barsana",
  sections: [
    
    {
      heading: "1# - Peda ",
      image: "/images/peda.jpeg",
      description: `
**Peda** is one of Barsana’s iconic sweets, loved by locals and travelers alike. This delicious treat is prepared by reducing milk into a thick, fudge-like consistency and sweetened with sugar. A hint of cardamom and saffron adds a delightful aroma and flavor, making it irresistible.

### Highlights:
- **Temple Connection**: Peda is often offered as prasad in Barsana’s temples, which elevates its cultural and spiritual significance.
- **Varieties**: Besides the traditional peda, modern twists like chocolate peda and kesar peda are available in local sweet shops.

Locals recommend trying peda from **traditional sweet shops** that have been preparing it for generations. Its melt-in-the-mouth texture and perfect sweetness make it an unforgettable experience. Peda is not just a sweet but a part of Barsana’s spiritual and festive celebrations, often gifted to loved ones during special occasions.`,
    },
    {
      heading: "2# - Kachori and Sabzi",
      image: "/images/kachori.jpeg",
      description: `
A visit to Barsana would be incomplete without trying **kachori with sabzi**, a staple breakfast dish loved by locals. The crispy, flaky kachoris are stuffed with spiced lentils or potatoes and served with a flavorful vegetable curry. This combination creates a filling and satisfying meal that’s perfect for starting the day.

### Key Highlights:
- **Local Vendors**: Fresh kachoris fried to golden perfection are best enjoyed from local street vendors.
- **Temple Kitchens**: For a more authentic and spiritual touch, kachoris served as prasad in temples are a must-try.

The sabzi served with kachori is often made with a tangy tomato base, spiced with traditional Indian flavors. Many visitors find this dish not only delicious but also a window into Barsana’s culinary traditions. Pair it with a glass of lassi for a complete, indulgent meal.`,
    },
    {
      heading: "3# - Lassi and Thandai",
      image: "/images/lassi.jpg",
      description: `
Barsana’s hot climate makes **lassi** and **thandai** two of the most popular beverages in the region. These refreshing drinks are not only delicious but also deeply rooted in the local culture, often associated with festivals like Holi and Radha Ashtami.

### Highlights:
- **Lassi**: This creamy yogurt-based drink is sweetened with sugar and sometimes flavored with rosewater or cardamom. Served in traditional earthen pots, it offers a unique earthy flavor that enhances the experience.
- **Thandai**: Prepared with a blend of milk, dry fruits, saffron, and spices like fennel seeds, this cooling drink is especially popular during festive occasions.

Visitors can enjoy these beverages at local shops or temple premises, where they are served as prasad. Both lassi and thandai not only quench your thirst but also provide a burst of energy, making them perfect for a mid-day refreshment.`,
    },
    {
      heading: "4# - Aloo-Puri",
      image: "/images/puri.jpg",
      description: `
**Aloo-Puri** is a classic Indian dish and a favorite in Barsana. It consists of soft, fluffy puris (deep-fried bread) served with a spicy and tangy potato curry. This wholesome dish is a staple during festivals and religious gatherings, often served in temple kitchens.

### Where to Try:
- **Street Vendors**: For a spicy, flavorful version, try it from local dhabas or street vendors.
- **Temple Kitchens**: A more authentic and spiritual experience can be found by enjoying aloo-puri as prasad.

The puris are often made from whole wheat flour, and the aloo sabzi is flavored with traditional spices, creating a comforting and satisfying meal. Served hot and fresh, aloo-puri is perfect for breakfast or lunch, offering a taste of Barsana’s rich culinary culture.`,
    },
    {
      heading: "5# - Rabri and Jalebi",
      image: "/images/jalebi.jpg",
      description: `
For those with a sweet tooth, **rabri and jalebi** are a match made in heaven. The thick, creamy rabri, made from condensed milk and flavored with cardamom and saffron, pairs beautifully with the crispy, syrup-soaked jalebis. This iconic duo is a festive favorite in Barsana.

### Highlights:
- **Cultural Connection**: These sweets are a staple during festivals and religious celebrations, often served as part of temple feasts.
- **Local Shops**: To enjoy the best rabri and jalebi, visit Barsana’s traditional sweet shops, where they are freshly prepared every day.

Rabri and jalebi are more than just desserts; they are a celebration of Barsana’s vibrant culture and hospitality. Whether enjoyed as a dessert or a mid-day snack, this combination is sure to leave a lasting impression.`,
    },
  ],
},




  

  //Nandgaon

  "places-to-visit-in-nandgaon": {
    title: "Place to visit in Nandgoan",
    sections: [
      {
        heading: "#1 - Nand Bhavan Temple: The Home of Nanda Maharaj",
        image: "/images/Nand-Bhawan.jpg",
        description: `
**Nand Bhavan Temple** is a sacred site dedicated to **Nanda Maharaj** (Krishna’s foster father) and **Yashoda** (his foster mother). It is situated in **Nandgaon**, Uttar Pradesh, and holds great religious and historical significance for devotees of **Lord Krishna**.

### Key Information About Nand Bhavan Temple:
1. **Deities**: The temple is primarily dedicated to **Nanda Maharaj** and **Yashoda**, the divine couple who raised Lord Krishna.
2. **Location**: The temple is located in **Nandgaon**, approximately 20-25 km from **Vrindavan** and **Mathura**, in the Braj region, where Lord Krishna spent his childhood.
3. **Significance**: Nand Bhavan celebrates the divine bond between **Krishna** and his foster parents, Nanda and Yashoda, and is a key site in Krishna’s childhood story.
4. **Festivals**: The temple hosts major festivals such as **Janmashtami**, **Diwali**, **Holi**, and **Nandotsav**, which are celebrated with great devotion.
5. **Timings**:
   - **Morning Darshan**: 5:00 AM - 12:00 PM
   - **Evening Darshan**: 4:00 PM - 9:00 PM
   - **Morning & Evening Aartis**: 5:30 AM and 7:00 PM (timings may vary during festivals).

### How to Reach:
- **By Road**: Nand Bhavan is accessible from **Mathura** and **Vrindavan** by road (about 20-25 km).
- **By Train**: The nearest railway station is **Mathura Junction**.
- **By Air**: The nearest airport is **Agra**, located about 70 km from Nandgaon, or **Delhi**, about 160 km away.

Nand Bhavan Temple is an important religious site for those following the life and teachings of **Lord Krishna**. With its deep historical roots and spiritual significance, it offers a peaceful and meaningful experience for devotees.
  `,
      },
      {
        heading: "#2 - Nandishvar Temple in Nandgaon: A Sacred Shiva Temple",
        image: "/images/Nandishwar.jpg",
        description: `
**Nandishvar Temple** in **Nandgaon**, Uttar Pradesh, is a revered temple dedicated to **Lord Shiva** and **Nandi**, his sacred bull. Located in the historic **Braj** region, this temple holds great significance both spiritually and culturally.

### Key Information About Nandishvar Temple:
1. **Deity**: The temple is dedicated to **Lord Shiva** and his sacred bull, **Nandi**, who is considered the divine gatekeeper and vehicle of Lord Shiva.
2. **Location**: Nandishvar Temple is located in **Nandgaon**, approximately 50 km from **Mathura** in the Braj region, a key area in the life of **Lord Krishna**.
3. **Religious Significance**: The temple is important for **Shiva** devotees, especially those who seek blessings from **Nandi**. It is also connected to the legend of **Lord Krishna** visiting this temple during his childhood.
4. **Cultural Importance**: The temple is a rare fusion of **Shiva** and **Krishna** worship, representing the spiritual heritage of the Braj region.
5. **Festivals**: Major festivals like **Mahashivaratri**, **Krishna Janmashtami**, and **Holi** are celebrated with devotion, attracting numerous pilgrims.
6. **Timings**:
   - **Morning Darshan**: 5:00 AM - 12:00 PM
   - **Evening Darshan**: 4:00 PM - 9:00 PM
   - During festivals, the temple may remain open longer, with special prayers and events.

### How to Reach:
- **By Road**: Nandishvar Temple is about 50 km from **Mathura**, accessible by taxi or local transport.
- **By Train**: The nearest railway station is **Mathura Junction**, from where you can travel by road to Nandgaon.
- **By Air**: The nearest airport is **Agra**, around 70 km away. Alternatively, **Delhi Airport** is about 160 km away.

Nandishvar Temple is a unique blend of Shiva and Krishna worship, offering a peaceful spiritual atmosphere for visitors. It holds an important place in the religious landscape of the **Braj** region and offers a rare glimpse into the spiritual connection between **Lord Shiva** and **Lord Krishna**.
  `,
      },
      {
        heading: "#3 - Pavan Sarovar: A Sacred Water Tank in Nandgaon",
        image: "/images/pavan-sarovar.jpg",
        description: `
**Pavan Sarovar** is a sacred water tank located in **Nandgaon**, Uttar Pradesh, part of the **Braj** region, known for its religious importance connected to **Lord Krishna** and **Lord Shiva**.

### Key Information About Pavan Sarovar:
1. **Location**:  Located in **Nandgaon**, about 50 km from **Mathura**, an important site in **Krishna**'s childhood.

2. **Religious Significance**:  
   - Believed to have been created by **Lord Krishna** using divine powers to bring **Ganges** water.
   - Associated with **Lord Shiva**’s visit, enhancing its sanctity.

3. **Spiritual Importance**:  
   - Water considered purifying; pilgrims bathe here to cleanse sins and seek **moksha**.
   - Part of **Tirth Yatra** (pilgrimage) in **Braj**.

4. **Krishna’s Connection**:  
   - Part of **Krishna Leela**, where Lord Krishna bathed and played with his friends.
   - Named after the **wind** (“Pavan”), believed to have been purified by Krishna.

5. **Timings**:  
   - Best Visiting Hours: **6:00 AM - 6:00 PM**.
   - Major festivals like **Krishna Janmashtami** and **Shivaratri** attract many pilgrims.

6. **Best Time to Visit**:  
   - **Winter months** (November to February) are ideal for a comfortable visit.
   - Festivals offer a festive and spiritual atmosphere.

### How to Reach:
- **By Road**: Accessible by road, 50 km from **Mathura**.
- **By Train**: **Mathura Junction** is the nearest station.
- **By Air**: Nearest airports: **Agra Airport** (70 km) or **Delhi Airport** (160 km).

### Nearby Attractions:
- **Nandgaon Temple**: Dedicated to **Nanda Maharaj**.
- **Radha Kund** and **Shyama Shyam Temple**: Major pilgrimage spots in **Vrindavan** and **Mathura**.

**Pavan Sarovar** is a spiritually significant site, where pilgrims come to bathe in holy waters believed to purify the soul. Its link to **Krishna** and **Shiva** makes it a unique destination, with the best experience during festivals and the cooler months.
  `,
      },
      {
        heading:
          "#4 - Charan Pahari: Sacred Footprints of Lord Krishna in Nandgaon",
        image: "/images/charan-pahari.jpg",
        description: `
**Charan Pahari** is a sacred site in **Nandgaon**, Uttar Pradesh, where Lord Krishna's footprints are believed to have been imprinted on the rocks. This site holds great religious and spiritual significance for devotees.

### Key Information About Charan Pahari:
1. **Location**:  Situated near **Nandgaon**, 50 km from **Mathura**, a key site in **Braj** where Lord Krishna spent his childhood.

2. **Religious Significance**:  
   - The name “Charan Pahari” refers to the place where Krishna’s divine footprints are found on the rocks.
   - Local legends say Krishna visited here during his childhood, leaving behind his sacred marks.

3. **Pilgrimage Importance**:  
   - Devotees visit to pay respects to Krishna’s footprints, meditate, and reflect on his divine leelas.
   - An important stop in the **Braj** pilgrimage.

4. **Spiritual Significance**:  
   - Visitors believe meditating at Charan Pahari brings spiritual benefits like peace, prosperity, and **moksha** (liberation).

5. **Timings**:  
   - Best Visiting Hours: **6:00 AM - 6:00 PM**. Early mornings or late afternoons are ideal for spiritual practices.
   - Major festivals like **Krishna Janmashtami** and **Holi** attract many pilgrims.

6. **Best Time to Visit**:  
   - **Winter months** (November to February) offer the best weather for visits.
   - Festivals like **Janmashtami** and **Holi** bring a festive atmosphere.

### How to Reach:
- **By Road**: 50 km from **Mathura**; accessible by taxi or bus.
- **By Train**: **Mathura Junction** is the nearest station; take a taxi or rickshaw to **Nandgaon**.
- **By Air**: Nearest airports are **Agra Airport** (70 km) or **Delhi Airport** (160 km).

### Nearby Attractions:
- **Nandgaon Temple**: Dedicated to **Nanda Maharaj**, Krishna's foster father.
- **Radha Kund** and **Shyama Shyam Temple**: Important pilgrimage sites in the region.
- **Vrindavan**: Another key Krishna-related site nearby.

**Charan Pahari** is a deeply spiritual site where Krishna’s footprints are revered. It holds immense significance for pilgrims visiting the **Braj** region. The best time to visit is during the cooler months and major festivals, making it an essential stop for spiritual reflection and blessings.
  `,
      },
    ],
  },

  "festivals-in-nandgaon": {
  title: "Festivals in Nandgaon",
  sections: [
    {
      heading: "1# - Lathmar Holi",
      image: "/images/laath-holi.jpg",
      description: `
**Lathmar Holi** is Nandgaon’s most iconic festival and a unique celebration of colors and tradition. Rooted in the playful legend of Lord Krishna and Radha, it symbolizes love, humor, and cultural richness. During this festival, women from Barsana “playfully” beat men from Nandgaon with sticks (*lathis*), while the men shield themselves with large shields.

### Highlights:
- The event takes place at **Nand Bhawan**, the main temple in Nandgaon, where thousands gather to witness and participate in this vibrant tradition.
- Men and women dress in traditional attire, adding a colorful spectacle to the festivities.
- Devotees chant **Krishna bhajans** and throw **gulal** (colored powder) in the air, creating an electric and joyous atmosphere.

**Tips for Visitors**: Arrive early to find a good spot and wear comfortable clothing. Be prepared to get drenched in colors and immerse yourself in the playful spirit of Lathmar Holi.`,
    },
    {
      heading: "2# - Krishna Janmashtami",
      image: "/images/janam.jpeg",
      description: `
**Krishna Janmashtami** is celebrated with grandeur in Nandgaon, marking the birth of Lord Krishna. The village, believed to be Krishna’s childhood home, transforms into a hub of devotion, festivity, and spiritual energy.

### Celebration Highlights:
- Special **midnight prayers** and **kirtans** are held at the **Nand Bhawan Temple**, believed to be where Krishna grew up.
- Devotees fast all day and break their fast after the midnight **abhishek** (ceremonial bathing of Krishna’s idol).
- The village comes alive with **Krishna Leela** performances, depicting stories from Krishna’s life.

Janmashtami in Nandgaon offers visitors a mix of spirituality and cultural richness, making it a must-visit during this sacred festival.`,
    },
    {
      heading: "3# - Annakut Festival",
      image: "/images/goverdhanpooja.jpeg",
      description: `
The **Annakut Festival**, also known as **Govardhan Puja**, is a grand celebration in Nandgaon, held a day after Diwali. It commemorates Krishna’s lifting of the **Govardhan Hill** to protect the villagers from the wrath of Lord Indra.

### Celebration Highlights:
- A **mountain of food** (*Annakut*) is prepared and offered to Lord Krishna at the **Govardhan Temple**.
- Villagers sing devotional songs and perform **aarti**, expressing gratitude to Krishna for his divine protection.
- The festival fosters a strong sense of community, with villagers sharing the feast and celebrating together.

The Annakut Festival in Nandgaon is a heartwarming experience that reflects devotion, gratitude, and Krishna’s divine presence in daily life.`,
    },
    {
      heading: "4# - Radha Ashtami",
      image: "/images/radha-ashtmi.jpg",
      description: `
**Radha Ashtami** is a significant festival in Nandgaon, celebrated to honor the birth of **Radha Rani**, Lord Krishna’s beloved consort. As Nandgaon is closely associated with Radha and Krishna’s divine love, the festival holds special importance here.

### Celebration Highlights:
- The **Radha-Krishna temples** in Nandgaon are beautifully decorated with flowers and lights.
- Special **bhajans** and **kirtans** fill the air with devotion, as devotees celebrate Radha’s virtues and her divine love for Krishna.
- Traditional **raas leela performances** showcase the eternal bond between Radha and Krishna.

Radha Ashtami in Nandgaon is a deeply spiritual occasion, offering visitors an opportunity to experience the essence of divine love and devotion.`,
    },
  ],
},

"things-to-do-in-nandgaon": {
  title: "Things to Do in Nandgaon",
  sections: [
    {
      heading: "1# - Visit Nand Bhawan Temple",
      image: "/images/Nand-Bhawan.jpg",
      description: `
The **Nand Bhawan Temple** is the most iconic landmark in Nandgaon. Believed to be the home of **Nand Baba**, Lord Krishna’s foster father, the temple is steeped in divine significance. Its architecture reflects ancient traditions, and the vibrant murals depicting Krishna’s childhood stories bring the place to life.

### Highlights:
- Admire the intricate carvings and the serene ambiance of the temple.
- Participate in the daily **aarti** and devotional singing, which fills the temple with spiritual energy.
- Explore the historical and cultural significance of the temple, which is central to Nandgaon’s identity.

A visit to Nand Bhawan Temple is not just a spiritual journey but also a cultural exploration of Krishna’s life.`,
    },
    {
      heading: "2# - Experience Lathmar Holi",
      image: "/images/laath-holi.jpg",
      description: `
Nandgaon is famous for its unique **Lathmar Holi**, a traditional celebration where women “playfully” beat men with sticks (*lathis*), recreating an episode from Krishna’s life. This vibrant festival is celebrated with colors, songs, and laughter, attracting visitors from all over the world.

### Highlights:
- Witness the playful traditions of Holi at **Nand Bhawan**.
- Enjoy the festive spirit as locals and tourists join together in the celebration.
- Participate in the unique rituals and soak in the cultural significance of this one-of-a-kind Holi.

Visiting Nandgaon during Holi is an unforgettable experience, filled with joy and vivid colors.`,
    },
    {
      heading: "3# - Explore the Pan Sarovar",
      image: "/images/pavan-sarovar.jpg",
      description: `
The **Pan Sarovar** is one of the sacred ponds in Braj Bhoomi, associated with Lord Krishna and Nand Baba. Surrounded by scenic beauty and tranquil vibes, this spot is perfect for relaxation and reflection.

### Highlights:
- Take a stroll along the serene banks of the pond, surrounded by lush greenery.
- Visit the small temples and shrines near the pond dedicated to Krishna and his family.
- Experience the spiritual calm that attracts pilgrims and seekers from all over.

The Pan Sarovar is a must-visit for those looking to connect with Nandgaon’s spiritual and natural beauty.`,
    },
    {
      heading: "4# - Attend Krishna Janmashtami Celebrations",
      image: "/images/janam.jpeg",
      description: `
Nandgaon comes alive during **Krishna Janmashtami**, the celebration of Lord Krishna’s birth. The village’s connection to Krishna’s childhood makes it a special place to experience this festival.

### Highlights:
- Witness the midnight **abhishek** (ceremonial bathing) of Krishna’s idol at **Nand Bhawan Temple**.
- Enjoy **Krishna Leela** performances that depict episodes from Krishna’s life.
- Participate in devotional singing and dancing with locals.

Janmashtami in Nandgaon is a deeply immersive experience that showcases the devotion and joy of the villagers.`,
    },
  ],
},
"how-to-reach-nandgaon": {
  title: "How to Reach Nandgaon",
  sections: [
    {
      heading: "By Air -",
      image: "/images/plane.jpg",
      description: `
The nearest airport to Nandgaon is **Agra Airport**, located about **75 km** away. For international travelers, the most convenient option is **Indira Gandhi International Airport (Delhi)**, which is approximately **145 km** from Nandgaon.

### Key Airports:
- **Agra Airport**: Limited domestic connectivity, but ideal for travelers coming from nearby regions. From here, you can hire a taxi or book private transport to reach Nandgaon.
- **Indira Gandhi International Airport (Delhi)**: The primary hub for international and domestic flights, offering better connectivity. From Delhi, it’s a **3-4 hour** drive to Nandgaon via the **Yamuna Expressway**.

### Recommendations:
- Plan your onward journey in advance as both airports require additional road travel to reach Nandgaon.
- Private taxis are highly recommended for convenience and time efficiency.`,
    },
    {
      heading: "By Train -",
      image: "/images/train.jpg",
      description: `
Nandgaon doesn’t have its own railway station, but the nearest major station is **Mathura Junction**, located approximately **32 km** away. Mathura Junction is a major railway hub with excellent connectivity to cities like Delhi, Jaipur, and Agra.

### Key Details:
- **Mathura Junction** is well-connected by regular trains from all major cities like **Delhi**, **Mumbai**, and **Kolkata**.
- From Mathura, you can hire a taxi or take a local bus to reach Nandgaon, which takes around **45 minutes** by road.

### Tips for Travelers:
- Book your train tickets in advance, especially during peak travel seasons such as **Holi** or **Krishna Janmashtami**, when the region sees a surge in visitors.
- Arrange for transport from Mathura Junction to Nandgaon before your journey to ensure a smooth transition.`,
    },
    {
      heading: "By Road -",
      image: "/images/road.jpg",
      description: `
Nandgaon is well-connected to nearby cities through a network of highways, making road travel the most convenient option.

### Key Routes:
- **From Delhi**: The distance to Nandgaon is around **145 km**, and the journey takes **3-4 hours** via the **Yamuna Expressway**.
- **From Agra**: It’s approximately **75 km**, taking about **1.5-2 hours** by road.
- **From Mathura**: Nandgaon is just **32 km** away, making it a quick **45-minute** drive.

### Transport Options:
- **Private Cars or Taxis**: These are the most flexible and comfortable options, especially for families or groups.
- **Buses**: Local buses operate between Mathura and Nandgaon. However, direct buses from Delhi or Agra are less frequent.

### Recommendations:
- Opt for private vehicles if you’re planning to visit nearby attractions like **Barsana** or **Vrindavan** as part of your trip.
- The roads leading to Nandgaon are generally in good condition, making self-driving a viable option for travelers.`,
    },
  ],
},

"foods-of-nandgaon": {
  title: "Foods of Nandgaon",
  sections: [
    {
      heading: "Traditional Delicacies -",
      image: "/images/peda.jpeg",
      description: `
Nandgaon, deeply rooted in its spiritual and cultural heritage, offers a culinary experience that resonates with its religious and rural charm. The food here is simple yet flavorful, prepared with traditional techniques and locally sourced ingredients.

### Must-Try Dishes:
- **Makhan Mishri**: A favorite offering to Lord Krishna, this dish is made with freshly churned butter and sugar crystals, symbolizing the playful bond between Krishna and his devotees.
- **Khichdi**: A humble and wholesome meal, often prepared during festivals or as a temple offering. The combination of rice, lentils, and spices makes it both nutritious and satisfying.
- **Kachori-Sabzi**: A crispy, deep-fried bread served with spicy potato curry, this dish is a popular breakfast option among locals and visitors alike.

### Unique Offerings:
- **Thandai**: A refreshing and aromatic drink made with milk, almonds, and saffron. It is particularly popular during festivals like **Holi**, adding a cooling touch to the vibrant celebrations.
- **Peda**: A sweet delicacy made from condensed milk and sugar, often served as a prasad (religious offering). Nandgaon’s peda is known for its rich and creamy flavor.`,
    },
    {
      heading: "Festive Feasts -",
      image: "/images/gujiya.jpg",
      description: `
During festivals like **Holi** and **Janmashtami**, the culinary traditions of Nandgaon truly shine. The temples and homes prepare elaborate feasts as part of the celebrations.

### Highlights:
- **Chhappan Bhog**: A grand offering of 56 dishes made for Lord Krishna, including sweets, savories, and traditional breads.
- **Gujiya**: A deep-fried dumpling filled with sweetened khoya and dry fruits, especially popular during Holi.

The food culture of Nandgaon is a blend of devotion and simplicity, offering visitors a chance to connect with the divine through its flavors. Whether you’re exploring the village or attending a festival, the culinary delights here are a must-try.`,
    },
  ],
},




  //Govardhan
  "places-to-visit-in-govardhan": {
    title: "Place to visit in Govardhan",
    sections: [
      {
        heading: "#1 - Daan Ghati Mandir: A Sacred Shrine in Govardhan",
        image: "/images/Dan-ghati.jpg",
        description: `
**Daan Ghati Mandir** is a revered Hindu temple located in **Govardhan**, known for its religious significance, divine ambiance, and beautiful architecture. The name **Daan Ghati** translates to ‘**donation valley**’, symbolizing the sacred offerings made here. The temple is dedicated to a deity manifested in rock form, which is believed to degrade into soil by a few millimeters every year, though this is not scientifically proven.

### Key Information About Daan Ghati Mandir:
1. **Location**:  
   Situated between **Mathura** and **Kamvana**, this temple is an important starting point for the **Parikrama Marg** (the path that devotees take to circle Govardhan Hill barefoot).

2. **Religious Significance**:  
   - It is one of the two major temples in **Govardhan** and a vital stop for pilgrims before they begin the Govardhan Hill Parikrama.
   - Devotees offer **milk**, **sweets**, and **milk products** to the deity, with special offerings during festivals.

3. **Architecture**:  
   - The temple is built in classic **Hindu architectural style** and has undergone several renovations over time, but the current structure is highly admired by all.

4. **Festivals and Rituals**:  
   - **Chhappan Bhog** (56 varieties of offerings) is presented during festivals, attracting a large number of devotees.

5. **Tourism and Pilgrimage Importance**:  
   - Over the years, **Daan Ghati Mandir** has become a major tourist attraction in **Govardhan**, with numerous pilgrims visiting each year as part of their spiritual journey.

**Daan Ghati Mandir** is an iconic temple in Govardhan, renowned for its religious significance and beautiful architecture. It is a must-visit for pilgrims, offering a place for worship, reflection, and spiritual offerings before embarking on the sacred Parikrama of Govardhan Hill.
  `,
      },
      {
        heading: "#2 - Radha Kund & Shyam Kund: Sacred Ponds of Braj",
        image: "/images/Radha kund.jpg",
        description: `
**Radha Kund & Shyam Kund** are two revered ponds located in **Arita**, 3 miles northeast of **Govardhan Hill**. These sacred water bodies are associated with the divine pastimes of **Lord Krishna** and **Radha** and are considered the most sacred places for **Vaishnavas** in the **Braj** region.

### Key Significance:
1. **Legendary Origins**:  
   According to legend, after Krishna killed the demon **Arishta**, he created **Radha Kund** by striking his heel on the ground and summoning waters from holy places to purify the site. Radha also created **Shyam Kund** with her bracelet, but it could not hold water until Krishna blessed it, making both kunds sanctified and pure.

2. **Spiritual Importance**:  
   The water in these kunds is considered the purest in the world, and pilgrims come from far and wide to bathe in these sacred waters, believing it cleanses them from sins and brings spiritual blessings. Krishna promised Radha that he would bathe in **Radha Kund** every day, making it particularly special for devotees.

3. **Pilgrimage Significance**:  
   The kunds are central to the pilgrimage circuit of **Braj**, especially for those seeking salvation (moksha). Devotees come to seek blessings, meditate, and reflect on the divine leelas of **Lord Krishna** and **Radha**.

4. **Visiting Timings**:  
   - **6:00 AM to 7:00 PM**, with the best time for visits being early mornings or late afternoons when the atmosphere is more peaceful and serene.

### Holy Vrindavan Tour Experience:
   - Visit **Radha Kund** and **Shyam Kund** with **Holy Vrindavan Tour** and experience the divine atmosphere of the place, connecting with **Krishna** in the most spiritual form. This sacred region is not just a tourist destination but a spiritual journey that deepens your devotion to Lord Krishna.
  `,
      },
      {
        heading: "#3 - Kusum Sarovar: Sacred Water Body in Vrindavan",
        image: "/images/Kusum-sarovar.jpg",
        description: `
**Kusum Sarovar** is a sacred lake in **Vrindavan**, linked to the divine love story of **Radha** and **Krishna**. It is believed that **Radha** would gather flowers here for **Krishna**, and they spent many cherished moments at this site, making it a place of great spiritual significance.

### Key Details:
1. **Location**: Situated near **Radhakund** and the **Yamuna River**, Kusum Sarovar offers a serene atmosphere for meditation and reflection.
   
2. **Temples**: The area features temples dedicated to **Radha** and **Krishna**, and is a popular stop for pilgrims in **Vrindavan**.

3. **Best Time to Visit**:  
   - **Morning (4:00 AM - 7:00 AM)**: Quiet and peaceful.
   - **Evening (5:00 PM - 7:00 PM)**: Ideal for experiencing **aarti** rituals.


4. **Festivals**: It is especially vibrant during **Holi**, **Janmashtami**, and **Radhashtami**, when large crowds gather for devotional activities.
### Key Attractions:
1. **The Ghats**:  Tranquil spots for meditation and reflection along the water's edge.
   
2. **Architectural Beauty**:  Ornate temples and ancient structures enhance the spiritual atmosphere.
   
3. **Nearby Pilgrimage Sites**:  Kusum Sarovar is close to **Radhakund**, making it a key stop for devotees visiting **Vrindavan**.


### Tips for Visitors:
- **Dress Modestly** and respect local traditions.
- **Stay Hydrated**, as the weather can be hot.

Kusum Sarovar is a must-visit for devotees seeking peace and a deeper connection with **Lord Krishna**.
  `,
      },
      {
        heading: "#4 - Govardhan Parikrama: Sacred Pilgrimage in Braj",
        image: "/images/Govardhan-parikrma.jpg",
        description: `
**Govardhan Parikrama** is a revered pilgrimage in the Braj region, where devotees walk around **Govardhan Hill**, a sacred site associated with the divine leela (pastime) of **Lord Krishna** lifting the hill to protect the villagers from **Indra**’s wrath. The parikrama is an essential spiritual practice for Krishna devotees, believed to bring divine blessings, spiritual growth, and the removal of sins.

### Historical and Spiritual Significance:
- **The Legend of Govardhan Hill**: According to Hindu mythology, Lord Krishna lifted **Govardhan Hill** on his little finger to shield the Braj region from torrential rains sent by **Indra**. This act of divine protection is commemorated through the **Govardhan Parikrama**.
- **Spiritual Benefits**: Completing the parikrama is considered a powerful act of devotion. It is said to wash away sins, bring blessings, and aid in spiritual progress.

### Route and Key Sites:
- **Radha Kund & Shyama Kund**: Sacred lakes where Krishna and Radha are believed to have performed many divine pastimes.
- **Mansi Ganga**: A holy water tank where Krishna is believed to have bathed.
- **Gokul**: The village where Krishna spent his childhood with his foster parents, **Nanda** and **Yashoda**.
- The parikrama covers **21 kilometers (13 miles)**, passing through these sacred sites and offering pilgrims an opportunity to reflect on the divine pastimes of Krishna.

### Best Time to Do Govardhan Parikrama:
- **Early Morning (4:00 AM - 6:00 AM)**: The parikrama is most peaceful during this time, with cooler temperatures and fewer crowds.
- **Late Afternoon (4:00 PM - 6:00 PM)**: The sunset adds a beautiful backdrop to the sacred walk, making it another ideal time for the parikrama.
- **Festivals**: The parikrama is especially auspicious during **Govardhan Puja**, celebrated the day after **Diwali**. During this time, many devotees undertake the parikrama as part of the festivities.

### Important Etiquette:
- **Barefoot Walking**: Devotees generally walk barefoot as a sign of reverence.
- **Modesty**: Dressing modestly is important as it is a sacred pilgrimage.
- **Hydration**: The walk can be long, so carrying water is advisable, especially in warmer months.

Govardhan Parikrama is not just a physical journey but a deeply spiritual experience, offering an opportunity for devotees to connect with **Lord Krishna** and the holy land of Braj. Whether visiting for the first time or making it a regular practice, this pilgrimage is a memorable experience that brings spiritual fulfillment.
  `,
      },
      {
        heading: "#5 - Punchhri Ka Lotha Temple: A Sacred Place of Krishna",
        image: "/images/Punchri-ka-lota.jpg",
        description: `
**Punchhri Ka Lotha Temple**, located in **Mathura**, Uttar Pradesh, is a significant pilgrimage site associated with **Lord Krishna**. This temple is linked to a unique legend involving a special pot (lotha) used by Krishna during his childhood in **Vrindavan**. The temple offers a peaceful and spiritual atmosphere, making it a hidden gem for devotees and visitors alike.

### Historical and Mythological Significance:
- **The Name**: "Punchhri Ka Lotha" translates to “the pot of five,” symbolizing Lord Krishna’s divine pastimes. The pot was associated with water from the **Yamuna River**, a symbol of purity.
- **Krishna’s Connection**: The temple commemorates Krishna’s early life in Braj, where he used the lotha in divine rituals and events, making it a revered site for devotees.

### Visiting Information:
- **Timings**: The temple is open from **6:00 AM to 7:30 AM** for morning darshan, and **5:30 PM to 7:00 PM** for evening aarti. The temple is busier during **festivals** like **Janmashtami** and **Radhashtami**, so the timings may vary.
- **Best Time to Visit**: Early morning and evening are ideal for a peaceful visit, while festivals offer a vibrant, spiritual atmosphere.

### Key Attractions:
- **The Lotha**: While the pot is not physically displayed, the spiritual essence of the pot’s significance is preserved in the temple.
- **Krishna’s Idol**: The central deity of worship at the temple is **Lord Krishna**, with a serene ambiance for prayer and reflection.
- **Devotional Activities**: **Bhajans** and **kirtans** are held during aarti times, creating a devotional atmosphere.


### Visitor Tips:
- **Dress Modestly**: Respect the sacred nature of the temple by dressing appropriately.
- **Stay Hydrated**: The weather can get hot, so carry water with you.
- **Avoid Peak Hours**: For a peaceful visit, avoid peak festival times. However, attending festivals like Janmashtami can be a vibrant experience.


The **Punchhri Ka Lotha Temple** offers a serene space for spiritual reflection, with deep historical and mythological ties to Lord Krishna’s childhood. Whether seeking a peaceful retreat or exploring the spiritual heritage of **Braj**, this temple is a must-visit.
  `,
      },
      {
        heading: "#6 - Mukharbind Temple: A Sacred Shrine of Lord Krishna",
        image: "/images/Mukharbind temple.jpg",
        description: `
**Mukharbind Temple**, located in **Vrindavan**, Uttar Pradesh, is a revered spiritual site dedicated to **Lord Krishna**. The temple holds deep significance in the **Braj** region, known for its connection to the divine pastimes of Krishna. The name "Mukharbind" is derived from the word "Mukharbind" meaning the "sacred mouth" of Krishna, and the temple is believed to be the place where Krishna’s divine voice or words were heard by his devotees. It is an important pilgrimage spot for those seeking spiritual enlightenment.

### Historical and Mythological Significance:
- **The Name and Origin**: The name Mukharbind is associated with the belief that this is the place where Krishna once spoke to his devotees. Devotees believe that the temple is built on the site where Krishna’s divine words were heard by the people of **Vrindavan**.
- **Spiritual Connection**: Mukharbind Temple is an important spot in the **Braj** region for devotees who seek a deeper connection with Krishna. The temple holds a unique significance for its connection to Krishna's voice and divine speech.

### Visiting Information:
- **Timings**: Mukharbind Temple is open from **5:00 AM to 7:00 AM** for morning darshan and **5:00 PM to 7:30 PM** for evening aarti.
- **Best Time to Visit**: Early morning and evening aarti provide the most serene and spiritually enriching experience.

### Key Attractions:
- **The Temple Structure**: The temple is built in traditional Hindu architecture with intricate carvings and designs. Its serene atmosphere offers peace and a spiritual connection with Krishna.
- **Divine Ambience**: The temple is known for its spiritual vibrations and is considered to be a place where the devotee can feel closer to Krishna's divine presence.
- **Krishna’s Idol**: The temple houses a beautiful idol of **Lord Krishna**, where devotees gather for prayers and spiritual rituals.


### Visitor Tips:
- **Dress Modestly**: As with all sacred places, it is important to dress modestly when visiting the temple.
- **Stay Hydrated**: The weather in Vrindavan can be hot, especially in the summer months, so carry water.
- **Respect Local Customs**: Participate in the devotional practices like **bhajans** and **kirtans** to enrich your spiritual experience.

Mukharbind Temple offers a unique spiritual experience for Krishna devotees. Whether you are a pilgrim seeking divine blessings or simply exploring the spiritual heritage of **Vrindavan**, this temple provides a peaceful retreat for reflection and devotion.
  `,
      },
      {
        heading: "#7 - Mansi Ganga Kund: The Sacred Tank of Govardhan",
        image: "/images/Mansi-ganga-kund.jpg",
        description: `
**Mansi Ganga Kund**, located at the base of **Govardhan Hill** near **Vrindavan**, is a sacred tank created by **Lord Krishna**. It holds great spiritual significance as it is believed that Krishna created this tank to purify the residents of Vrindavan after lifting the Govardhan Hill.
### Overview of Mansi Ganga Kund:
- **The Name**: The name "Mansi Ganga" comes from the combination of "Mansi" meaning "mind" and "Ganga" referring to the holy river Ganga. According to legend, Krishna created this tank with his mind as a form of the sacred Ganga to cleanse the residents of **Vrindavan**.
- **Historical Significance**: The creation of Mansi Ganga is associated with the legend of **Krishna lifting the Govardhan Hill** to protect the people of Vrindavan. After the lifting of the hill, Krishna created this tank for the villagers to bathe in and purify themselves, making it a symbol of divine protection and grace.


### Key Points:
- **Purification**: The waters of Mansi Ganga are believed to have purifying properties, akin to the **Ganges River** itself. Pilgrims believe that a dip in the waters cleanses them of sins and brings peace of mind.
- **Connection to Krishna**: This tank is intricately connected to Lord Krishna’s life and is seen as a manifestation of his divine powers. It is one of the prominent sites for Krishna devotees.
- **Rituals and Pujas**: Devotees visit Mansi Ganga for performing rituals, offering prayers, taking a holy dip, and participating in the evening **Aarti** ceremonies.


### Nearby Attractions:
- **Mansi Ganga Temple**: Dedicated to **Radha Krishna**.
- **Radha Kund & Shyam Kund**: Other nearby holy ponds.
- **Govardhan Hill**: Central to the pilgrimage.

### Best Time to Visit:
- **Timing**: The temple is open from **6:00 AM – 12:00 PM** and **3:00 PM – 7:00 PM**. The best time to visit is during festivals like **Govardhan Puja** and **Kartika Purnima**.

### Festivals:
- **Govardhan Puja**: Celebrating Krishna’s act of lifting the hill.
- **Kartika Purnima**: A spiritually significant day.

### Tips for Visitors:
- **Dress Modestly** and respect local customs.
- **Avoid Polluting the Water** to maintain its sanctity.

Mansi Ganga is an essential site for pilgrims visiting **Govardhan Hill**, offering a place for spiritual purification and devotion.
`,
      },
    ],
  },


"festivals-in-govardhan": {
  title: "Festivals in Govardhan",
  sections: [
    {
      heading: "1# - Govardhan Puja",
      image: "/images/goverdhanpooja.jpeg",
      description: `
Govardhan Puja is the most significant festival celebrated in Govardhan, commemorating the divine act of Lord Krishna lifting the Govardhan Hill to protect the villagers from torrential rains caused by Lord Indra's wrath.

### Celebration Highlights:
- The festival involves the creation of a massive **Annakut (Mountain of Food)**, symbolizing the Govardhan Hill, made of sweets, rice, and other dishes as offerings to Lord Krishna.
- Devotees perform **Parikrama (circumambulation)** of the Govardhan Hill, walking barefoot around the sacred site while chanting prayers.
- Temples like **Sri Giriraj Ji Temple** host special rituals and kirtans (devotional songs) to mark the occasion.

### Spiritual Significance:
Govardhan Puja signifies Krishna's message of devotion over arrogance and the importance of protecting nature and community well-being.`,
    },
    {
      heading: "2# - Janmashtami",
      image: "/images/janam.jpeg",
      description: `
**Janmashtami**, the birth anniversary of Lord Krishna, is celebrated with great fervor in Govardhan. Devotees gather to honor Krishna’s life and divine acts.

### Celebration Highlights:
- Temples are adorned with lights and flowers, hosting midnight prayers and **Krishna Leela** enactments.
- Traditional delicacies like **Makhan Mishri** and **Chhappan Bhog** are offered to the deity and shared among devotees.
- Devotees perform Govardhan Parikrama during the day to seek Krishna’s blessings.`,
    },
    {
      heading: "3# - Holi",
      image: "/images/holi.jpeg",
      description: `
Holi in Govardhan is a joyful celebration of colors and devotion. The festival reflects the playful and vibrant spirit of Krishna.

### Celebration Highlights:
- **Lathmar Holi**: Inspired by Krishna and Radha's playful interactions, women playfully "beat" men with sticks as men shield themselves.
- **Phoolon Ki Holi**: Temples like Radha Kund host Holi celebrations with flowers instead of colors, creating a mesmerizing and fragrant experience.

Holi in Govardhan is not just a festival of colors but an embodiment of love, unity, and spirituality.`,
    },
  ]},

 "things-to-do-in-govardhan": {
  title: "Things to Do in Govardhan",
  sections: [
    {
  heading: "1# - Visit Temples in Govardhan",
  image: "/images/govardhan.jpeg",
  description: `
Govardhan is home to numerous **ancient temples** that are steeped in history and spirituality. Each temple tells a unique story of **Lord Krishna’s divine pastimes** and offers a serene environment for devotion and meditation. The **Daan Ghati Temple**, **Mukharbind Temple**, and **Haridev Temple** are some of the most popular spots where visitors can seek blessings. Many temples are adorned with intricate carvings, beautiful idols, and vibrant rituals. Attending the **Aarti ceremonies**, where devotees sing hymns and offer prayers, is a must-experience activity. These temples provide a glimpse into the rich cultural and spiritual heritage of Govardhan.`,
},
{
  heading: "2# - Explore Govardhan Hills",
  image: "/images/govardhan-hill.jpeg",
  description: `
The **Govardhan Hills**, a revered site in Hindu mythology, are believed to have been lifted by **Lord Krishna** on his little finger to protect the villagers from torrential rains. The hills are dotted with serene spots like **Radha Kund**, **Shyam Kund**, and **Kusum Sarovar**, making them ideal for exploration. Walking around the hills lets visitors soak in their tranquil beauty, while the stories of Krishna’s divine acts add a spiritual charm. Many tourists enjoy capturing the breathtaking views of the countryside and relishing the natural beauty intertwined with divine energy.`,
},
{
  heading: "3# - Discover the Local Markets",
  image: "/images/local-market.jpeg",
  description: `
Govardhan’s **local markets** offer a vibrant shopping experience, brimming with colorful handicrafts, religious artifacts, and traditional souvenirs. Visitors can purchase **handmade jewelry**, **Krishna idols**, **incense sticks**, and more. These bustling markets are also great places to try **local snacks and sweets**, including the famous **Mathura Peda**. Exploring the streets provides an opportunity to connect with the local culture, interact with artisans, and appreciate the craftsmanship that reflects the region’s heritage.`,
},
{
  heading: "4# - Trekking and Natural Walks",
  image: "/images/trek.jpeg",
  description: `
For nature lovers, Govardhan offers opportunities for **trekking and peaceful walks** amidst lush greenery and natural beauty. Trails around **Govardhan Hill** are perfect for those looking to blend **spiritual exploration with physical activity**. As you walk, you can hear the **chirping of birds**, feel the cool breeze, and enjoy the serene surroundings. These treks and walks also allow visitors to discover hidden gems like **water bodies**, **flower gardens**, and small shrines scattered across the landscape. The experience is both rejuvenating and spiritually enriching, offering a break from the hustle of everyday life.`,
},

  ],
},
"how-to-reach-govardhan": {
  title: "How to Reach Govardhan",
  sections: [
    {
      heading: "By Air -",
      image: "/images/plane.jpg",
      description: `
The nearest airport to Govardhan is **Agra Airport**, located approximately **80 km** away. For international travelers, **Indira Gandhi International Airport (Delhi)** is a more viable option, situated around **160 km** from Govardhan.

### Key Airports:
- **Agra Airport**: Offers limited domestic flights, making it suitable for travelers from nearby cities. From Agra, you can hire a taxi or use local transport to reach Govardhan.
- **Indira Gandhi International Airport (Delhi)**: The primary hub for international and domestic travelers, offering better connectivity. The journey from Delhi to Govardhan takes **3-4 hours** by road.

### Recommendations:
- Book a private taxi or arrange for transport in advance from either airport for a seamless journey.
- Air travel is recommended for visitors coming from distant locations, combined with a road trip to reach Govardhan.`,
    },
    {
      heading: "By Train -",
      image: "/images/train.jpg",
      description: `
Govardhan does not have its own railway station, but the nearest major station is **Mathura Junction**, located approximately **25 km** away. Mathura Junction is well-connected to major cities across India.

### Key Details:
- **Mathura Junction** serves as the primary railhead, with trains arriving from cities like **Delhi**, **Mumbai**, **Jaipur**, and **Varanasi**.
- From Mathura, you can hire a taxi, take an auto-rickshaw, or board a local bus to reach Govardhan. The road journey takes about **30-40 minutes**.

### Tips for Travelers:
- Reserve train tickets well in advance during festivals like **Govardhan Puja** or **Janmashtami**, as the region experiences a high influx of devotees.
- Pre-book onward transportation from Mathura to Govardhan for a hassle-free trip.`,
    },
    {
      heading: "By Road -",
      image: "/images/road.jpg",
      description: `
Govardhan is easily accessible by road, making it a popular choice for travelers from nearby cities.

### Key Routes:
- **From Delhi**: Govardhan is about **160 km** away, with the journey taking **3-4 hours** via the **Yamuna Expressway** and connecting highways.
- **From Agra**: The distance is approximately **80 km**, taking around **1.5-2 hours**.
- **From Mathura**: Govardhan is just **25 km** away, making it a short **30-40 minute** drive.

### Transport Options:
- **Private Cars or Taxis**: Highly recommended for comfort and flexibility, especially for families or groups.
- **Buses**: Local and government buses operate from Mathura to Govardhan. Direct buses from Delhi or Agra are less frequent but available.

### Recommendations:
- Opt for private vehicles if you plan to explore nearby attractions like **Barsana**, **Vrindavan**, or **Nandgaon**.
- The roads leading to Govardhan are generally well-maintained, ensuring a smooth travel experience.`,
    },
  ],
},

"foods-of-govardhan": {
  title: "Foods of Govardhan",
  sections: [
    {
      heading: "1# - Chappan Bhog (56 Offerings)",
      image: "/images/besan.jpg",
      description: `
One of the most iconic food traditions in Govardhan is the **Chappan Bhog**, a lavish offering of **56 different delicacies** made for Lord Krishna. These dishes range from sweets like **laddoos** and **pedas** to savory items such as **kadhi**, **puris**, and spiced lentils.

### Significance:
- This tradition originates from the tale of Lord Krishna lifting the Govardhan Hill to protect the villagers from torrential rains.
- Devotees prepare these offerings as an act of gratitude and devotion.

### Where to Try:
- Temples like the **Govardhan Nath Ji Mandir** or local eateries around Govardhan Hill serve varieties of these prasad-like dishes.`,
    },
//     {
//       heading: "2# - Makhan Mishri (Butter and Sugar Crystals)",
//       image: "/images/makhan-mishri.jpg",
//       description: `
// A beloved treat associated with Lord Krishna, **Makhan Mishri** is a simple yet divine preparation made from fresh butter and sugar crystals.

// ### Why It's Special:
// - This dish reflects the childhood stories of Krishna, famously known as *Makhan Chor* (Butter Thief).
// - It's not just a food item but a symbol of divine love and simplicity.

// ### Where to Try:
// - Most temples and local sweet shops around Govardhan offer this delicacy.`,
//     },
    {
      heading: "2# - Kachori with Aloo Sabzi",
      image: "/images/kachori.jpeg",
      description: `
**Kachori with Aloo Sabzi** is a staple breakfast or snack option in Govardhan. The kachoris are stuffed with lentils or spices and served with tangy, flavorful potato curry.

### Why You’ll Love It:
- This dish is a perfect blend of crispy, spicy, and tangy flavors.
- It’s filling and ideal for those embarking on the **Parikrama (pilgrimage walk)** around Govardhan Hill.

### Where to Try:
- Street-side vendors and eateries around Govardhan serve piping hot kachoris in the mornings.`,
    },
    {
      heading: "3# - Rabri and Jalebi",
      image: "/images/rabree.jpeg",
      description: `
**Rabri** is a creamy, condensed milk dessert often paired with hot, crispy **jalebis** for a heavenly combination.

### Why It Stands Out:
- Rabri is made using slow-cooked milk, flavored with saffron and cardamom, while jalebis provide a sweet and crispy contrast.
- Together, they create a rich, indulgent treat for food lovers.

### Where to Try:
- Local sweet shops in the town center and around the temple area.`,
    },
    {
      heading: "4# - Prasad from Govardhan Temples",
      image: "/images/peda.jpeg",
      description: `
Devotees visiting Govardhan temples receive **prasad**—holy food blessed by the deities. It often includes sweets like **pedas**, **ladoos**, and savory items like **khichdi** or **rice dishes**.

### Why It’s Unique:
- Prasad is considered sacred and is prepared with utmost devotion.
- It provides a spiritual connection through food, making it a must-try.

### Where to Try:
- Available at all major temples like **Daan Ghati Mandir** and **Mukharvind Temple**.`,
    },
  ],
},







  //Gokul/RamanReti & Dauji
  "places-to-visit-in-gokul-&-dauji": {
    title: "Place to visit in Gokul & Dauji",
    sections: [
      {
        heading: "#1 - 84 Khamba Temple: Spiritual Significance",
        image: "/images/84-khamba.jpg",
        description: `
The **84 Khamba Temple** in **Gokul**, near **Mathura**, is a significant Hindu temple dedicated to **Lord Krishna**. It is believed to mark the spot where Krishna spent his childhood, and its name comes from the 84 stone pillars around the structure, which hold spiritual significance.

### Key Points:
- **Significance**: The 84 pillars represent spiritual progression or the 84 million species of life.
- **Deity**: The temple is dedicated to Lord Krishna and associated with his early years in Gokul.
- **Historical Context**: The temple is linked to Krishna’s childhood and his foster parents, **Nanda Maharaj** and **Yashoda**.

### Key Features:
- **84 Pillars**: A unique architectural feature symbolizing spiritual and yogic significance.
- **Idols**: The temple houses idols of **Krishna**, **Radha**, and other deities.
- **Serene Atmosphere**: The temple provides a peaceful space for reflection and prayer.

### Festivals:
- **Govardhan Puja**: Celebrating Krishna’s lifting of Govardhan Hill.
- **Janmashtami**: Krishna’s birthday, celebrated with special prayers and rituals.
- **Kartika Purnima** & **Makarsankranti**: Significant festivals attracting pilgrims.

### Visiting Details:
- **Best Time**: October to March. Festivals like **Govardhan Puja** and **Janmashtami** bring large crowds.
- **Temple Timings**: 5:00 AM – 12:00 PM and 3:00 PM – 7:00 PM. Special prayers are held during festivals.

### Nearby Attractions:
- **Nanda Bhavan**, **Brahma Kund**, and **Gokulnath Temple** are significant sites in Gokul.
- **Mathura** and **Vrindavan** are also key pilgrimage destinations.

### Tips:
- **Dress Modestly** and participate respectfully in rituals.
- **Carry Water** during warm months, and if planning to do **Govardhan Parikrama**, allow time for temple visits.

The 84 Khamba Temple offers a peaceful spiritual experience deeply connected to Krishna's divine pastimes in Gokul.
`,
      },
      {
        heading: "#2 - Raman Reti: Krishna’s Divine Playground",
        image: "/images/Raman-reti.jpg",
        description: `
**Raman Reti**, located in **Gokul** near **Mathura**, is a sacred site where **Lord Krishna** spent his childhood, playing with his friends and performing miracles. This place holds great spiritual significance for **Gaudiya Vaishnavas**, representing Krishna's divine pastimes.

### Key Points:
- **Significance**: The name "Raman" refers to Krishna, and "Reti" means sand, symbolizing the sacred ground where Krishna played.
- **Spiritual Importance**: The sand of Raman Reti is considered highly sacred, and walking on it is believed to purify the soul.
- **Location**: Gokul, 15 km from Mathura, is closely associated with Krishna's childhood.

### Key Features:
- **Sacred Sand**: Krishna's divine energy is believed to be imprinted here.
- **Temples and Shrines**: Small temples dedicated to Krishna’s activities in Gokul.
- **Flute Music**: The sound of Krishna’s flute is said to resonate in the area.
- **Peaceful Atmosphere**: Ideal for meditation and spiritual activities.

### Festivals:
- **Krishna Janmashtami**: Celebrating Krishna’s birth with prayers and reenactments.
- **Govardhan Puja**: Celebrating Krishna’s protection of Gokul.
- **Kartika Purnima** & **Makarsankranti**: Key spiritual festivals in Gokul.

### Visiting Details:
- **Best Time**: October to March. Festivals attract large crowds, but offer a richer experience.
- **Temple Timings**: Typically 6:00 AM – 12:00 PM and 3:00 PM – 7:00 PM. Aartis are held in the morning and evening.

### Nearby Attractions:
- **Nanda Bhavan**, **Brahma Kund**, and **Govardhan Hill** are key sites related to Krishna’s life.

Raman Reti is a serene and sacred place, perfect for devotion, meditation, and connecting with Krishna’s divine pastimes.
`,
      },
      {
        heading: "#3 - Brahmand Ghat: A Sacred Pilgrimage Site",
        image: "/images/Brahmand-ghat.jpg",
        description: `
**Brahmand Ghat**, located near **Gokul** in **Mathura**, Uttar Pradesh, is a significant Hindu pilgrimage site linked to **Lord Krishna**. The name "Brahmand" refers to the universe, symbolizing Krishna's role as the Supreme Being. It is believed to be the site where Krishna revealed his **Vishvarupa** (cosmic form) to his foster father **Nanda Maharaj** and the residents of Gokul.

### Key Features:
- **Vishvarupa Revelation**: Krishna's divine universal form was revealed here, symbolizing his omnipresence and cosmic nature.
- **Yamuna River**: Pilgrims take a holy dip in the Yamuna, believed to purify the soul.
- **Nearby Temples**: Small temples dedicated to Krishna and his forms, offering a serene environment for prayer.

### Spiritual Activities:
- **Kirtans and Bhajans**: Devotees gather for chanting and singing in praise of Krishna.
- **Aarti and Prayers**: Morning and evening aartis bring the ghat to life with devotional rituals.

### Festivals:
- **Krishna Janmashtami**: Krishna's birth is celebrated with prayers, kirtans, and aartis.
- **Govardhan Puja**: Flocks of devotees pay homage to Krishna’s act of lifting the Govardhan Hill.
- **Kartika Purnima**: Special rituals are held during the full moon of Kartika month.

### Visiting Details:
- **Best Time to Visit**: October to March. The summer months (April-June) can be quite hot.
- **Temple Timings**: Nearby temples are open from 6:00 AM – 12:00 PM and 3:00 PM – 7:00 PM.
- **Aarti Timings**: Morning: 6:00 AM – 7:00 AM, Evening: 7:00 PM – 8:00 PM.


### Tips for Visiting:
- **Dress Modestly**: Respect the sacred site with appropriate attire.
- **Respect Rituals**: Participate reverently in prayers and kirtans.
- **Carry Water**: Stay hydrated, especially during the hot months.

Brahmand Ghat is a place where Krishna's divine cosmic nature was revealed, making it an essential site for Krishna devotees seeking spiritual purification and a deeper connection with the divine.
`,
      },
      {
        heading: "#4 - Chintaharan Mahadev Temple: Reliever of Worries",
        image: "/images/chintarhan.jpg",
        description: `
**Chintaharan Mahadev Temple**, located in **Gokul**, near Mathura, Uttar Pradesh, is dedicated to Lord Shiva in the form of **Chintaharan Mahadev**, the "Reliever of Worries." Devotees visit seeking blessings from Shiva to alleviate stress, anxiety, and life’s troubles.

### Key Features:
- **Name Significance**: "Chintaharan" means "remover of worries." This form of Shiva is believed to provide mental peace and clarity, helping devotees overcome life's challenges.
- **Location**: Situated near the banks of the **Yamuna River**, the temple is approximately 15 km from Mathura and 30 km from Vrindavan.
- **Lord Shiva's Idol**: The temple houses a Shiva Lingam, where devotees offer prayers and light incense to invoke Lord Shiva's blessings.

### Spiritual Activities:
- **Prayers and Rituals**: Devotees often perform rituals such as offering milk, bel leaves, and chanting mantras like **Om Namah Shivaya**.
- **Shiva Pooja and Aarti**: Morning and evening aartis are conducted with hymns and devotional songs.

### Festivals:
- **Maha Shivaratri**: Celebrated with fasting, chanting, and night-long prayers.
- **Shravan Month**: A particularly auspicious time when devotees perform special prayers.
- **Sawan Somvar**: Mondays during Shravan are significant for Shiva devotees, with rituals and fasting.

### Visiting Details:
- **Best Time to Visit**: October to March (cooler months) for a comfortable visit.
- **Temple Timings**: 
  - Morning: 6:00 AM – 12:00 PM
  - Evening: 4:00 PM – 8:00 PM
  - Aarti: Morning at 7:00 AM, Evening at 7:00 PM.



### Tips for Visiting:
1. **Dress Modestly**: Respectful attire is recommended for visiting the temple.
2. **Respect Rituals**: Engage in the prayers and rituals with reverence.
3. **Prepare for Crowds**: During festivals like Maha Shivaratri, arrive early to participate in the rituals.

Chintaharan Mahadev Temple is a serene and spiritually significant site where devotees come to seek relief from their worries and connect with Lord Shiva’s divine power.
`,
      },
      {
        heading: "#5 - Raskhan Samadhi: Fusion of Sufi and Bhakti Devotion",
        image: "/images/Rakshan.jpg",
        description: `
**Raskhan Samadhi**, located in **Gokul**, Uttar Pradesh, honors **Raskhan**, a 16th-century Sufi poet devoted to **Lord Krishna**. His poetry blends Sufi mysticism and Bhakti devotion, making the samadhi an important site for spiritual reflection.

### Key Highlights:
- **Who Was Raskhan?**: A Muslim poet, deeply devoted to Krishna, known for his devotional **dohe** (couplets) and **kavitas** (poems). His works are central to the **Sufi-Bhakti** tradition.
- **Samadhi Significance**: Represents the unity of Sufi and Bhakti traditions, attracting devotees from all faiths.
- **Features**: A peaceful site with a simple structure, offering space for meditation and prayer. Devotees often chant Raskhan’s poetry and offer flowers and lamps.
- **Rituals**: Prayers, lighting lamps, chanting Krishna’s hymns, and some Sufi practices like meditation.
  
### Festivals:
- **Krishna Janmashtami**: Celebrated with pilgrimages to the samadhi.
- **Raskhan’s Death Anniversary**: Prayers and readings of his poetry.
- **Sufi Festivals**: Poetry readings and qawwalis.

### Visiting Info:
- **Best Time**: October to March.
- **Timings**: Open from 6:00 AM to 7:00 PM.
- **Nearby Attractions**: Gokul, Vrindavan, **Govardhan Hill**, and the **Yamuna River**.

### Tips:
1. **Respect Traditions**: Be peaceful and respectful.
2. **Dress Modestly**: Follow the sacred site’s norms.
3. **Avoid Peak Heat**: Visit early or late in the day during summer.

Raskhan Samadhi is a unique spiritual destination, honoring the legacy of Raskhan’s devotion to Krishna and the fusion of Sufi and Bhakti traditions.
`,
      },
      {
        heading:
          "#6 - Thakurani Ghat : A Sacred Site for Radha-Krishna Devotees",
        image: "/images/Thakurani-ghat.jpg",
        description: `
**Thakurani Ghat**, located in **Gokul**, near Mathura, is a spiritual site deeply connected to **Radha and Krishna's** divine pastimes. It serves as a peaceful place for **meditation**, **prayer**, and **spiritual reflection**.

### Key Highlights:
- **Location**: In Gokul, linked to Krishna's childhood.
- **Name Significance**: "Thakurani" refers to Radha, Krishna’s consort.
- **Historical Significance**: Symbolizes the divine love of Radha and Krishna, central to the **Bhakti movement**.

### Spiritual Significance:
- **Radha-Krishna Connection**: Celebrates their divine union.
- **Sacred Bathing**: Pilgrims bathe in the Yamuna for purification.
- **Vraja Bhakti**: Part of the **Vraja Mandal**, focusing on devotion to Krishna and Radha.

### Features:
- **Radha’s Presence**: A place to meditate on Radha's love.
- **Yamuna River**: Believed to purify the soul.
- **Peaceful Atmosphere**: Ideal for reflection.
- **Nearby Temples**: Temples dedicated to Radha and Krishna.

### Rituals & Festivals:
- **Holy Bathing**: Pilgrims bathe for spiritual purification.
- **Chanting & Prayers**: Devotional activities at the ghat.
- **Kirtan & Aarti**: Evening prayers with devotional songs.
- **Radha Ashtami** & **Krishna Janmashtami**: Major festivals.

### Timing & Visiting:
- **Best Time**: October to March.
- **Opening Hours**: Open all day.
  - **Morning**: 5:00 AM - 9:00 AM.
  - **Evening**: 4:00 PM - 7:00 PM.


### Tips for Visitors:
1. **Respect Traditions**: Quiet behavior is appreciated.
2. **Dress Modestly**: For sacred sites.
3. **Avoid Peak Summer Heat**: Visit early or late.
4. **Prepare for a Spiritual Experience**: Embrace the divine atmosphere.

Thakurani Ghat is a serene and sacred place for devotees seeking to connect with **Radha and Krishna**.
`,
      },
      {
        heading: "#7 - Dauji Temple: A Sacred Site for Lord Balarama Worship",
        image: "/images/Dauji-temple.jpg",
        description: `
**Dauji Temple**, located in **Baldeo**, near Mathura, is a significant Hindu temple dedicated to **Lord Balarama**, Krishna’s elder brother. It is an important pilgrimage site honoring Balarama’s divine role in Hindu mythology.

### Key Highlights:
- **Location**: Situated in Baldeo, 15 km from Mathura.
- **Deity**: Lord Balarama, worshipped as the god of strength, wisdom, and agriculture.
- **Importance**: Symbolizes Balarama’s divine role in the Mahabharata and his connection with Krishna.

### Mythological & Religious Significance:
- **Balarama's Role**: Balarama assisted Krishna in his divine pastimes, and the temple honors this bond.
- **Baldeo's Name**: The town is named after Balarama, believed to have visited during his childhood.

### Features of the Temple:
1. **Main Idol**: Lord Balarama’s black stone idol with a plough.
2. **Serene Environment**: The temple’s peaceful setting encourages devotion and reflection.
3. **Architecture**: Traditional North Indian temple with intricate carvings.
4. **Agriculture Connection**: Popular among farmers seeking blessings for good harvests.

### Rituals & Worship:
- **Aarti**: Performed morning and evening with chanting and hymns.
- **Prayers & Offerings**: Devotees offer flowers and fruits to Lord Balarama.
- **Festivals**: **Holi**, **Balarama Jayanti**, and **Krishna Janmashtami** are celebrated here with special prayers and rituals.

### Timing & Visiting:
- **Opening Hours**: Open throughout the day.
  - **Morning**: 6:00 AM – 9:00 AM (ideal for prayers).
  - **Evening**: 5:00 PM – 7:00 PM (for evening aarti).
- **Best Time**: October to March (cool weather).
- **Festivals**: Visit during **Krishna Janmashtami**, **Balarama Jayanti**, and **Holi** for special celebrations.

### Nearby Attractions:
1. **Mathura**: The birthplace of Lord Krishna.
2. **Vrindavan**: A key Krishna worship site.
3. **Govardhan Hill**: A sacred site near Mathura.

### Tips for Visitors:
1. **Dress Modestly**: Respect the sacred space.
2. **Respect Local Customs**: Maintain peace during prayers.
3. **Festival Crowds**: Be prepared for larger crowds during festivals.

Dauji Temple in Baldeo is a spiritual haven for devotees honoring Lord Balarama, offering a serene environment for worship and reflection.
`,
      },
    ],
  },
 
  "things-to-do-in-gokul-&-dauji": {
  title: "Things to Do in Gokul & Dauji",
  sections: [
    {
      heading: "1# - Visit Temples in Gokul and Govardhan",
      image: "/images/gokul.jpeg",
      description: `
  The temples in Gokul and Govardhan offer a spiritually enriching journey for devotees and explorers. These sacred sites are deeply connected to the pastimes of Lord Krishna and are ideal for meditation and reflection.
  
  Key Highlights:
  - Explore temples like Daan Ghati Temple, Mukharbind Temple, and Raman Reti Temple, each holding historical and spiritual significance.
  - Visit Nand Bhavan in Gokul, where Lord Krishna spent his childhood.
  - Experience the vibrant Aarti ceremonies, known for their devotional hymns and serene atmosphere.
  
  Tips for Visitors:
  - Spend time absorbing the peace and beauty of these temples.
  - Plan your visit during festivals to witness the temples at their most vibrant and devotional.
  
  A visit to these temples immerses you in the divine energy of the region, leaving you spiritually fulfilled and inspired.`,
    },
    {
      heading: "2# - Visit Yamuna River and Sacred Ghats",
      image: "/images/yamuna.jpeg",
      description: `
  The Yamuna River and its sacred ghats are central to Krishna’s pastimes and hold immense spiritual significance. This serene location is perfect for reflection, prayer, and enjoying nature's tranquility.
  
  Key Highlights:
  - Visit Vishram Ghat and Kesi Ghat, known for their peaceful ambiance and spiritual energy.
  - Enjoy a boat ride along the Yamuna, soaking in the natural beauty and historical surroundings.
  - Witness the Yamuna Aarti at dusk, a spectacular event filled with devotional chants and the glow of lamps.
  
  Tips for Visitors:
  - Arrive early to enjoy a quieter experience at the ghats.
  - Participate in the evening Aarti to feel the divine connection.
  
  The Yamuna River and its ghats offer a serene escape, blending spiritual energy with natural beauty, making them a must-visit for all travelers.`,
    },
    {
      heading: "3# - Trek Around Gokul and Its Surroundings",
      image: "/images/gokul-trek.jpeg",
      description: `
  Trekking around Gokul and its surroundings combines spiritual discovery with the joy of exploring nature. This activity offers a unique way to connect with Krishna’s divine heritage.
  
  Key Highlights:
  - Walk through Raman Reti sand, where Lord Krishna is believed to have played as a child.
  - Explore trails leading to Brahmand Ghat, surrounded by lush landscapes and small shrines.
  - Experience the simplicity and charm of rural villages, reflecting the natural beauty and heritage of Gokul.
  
  Tips for Visitors:
  - Wear comfortable shoes for long walks and treks.
  - Take breaks to enjoy the serene views and historical sites.
  
  Trekking in Gokul is perfect for those seeking a spiritual connection alongside a refreshing natural experience.`,
    },
    {
      heading: "3# - Visit the Dauji Temple (Dauji)",
      image: "/images/Dauji-temple.jpg",
      description: `
The **Dauji Temple** in **Dauji** is dedicated to **Lord Balarama**, Krishna’s elder brother. This serene temple is a popular site for worship and spiritual reflection.

### Key Highlights:
- **Divine Connection**: The temple honors Lord Balarama and provides a peaceful setting for prayer and meditation.
- **Unique Rituals**: The temple hosts various spiritual rituals, especially during festivals like **Dauji Ka Huranga**.
- **Serenity and Peace**: The temple offers a tranquil atmosphere, perfect for reflection and connecting with Balarama’s divine presence.

### Tips for Visitors:
- The temple is particularly vibrant during festivals when it’s beautifully decorated, and special rituals are held.
- Spend some time in meditation or simply enjoy the peaceful surroundings.

The **Dauji Temple** is a sacred place to honor Lord Balarama and offers visitors a peaceful, reflective experience amidst the natural beauty of the region.`,
    },
    {
      heading: "4# - Explore the Local Markets (Gokul & Dauji)",
      image: "/images/localmarket.jpeg",
      description: `
The local markets in **Gokul** and **Dauji** offer an authentic taste of Braj culture, filled with colorful stalls selling traditional items and local delicacies.

### Key Highlights:
- **Local Handicrafts**: Explore the local handicrafts such as **wooden toys**, **painted pottery**, and **traditional Braj jewelry**.
- **Religious Souvenirs**: Temples and markets in both towns sell souvenirs like **idols of Krishna and Balarama**, **sacred beads**, and **holy books**.
- **Traditional Sweets**: Don’t miss the opportunity to try local sweets such as **pedas**, **gujiyas**, and **laddus**.

### Tips for Visitors:
- Bargain for better prices at local shops, especially for handicrafts and souvenirs.
- Explore the markets in the evening to witness the festive lighting and lively crowd.

Visiting the markets in **Gokul** and **Dauji** provides a unique insight into the local way of life while offering a chance to take home a piece of Braj culture and spirituality.`,
    },
  ],
}
,
"how-to-reach-gokul-&-dauji": {
  title: "How to Reach Gokul & Dauji",
  sections: [
    {
      heading: "By Air -",
      image: "/images/plane.jpg",
      description: `
The nearest airport to **Gokul & Dauji** is **Agra Airport**, located approximately **60 km** away. For international travelers, **Indira Gandhi International Airport (Delhi)** is a more viable option, situated around **170 km** from Gokul & Dauji.

### Key Airports:
- **Agra Airport**: Offers limited domestic flights, suitable for travelers coming from nearby cities. From Agra, you can hire a taxi or use local transport to reach Gokul & Dauji.
- **Indira Gandhi International Airport (Delhi)**: The main hub for international and domestic travelers, offering better connectivity. The journey from Delhi to Gokul & Dauji takes about **3.5-4 hours** by road.

### Recommendations:
- Pre-arrange a private taxi for a smooth journey from either airport to Gokul & Dauji.
- Air travel is best for visitors coming from distant locations, with road travel to reach Gokul & Dauji as the final step.`,
    },
    {
      heading: "By Train -",
      image: "/images/train.jpg",
      description: `
Gokul & Dauji do not have their own railway stations, but the nearest major station is **Mathura Junction**, located about **20 km** away. Mathura Junction is well-connected to cities across India.

### Key Details:
- **Mathura Junction** is the primary railhead, with frequent trains from cities like **Delhi**, **Mumbai**, **Jaipur**, and **Varanasi**.
- From Mathura, you can hire a taxi, take an auto-rickshaw, or board a local bus to reach Gokul & Dauji. The journey takes about **30-40 minutes**.

### Tips for Travelers:
- Book train tickets well in advance during peak seasons like **Janmashtami** or **Govardhan Puja**, when the area attracts many devotees.
- Arrange onward transportation from Mathura to Gokul & Dauji for a hassle-free experience.`,
    },
    {
      heading: "By Road -",
      image: "/images/road.jpg",
      description: `
Gokul & Dauji are easily accessible by road, making it a convenient option for visitors traveling from nearby cities.

### Key Routes:
- **From Delhi**: Gokul & Dauji are about **170 km** away, taking **3.5-4 hours** via the **Yamuna Expressway** and connecting highways.
- **From Agra**: It is approximately **60 km**, taking around **1.5-2 hours** by road.
- **From Mathura**: The distance is just **20 km**, making it a short **30-40 minute** drive.

### Transport Options:
- **Private Cars or Taxis**: Highly recommended for comfort and flexibility, especially for group or family travel.
- **Buses**: Local and government-run buses operate between Mathura and Gokul & Dauji, though direct buses from Delhi or Agra may be less frequent.

### Recommendations:
- Consider hiring a private vehicle if you plan to visit nearby attractions like **Govardhan**, **Vrindavan**, or **Barsana**.
- The roads leading to Gokul & Dauji are well-maintained, making road travel a smooth and preferred option.`,
    },
  ],
},
"festivals-in-gokul-&-dauji": {
  title: "Festivals in Gokul & Dauji",
  sections: [
    {
      heading: "1# - Janmashtami (Krishna's Birth Anniversary)",
      image: "/images/janam.jpeg",
      description: `
In **Gokul**, Janmashtami holds immense spiritual significance as it is believed to be the place where Lord Krishna spent his early years. The celebrations are marked with grandeur, devotion, and joy.

### Celebration Highlights:
- **Midnight Celebrations**: Devotees gather in temples to celebrate Krishna’s birth with bhajans, kirtans, and prayers.
- **Jhulan Yatra**: Elaborate swings (*jhulas*) adorned with flowers are set up in temples to cradle baby Krishna.
- **Dahi Handi**: In Gokul, young boys recreate Krishna’s mischievous act of stealing butter by forming human pyramids to break a pot of butter tied high above.

Janmashtami in **Gokul** offers a unique experience where devotion blends seamlessly with joy and festivity.`,
    },
    {
      heading: "2# - Dauji Ka Huranga (Post-Holi Celebrations)",
      image: "/images/laath-holi.jpg",
      description: `
**Dauji Ka Huranga** is a unique Holi celebration that takes place in **Dauji Temple**, near Gokul. Dedicated to **Lord Balarama**, Krishna’s elder brother, this festival is an extension of the Holi festivities.

### Celebration Highlights:
- **Colorful Chaos**: Men and women gather in the temple courtyard, splashing colors, throwing water, and indulging in playful wrestling.
- **Lathmar Tradition**: Similar to Barsana’s Holi, women playfully beat men with sticks (*lathis*) while the men protect themselves with shields.
- **Traditional Songs**: Folk songs praising Krishna and Balarama add a musical charm to the festivities.

Dauji Ka Huranga is a lively and entertaining festival that showcases the playful traditions of Braj Bhoomi.`,
    },
    {
      heading: "3# - Nandotsav (Celebration of Krishna’s Naming Ceremony)",
      image: "/images/lassi.jpg",
      description: `
**Nandotsav**, celebrated a day after Janmashtami, is one of the most cherished festivals in Gokul. This festival marks the joyous occasion of Krishna’s naming ceremony.

### Celebration Highlights:
- **Community Feasts**: Devotees distribute sweets, curd, and milk-based dishes as prasad.
- **Cultural Events**: Dance and drama performances narrating Krishna’s childhood stories are organized.
- **Devotional Singing**: Temples resonate with the chanting of Krishna’s name and bhajans.

The spirit of Nandotsav reflects the collective joy and gratitude of the people of Gokul for the arrival of their beloved Lord Krishna.`,
    },
    {
      heading: "4# - Annakut Mahotsav (Govardhan Puja)",
      image: "/images/peda.jpeg",
      description: `
The **Annakut Mahotsav**, or **Govardhan Puja**, is celebrated with great fervor in Gokul and Dauji. This festival commemorates Krishna’s lifting of the Govardhan Hill to protect the villagers from torrential rains.

### Celebration Highlights:
- **Annakut Prasad**: A massive offering of food items, including sweets, grains, and vegetables, is arranged in a hill-like structure to honor Krishna.
- **Temple Decorations**: Temples are beautifully decorated, and devotees sing bhajans in praise of Krishna’s divine act.
- **Parikrama**: Many devotees perform the traditional circumambulation (*parikrama*) of the temple, symbolizing their reverence.

The **Annakut Mahotsav** reflects Krishna’s teachings about protecting nature and fostering community spirit.`,
    },
  ],
},
"foods-of-gokul-&-dauji": {
  title: "Foods of Gokul & Dauji",
  sections: [
    {
      heading: "Traditional Sweets & Snacks -",
      image: "/images/peda.jpeg",
      description: `
Gokul & Dauji, both renowned for their religious and cultural significance, are also home to a variety of delicious local foods. Among the must-try items, the traditional sweets and snacks stand out as highlights of the region’s culinary heritage.

### 1. **Peda**:
A milk-based sweet that is rich in flavor and often flavored with cardamom or saffron, **Peda** is a famous delicacy in Gokul & Dauji. It is typically made from condensed milk, sugar, and ghee, offering a rich taste that is loved by locals and visitors alike. Peda is especially popular during festivals and religious events like **Janmashtami**.

### 2. **Kachori**:
A popular savory snack in the region, **Kachori** is a deep-fried pastry filled with spicy lentils or potatoes. It is typically served with tangy tamarind chutney or spicy potato curry, making it a perfect snack for a quick bite while exploring the streets of Gokul & Dauji.

### 3. **Rabri**:
A rich and creamy dessert, **Rabri** is made by boiling milk until it thickens, then sweetening it with sugar and garnishing it with cardamom or pistachios. This dessert is often served chilled and is a refreshing treat after a heavy meal.

### 4. **Churma**:
Traditionally associated with **Govardhan Puja**, **Churma** is made by grinding wheat flour dough into a coarse powder and then frying it in ghee. The mixture is sweetened with sugar and garnished with dry fruits. It’s a favorite offering during religious festivals.

### 5. **Lassi**:
Gokul & Dauji are also known for their **Lassi**, a yogurt-based drink that is both refreshing and filling. It’s typically served sweetened, and you can find it flavored with fruits or spices like saffron and rose.

These local dishes offer a perfect blend of flavors, combining sweet, savory, and spicy elements that reflect the region's diverse food culture.
      `,
    },
    {
      heading: "Street Food -",
      image: "/images/alootikki.jpeg",
      description: `
Apart from traditional sweets and snacks, the street food scene in **Gokul & Dauji** is equally vibrant and full of variety. These foods are not only popular among the locals but also attract tourists from all over India, who come to experience the authentic flavors of this region.

### 1. **Aloo Tikki**:
A popular street food, **Aloo Tikki** is a fried patty made from mashed potatoes mixed with spices. The crispy exterior and soft interior are complemented by tamarind chutney, yogurt, and spices. Often served as a snack or light meal, it’s a must-try street food in Gokul & Dauji.

### 2. **Chaat**:
Chaat is a generic term for a variety of Indian street snacks that are served with sweet and tangy chutneys. In Gokul & Dauji, you’ll find several variations of **Pani Puri**, **Bhel Puri**, and **Dahi Puri**. These dishes are popular for their combination of crunchy, tangy, spicy, and sweet flavors, making them a favorite among visitors.

### 3. **Samosa**:
Another well-loved snack, **Samosas** are deep-fried pastries filled with spicy potatoes and peas. The crispy golden exterior and flavorful filling make this snack a satisfying option, perfect for any time of the day. Samosas are served with mint chutney or tamarind sauce.

### 4. **Bhel Puri**:
A delicious mix of puffed rice, sev (crispy chickpea flour noodles), potatoes, onions, tomatoes, and a variety of chutneys, **Bhel Puri** is a light yet filling snack that is both crunchy and tangy. It’s perfect for a quick bite as you walk through the bustling streets of Gokul & Dauji.

### 5. **Pav Bhaji**:
A popular Mumbai street food, **Pav Bhaji** has also found its way into the streets of Gokul & Dauji. It consists of a spicy vegetable curry served with buttered pav (bread rolls). It’s a hearty and flavorful dish, loved by food enthusiasts.

These street foods are integral to the food culture of Gokul & Dauji and offer a great way to experience the lively atmosphere of the region while enjoying delicious, quick bites.
      `,
    },
  ],
}

  

};

export default contentMap;
