import React, { useState } from "react";
import { Card, Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../pagesCss/Customized.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const CustomizedTour = () => {
  const tourData = [
    {
      title: "Mathura",
      locations: [
        {
          name: "Krishna Janmabhoomi",
          image: "/images/janambhoomi.jpg",
          description: "Explore the birthplace of Lord Krishna.",
        },
        {
          name: "Dwarkadhish Temple",
          image: "/images/Dwarkadhish.jpg",
          description:
            "A grand temple dedicated to Lord Krishna, known for its stunning architecture and vibrant festivals..",
        },
        {
          name: "Vishram Ghat",
          image: "/images/vishramGhat.jpg",
          description:
            "A revered bathing ghat on the banks of the Yamuna River, where Lord Krishna is said to have rested after slaying the demon Kansa.",
        },
        {
          name: "Birla Mandir",
          image: "/images/Birla-mandir.jpg",
          description:
            "Known for its modern architecture and peaceful surroundings, dedicated to Lord Krishna and Radha.",
        },
        {
          name: "Galteshwar Mahadev",
          image: "/images/Galteshwar-Mahadev.jpg",
          description:
            "A revered shrine dedicated to Lord Shiva, situated in Mathura's spiritual landscape.",
        },

        {
          name: "Kans Qiula",
          image: "/images/KansQila.jpg",
          description:
            "The remains of the fort associated with the tyrant King Kansa, where Lord Krishna is believed to have vanquished him.",
        },
        {
          name: "Keshav Devji Temple",
          image: "/images/Keshav-devji.jpg",
          description:
            "A serene temple dedicated to Lord Krishna, offering a peaceful atmosphere for devotion and meditation.",
        },
        {
          name: "Yamuna Arti",
          image: "/images/Yamuna-Arti.jpg",
          description:
            "A ritualistic offering to the Yamuna River, symbolizing reverence and purification.",
        },
        {
          name: "Bhuteshwar Temple",
          image: "/images/Bhuteshwar-Temple.jpg",
          description:
            "An ancient temple dedicated to Lord Shiva, known for its historical significance and religious rituals.",
        },
      ],
    },
    {
      title: "Vrindavan",
      locations: [
        {
          name: "Maa Vaishno Devi Temple",
          image: "/images/Maa-Vaishno-Devi.jpg",
          description: "A holy temple dedicated to Maa Vaishno Devi.",
        },
        {
          name: "Prem Mandir",
          image: "/images/prem-mandir.jpg",
          description:
            "A temple that celebrates the love between Lord Krishna and Radha.",
        },

        {
          name: "Priyakant ju Mandir",
          image: "/images/Priyakantju.jpg",
          description:
            "Priyakant Ju Mandir in Mathura is a renowned temple dedicated to Lord Krishna.",
        },
        {
          name: "Akshay Patra & Chandrodaya Temple",
          image: "/images/chandrodya.jpg",
          description:
            "The Akshaya Patra Foundation is a non-profit organization that provides mid-day meals to school children across India. & Chandrodaya Temple notable for its towering structure and cultural significance.",
        },
        {
          name: "ISKCON Temple",
          image: "/images/isckon.jpg",
          description:
            "The ISKCON Temple in Mathura is a prominent spiritual center dedicated to Lord Krishna.",
        },
        {
          name: "Bankey Bihari",
          image: "/images/Bankey-Bihari.jpg",
          description:
            "Bankey Bihari Temple is a Hindu temple dedicated to Lord Krishna,.",
        },
        {
          name: "Temple Of Glass",
          image: "/images/temple-of-Glasses.jpg",
          description:
            "Famous for its stunning glass architecture and intricate designs, dedicated to Lord Krishna.",
        },
        {
          name: "Radha Vallab Temple",
          image: "/images/Radha vallabh.jpg",
          description:
            "Dedicated to Radha and Krishna, this temple is known for its serene ambiance and devotional fervor.",
        },
        {
          name: "Radha Damodar Temple",
          image: "/images/radha-damodar.jpg",
          description:
            "A historic temple revered for its association with the saint Jiva Goswami and housing the original deity of Damodar Krishna.",
        },
        {
          name: "Kali Deh",
          image: "/images/Kale-deh.jpg",
          description:
            "A sacred site believed to be the birthplace of Lord Krishna, attracting pilgrims and devotees.",
        },
        {
          name: "Garun Govind",
          image: "/images/Garun-Govind.jpg",
          description:
            "Known for its unique architecture and dedicated to Garuda, the divine eagle carrier of Lord Vishnu.",
        },
        {
          name: "Rangji Mandir",
          image: "/images/Ranji-temple.jpg",
          description:
            "A temple known for its beautiful architecture and significant historical importance.",
        },
        {
          name: "Nidhivan",
          image: "/images/Nidhivan.jpg",
          description:
            "A mysterious forest grove believed to be the eternal abode of Radha and Krishna’s pastimes.",
        },
        {
          name: "Madan Mohan Temple",
          image: "/images/madan-mohan.jpg",
          description:
            "One of the oldest temples in Mathura, dedicated to Lord Krishna in the deity form of Madan Mohan ",
        },
        {
          name: "Pagal Baba Temple",
          image: "/images/pagal-baba.jpg",
          description:
            "A temple dedicated to Lord Krishna where devotees believe that intense devotion leads to divine madness (pagalpan).",
        },
      ],
    },
    {
      title: "Barsana",
      locations: [
        {
          name: "Radharani Temple",
          image: "/images/Radha rani temple.jpg",
          description:
            "A revered temple dedicated to Radharani, the eternal consort of Lord Krishna, known for its devotional atmosphere.",
        },
        {
          name: "Peeli Pokhara ",
          image: "/images/peeli-pokhar.jpg",
          description:
            "A historic pond associated with Lord Krishna's childhood pastimes, offering a serene and picturesque setting.",
        },
        {
          name: "Maan Ghar",
          image: "/images/maan-ghar.jpg",
          description:
            "A site where Lord Krishna is believed to have indulged in playful acts and mischief during his childhood",
        },
        {
          name: "Vilas Ghar",
          image: "/images/vilas-ghar.jpg",
          description:
            "A place where Lord Krishna enjoyed playful activities and pastimes, popular among devotees for its spiritual significance.",
        },
        {
          name: "Dan Bihari Temple",
          image: "/images/Dab-bihari.jpg",
          description:
            "A temple dedicated to Lord Krishna in his playful and mischievous childhood form, attracting devotees seeking blessings.",
        },
        {
          name: "Morkuti",
          image: "/images/morkuti.jpg",
          description:
            "A sacred site associated with Lord Krishna's pastimes, revered for its spiritual ambiance and historical importance.",
        },
      ],
    },
    {
      title: "Nandgaon",
      locations: [
        {
          name: "Nand Bhavan Temple",
          image: "/images/Nand-Bhawan.jpg",
          description:
            "The ancestral home of Lord Krishna's foster father Nanda Maharaj, known for its association with Krishna's childhood in Gokul.",
        },
        {
          name: "Nandishvar Temple",
          image: "/images/Nandishwar.jpg",
          description:
            "A sacred temple dedicated to Nand Baba, the foster father of Lord Krishna, revered for its spiritual significance.",
        },
        {
          name: "Pavan Sarovar",
          image: "/images/pavan-sarovar.jpg",
          description:
            "A holy lake where Lord Krishna's parents, Nanda Maharaj and Yashoda Mata, are said to have performed ceremonial bathing rituals.",
        },
        {
          name: "Charan Pahari",
          image: "/images/charan-pahari.jpg",
          description:
            "A hill where devotees believe Lord Krishna's footprints are imprinted, offering a place for contemplation and spiritual connection.",
        },
      ],
    },
    {
      title: "Govardhan",
      locations: [
        {
          name: "Daan Ghati",
          image: "/images/Dan-ghati.jpg",
          description:
            "A serene ghat on the Yamuna River, known for its association with charitable activities and religious ceremonies.",
        },
        {
          name: "Radhakund & Shyamkund",
          image: "/images/Radha kund.jpg",
          description:
            "Sacred ponds revered for their connection to Radha and Krishna's divine pastimes, a major pilgrimage site.",
        },
        {
          name: "Kusum Sarovar",
          image: "/images/Kusum-sarovar.jpg",
          description:
            "A historic lake surrounded by lush gardens, known for its association with Radha and Krishna's romantic rendezvous.",
        },
        {
          name: "Goverdhan Mountain Parikrama",
          image: "/images/Govardhan-parikrma.jpg",
          description:
            "A pilgrimage trek around the sacred Govardhan Hill, believed to be lifted by Lord Krishna to protect villagers from Indra's wrath.",
        },
        {
          name: "Punchhri Ka Lotha Temple",
          image: "/images/Punchri-ka-lota.jpg",
          description:
            "VA temple known for its unique architecture and intricate carvings, dedicated to Lord Krishna.",
        },
        {
          name: "Mukharbind Temple",
          image: "/images/Mukharbind temple.jpg",
          description:
            "A temple dedicated to Mukharbind, an incarnation of Lord Shiva, where devotees seek blessings for auspicious beginnings.",
        },
        {
          name: "Mansi Ganga Kund",
          image: "/images/Mansi-ganga-kund.jpg",
          description:
            "A holy pond where Radha and Krishna are believed to have performed their pastimes, located near Govardhan Hill, a place of spiritual significance.",
        },
      ],
    },
    {
      title: "Gokul & Dauji",
      locations: [
        {
          name: "84 Khamba Temple",
          image: "/images/84-khamba.jpg",
          description:
            "Known for its 84 pillars and association with Lord Krishna's childhood tales, a significant pilgrimage site.",
        },
        {
          name: "Raman Reti",
          image: "/images/Raman-reti.jpg",
          description:
            "A sand dune area where Lord Krishna is believed to have played as a child, now a serene pilgrimage spot.",
        },
        {
          name: "Brahmand Ghat",
          image: "/images/Brahmand-ghat.jpg",
          description:
            "A holy bathing ghat on the Yamuna River, associated with the cosmic egg (Brahmand) created by Lord Brahma.",
        },
        {
          name: "Chintaharan Mahadev",
          image: "/images/chintarhan.jpg",
          description:
            "A revered shrine dedicated to Lord Shiva, where devotees seek solace and relief from worries.",
        },
        {
          name: "Raskhan Samadhi",
          image: "/images/Rakshan.jpg",
          description:
            "The final resting place of the saint Raskhan, known for his devotion to Lord Krishna and his literary contributions.",
        },
        {
          name: "Thakurani Ghat",
          image: "/images/Thakurani-ghat.jpg",
          description:
            "A picturesque ghat on the Yamuna River, frequented by pilgrims for bathing and religious rituals.",
        },
        {
          name: "Dauji Temple",
          image: "/images/Dauji-temple.jpg",
          description:
            "A significant temple dedicated to Lord Balarama, Krishna's elder brother, known for its religious importance and festivals.",
        },
      ],
    },
  ];

  const [selectedLocations, setSelectedLocations] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showMore, setShowMore] = useState({
    Vrindavan: false,
  });

  const handleToggleShowMore = (title) => {
    setShowMore((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  
  
  const handleCheckboxChange = (title, location) => {
    setSelectedLocations((prevState) => ({
      ...prevState,
      [`${title}-${location}`]: !prevState[`${title}-${location}`],
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    // console.log(selectedLocations);
    const errors = {};

    // Validation checks
    if (!userData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!userData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      errors.email = "Email is invalid";
    }
    if (!userData.phone.trim()) {
      errors.phone = "Phone is required";
    } else if (!/^\d+$/.test(userData.phone)) {
      errors.phone = "Phone number is invalid";
    }
    if (Object.keys(selectedLocations).length === 0) {
      errors.selectedLocations = "At least one location must be selected";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      // Proceed with form submission if no errors

      if (Object.keys(errors).length === 0) {
        // Prepare data to be sent
        const postData = {
          user: userData,
          locations: Object.keys(selectedLocations).filter(
            (key) => selectedLocations[key]
          ),
        };
        console.log(postData);
        try {
          // Using axios
          const response = await axios.post(
            "https://hvt-backend.vercel.app/user/create",
            postData
          );
          // https://hvt-backend.vercel.app/User/create
          if (response) {
            console.log("Data submitted successfully:", postData);
            toast.success("Enquiry sent succesfully");
            // Handle success (e.g., show a success message, clear form)
            setSelectedLocations({}); // Clear selected locations
            setUserData({ name: "", email: "", phone: "" }); // Reset user data
            setShowModal(false); // Close the modal after submission
          } else {
            toast.error("Failed to submit data");
            console.error("Failed to submit data");
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          toast.error("Error submitting data:", error);
        }
      }
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (title, index) => {
    setHoveredIndex(`${title}-${index}`);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const selectedTourLocations = Object.keys(selectedLocations).filter(
    (key) => selectedLocations[key]
  );


  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-center fw-bold fs-1">
        Customized <span className="main-hover"> Tour</span>
      </h2>
      <p className="text-justify">
        A customized tour is a personalized travel experience designed to match your specific interests and preferences.
        You can choose destinations, activities, and more for a unique adventure.
      </p>
      <div className="row">
        {tourData.map((tour, index) => {
          const locationsToShow = showMore[tour.title]
            ? tour.locations
            : tour.title === "Vrindavan"
            ? tour.locations.slice(0, 5)
            : tour.locations.slice(0, 5);

          return (
            <div className="col-md-4 mb-4" key={index}>
              <Card
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-duration="2000"
              >
                <Card.Body>
                  <Card.Title className="main-hover text-center font-weight-bold mb-3 pb-1 tour-card-title">
                    {tour.title}
                  </Card.Title>

                  {locationsToShow.map((location, idx) => (
                    <div
                      key={idx}
                      className="location-info mb-3 bg-light"
                      onMouseEnter={() => handleMouseEnter(tour.title, idx)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Form.Check
                        type="checkbox"
                        id={`location-${tour.title}-${idx}`}
                        label={location.name}
                        checked={
                          !!selectedLocations[`${tour.title}-${location.name}`]
                        }
                        onChange={() =>
                          handleCheckboxChange(tour.title, location.name)
                        }
                        className="pb-2 tour-checkbox ms-3 custom-checkbox"
                      />
                      {hoveredIndex === `${tour.title}-${idx}` && (
                        <div
                          className={`desc ${
                            hoveredIndex === `${tour.title}-${idx}` ? "show" : ""
                          }`}
                        >
                          <img
                            src={location.image}
                            alt={location.name}
                            className="location-image mt-2 mx-2 rounded"
                          />
                          <p>{location.description}</p>
                        </div>
                      )}
                    </div>
                  ))}

                  {tour.locations.length > (tour.title === "Vrindavan" ? 6 : 5) && (
                    <p
                      className={`view-more-hover view-more-text mt-3 text-center ${
                        showMore[tour.title] ? "active-view-more-text" : ""
                      }`}
                      onClick={() => handleToggleShowMore(tour.title)}
                      style={{
                        color: showMore[tour.title] ? "#9B8C5F" : "#9B8C5F ",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      {showMore[tour.title] ? "View Less" : "View More"}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          className="mt-1 w-100 select-hover"
          style={{
            backgroundColor: "#8e7e50",
            color: "white",
            position: "relative",
          }}
          onClick={() => setShowModal(true)}
        >
          Select
        </Button>
      </div>
      {formErrors.selectedLocations && (
        <p className="text-danger">{formErrors.selectedLocations}</p>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Selected Tour and User Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={userData.name}
                onChange={handleInputChange}
                isInvalid={!!formErrors.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                isInvalid={!!formErrors.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={userData.phone}
                onChange={handleInputChange}
                isInvalid={!!formErrors.phone}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mt-3">
              <h5>Selected Locations:</h5>
              <ul>
                {selectedTourLocations.map((location) => (
                  <li key={location}>{location}</li>
                ))}
              </ul>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                className="mt-3 w-100"
                style={{ backgroundColor: "#8e7e50", color: "white" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default CustomizedTour;
