import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pagesCss/HvtHeader.css';

export const HvtHeader = ({ isMenuOpen, setIsMenuOpen }) => {

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavClick = () => {
    setIsMenuOpen(false); // Close the menu after navigation
  };

  return (
    <Navbar  expand="lg" className="navbar-light" expanded={isMenuOpen}>
      <Container fluid>
      <Nav.Link href="/" className="d-flex align-items-center ms-1 navbar-brand">
  <img
    src="/images/logo.png"
    alt="Holy Vrindavan Tour shashank"
    className="rounded-circle"
    style={{ maxHeight: '60px', marginRight: '10px', width: '60px' }}
  />
  <h3 className="mb-0">Holy Vrindavan Tour</h3>
</Nav.Link>

        <Navbar.Toggle aria-controls="navbarNav" onClick={handleToggle} />
        <Navbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={handleNavClick}>
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/about" onClick={handleNavClick}>
                About
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/destinations" onClick={handleNavClick}>
                Destinations
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/gallery" onClick={handleNavClick}>
                Gallery
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/customized-tour" onClick={handleNavClick} className='custom-tour px-2  '>
                Customized Tour
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contact" onClick={handleNavClick}>
                Contact Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item  
              className="d-block d-sm-block d-md-none d-lg-none text-decoration-none">
              <Nav.Link as={Link} to="/login" onClick={handleNavClick}>
                Admin
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
